import {makeAutoObservable} from 'mobx'
import PublicationStore from '../publicationStore'
import {IRubricAndSubrubricStore} from './IRubricAndSubrubricStore'

class RubricAndSubrubricStore implements IRubricAndSubrubricStore {
  publicationStore: PublicationStore
  rubricId = ''
  rubricIdCache = ''
  rubricAvailableForRambler = false
  subrubricId = ''
  subrubricIdCache = ''
  subrubricAvailableForRambler = false
  rubricsForRamblerList: string[] = []

  constructor(publicationStore: PublicationStore) {
    makeAutoObservable(this)
    this.publicationStore = publicationStore
  }
  setRubricId = (value: string): void => {
    this.rubricId = value
  }

  setRubricIdCache = (value: string): void => {
    this.rubricIdCache = value
  }

  setRubricAvailableForRambler = (value: boolean): void => {
    this.rubricAvailableForRambler = value
  }

  setSubrubricId = (value: string): void => {
    this.subrubricId = value
  }

  setSubrubricIdCache = (value: string): void => {
    this.subrubricIdCache = value
  }

  setSubrubricAvailableForRambler = (value: boolean): void => {
    this.subrubricAvailableForRambler = value
  }

  setRubricsForRamblerList = (value: string[]): void => {
    this.rubricsForRamblerList = value
  }
}

export default RubricAndSubrubricStore
