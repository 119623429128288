import {FetchResult} from '@apollo/client'
import {UpdatePublicationMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UiUpdatePublicationResult} from 'entities/types/UiPublication'
import {UiRubric} from 'entities/types/UiRubric'
import {UiSubrubric} from 'entities/types/UiSubrubric'
import {UiStory} from 'entities/types/UiStory'
import {UiRegion} from 'entities/types/UiRegion'
import {UiAuthor} from 'entities/types/UiAuthor'
import {UiCollection} from 'entities/types/UiCollection'
import {UiDestination} from 'entities/types/UiDestination'
import {UiTag} from 'entities/types/UiTag'
import {UiYandexZenCategory} from 'entities/types/UiYandexZenCategory'
import {UiRelatedLink} from 'entities/types/UiRelatedLink'
import {UiPublicationYandexNewsVideo} from 'entities/types/UiPublicationYandexNewsVideo'
import {UiFigure} from 'entities/types/UiFigure'
import {UiImage} from 'entities/types/UiImage'
import {UiFigureType} from 'entities/types/UiFigureType'
import {UiFigureVersion} from 'entities/types/UiFigureVersion'
import {UiSubscriptionBlock} from 'entities/types/UiSubscriptionBlock'
import defaultPublication from '../publication.consts'

const updatePublicationAdapterToClient = (
  response: FetchResult<UpdatePublicationMutation>
): UiUpdatePublicationResult => {
  if (
    response.data?.updatePublication?.publication === null ||
    response.data?.updatePublication?.publication === undefined
  ) {
    return defaultPublication
  }

  const {
    authors,
    badwords,
    collections,
    createdAt,
    deprecatedId,
    destinations,
    id,
    isAutomaticCommentsDisabled,
    isCommentsEnabled,
    isImportant,
    isInside,
    keywords,
    ogDescription,
    ogTitle,
    oldAdminUrl,
    overviewDescription,
    overviewFigureId,
    overviewFigureVersionId,
    overviewTitle,
    publishedAt,
    regions,
    relatedLinks,
    rubric,
    sendToRambler,
    seoTitle,
    specProjectUrl,
    status,
    story,
    subscriptionBlock,
    subrubric,
    subtitle,
    tags,
    title,
    typeSlug,
    uid,
    url,
    yandexDzenTitle,
    yandexNewsVideos,
    yandexTitle,
    yandexZenCategories
  } = response.data?.updatePublication?.publication

  let rubricElement: UiRubric | null = null

  if (rubric) {
    const {availableForRambler, id: rubricId, title: rubricTitle} = rubric

    rubricElement = {
      id: rubricId,
      title: rubricTitle || '',
      availableForRambler: availableForRambler || false,
      slug: '',
      subrubrics: []
    }
  }

  let subrubricElement: UiSubrubric | null = null

  if (subrubric) {
    const {availableForRambler, id: subrubricId, title: subrubricTitle} = subrubric

    subrubricElement = {
      id: subrubricId,
      title: subrubricTitle || '',
      availableForRambler: availableForRambler || false,
      slug: ''
    }
  }

  let storyElement: UiStory | null = null

  if (story) {
    const {id: storyId, uid: storyUid, title: storyTitle} = story

    storyElement = {
      id: storyId,
      uid: storyUid,
      title: storyTitle || '',
      createdAt: new Date(),
      dayThemeRegions: [],
      description: '',
      isActive: false,
      updatedAt: new Date(),
      publicationsCount: 0
    }
  }

  const regionsVal: UiRegion[] =
    regions?.map(r => ({
      id: r?.id || '',
      uid: r?.uid || '',
      title: r?.title || '',
      slug: '',
      deprecatedId: 0,
      description: '',
      position: 0,
      requiredCountOfTopNewsBlocks: 0
    })) || []

  const authorsVal: UiAuthor[] =
    authors?.map(a => ({
      firstName: a.firstName || '',
      lastName: a.lastName || '',
      uid: a.uid || '',
      deprecatedId: 0,
      id: ''
    })) || []

  const collectionsVal: UiCollection[] =
    collections?.map(c => ({
      id: +(c?.id || 0),
      uid: c?.uid || '',
      title: c?.title || '',
      createdAt: new Date(),
      description: '',
      isActive: false,
      publicationsCount: 0,
      updatedAt: new Date()
    })) || []

  const destinationsVal: UiDestination[] =
    destinations?.map(d => ({
      id: d?.id || '',
      slug: d?.slug || '',
      title: d?.title || '',
      uid: ''
    })) || []

  const tagsVal: UiTag[] =
    tags?.map(t => ({
      id: t?.id || '',
      title: t?.title || '',
      uid: t?.uid || '',
      createdAt: new Date(),
      description: '',
      isActive: false,
      priority: 0,
      relatedTags: [],
      updatedAt: new Date()
    })) || []

  const relatedLinksVal: UiRelatedLink[] =
    relatedLinks?.map(rl => ({
      id: rl.id || '',
      text: rl.text || '',
      url: rl.url || ''
    })) || []

  const yandexZenCategoriesVal: UiYandexZenCategory[] =
    yandexZenCategories?.map(yac => ({
      deprecatedId: 0,
      id: yac?.id || '',
      uid: yac?.uid || '',
      title: yac?.title || ''
    })) || []

  const yandexNewsVideosVal: UiPublicationYandexNewsVideo[] = []

  yandexNewsVideos?.forEach(ynv => {
    let thumbnailElement: UiFigure | null = null

    if (ynv.thumbnail) {
      const {
        id: figureId,
        uid: figureUid,
        src,
        alt,
        author,
        description,
        hasWatermark,
        hasDiagonalWatermark
      } = ynv.thumbnail

      thumbnailElement = {
        id: figureId,
        uid: figureUid,
        src: src || '',
        cropperPreview: {} as UiImage,
        alt: alt || '',
        author: author || '',
        createdAt: createdAt ?? new Date(),
        description: description || '',
        hasWatermark: hasWatermark ?? false,
        hasDiagonalWatermark: hasDiagonalWatermark ?? false,
        updatedAt: new Date(),
        sourceImage: {} as UiImage,
        type: {} as UiFigureType,
        latestVersion: {} as UiFigureVersion
      }
    }

    yandexNewsVideosVal.push({
      id: ynv.id || '',
      title: ynv.title || '',
      uid: ynv.uid || '',
      uri: ynv.uri || '',
      thumbnail: thumbnailElement ?? ({} as UiFigure)
    })
  })

  let subscriptionBlockElement: UiSubscriptionBlock | null = null

  if (subscriptionBlock) {
    const {uid: subscriptionBlockUid, title: subscriptionBlockTitle} = subscriptionBlock
    subscriptionBlockElement = {
      uid: subscriptionBlockUid || '',
      title: subscriptionBlockTitle || '',
      content: '',
      id: '',
      position: 0
    }
  } else {
    subscriptionBlockElement = {
      uid: '',
      title: '',
      content: '',
      id: '',
      position: 0
    }
  }

  return {
    authors: authorsVal,
    badwords: badwords ?? defaultPublication.badwords,
    collections: collectionsVal,
    createdAt: new Date(createdAt),
    deprecatedId: deprecatedId ?? defaultPublication.deprecatedId,
    destinations: destinationsVal,
    id: id ?? defaultPublication.id,
    isAutomaticCommentsDisabled: isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
    isCommentsEnabled: isCommentsEnabled ?? defaultPublication.isCommentsEnabled,
    isImportant: isImportant ?? defaultPublication.isImportant,
    isInside: isInside ?? defaultPublication.isInside,
    keywords: keywords ?? defaultPublication.keywords,
    ogDescription: ogDescription ?? defaultPublication.ogDescription,
    ogTitle: ogTitle ?? defaultPublication.ogTitle,
    oldAdminUrl: oldAdminUrl ?? defaultPublication.oldAdminUrl,
    overviewDescription: overviewDescription ?? defaultPublication.overviewDescription,
    overviewFigureId: overviewFigureId ?? defaultPublication.overviewFigureId,
    overviewFigureVersionId: overviewFigureVersionId ?? defaultPublication.overviewFigureVersionId,
    overviewTitle: overviewTitle ?? defaultPublication.overviewTitle,
    publishedAt: new Date(publishedAt),
    regions: regionsVal,
    relatedLinks: relatedLinksVal,
    rubric: rubricElement ?? defaultPublication.rubric,
    sendToRambler: sendToRambler ?? defaultPublication.sendToRambler,
    seoTitle: seoTitle ?? defaultPublication.seoTitle,
    specProjectUrl: specProjectUrl ?? defaultPublication.specProjectUrl,
    status: status ?? defaultPublication.status,
    story: storyElement ?? defaultPublication.story,
    subrubric: subrubricElement ?? defaultPublication.subrubric,
    subscriptionBlock: subscriptionBlockElement,
    subtitle: subtitle ?? defaultPublication.subtitle,
    tags: tagsVal,
    typeSlug: typeSlug ?? defaultPublication.typeSlug,
    title: title ?? defaultPublication.title,
    uid: uid ?? defaultPublication.uid,
    url: url ?? defaultPublication.url,
    yandexDzenTitle: yandexDzenTitle ?? defaultPublication.yandexDzenTitle,
    yandexNewsVideos: yandexNewsVideosVal,
    yandexTitle: yandexTitle ?? defaultPublication.yandexTitle,
    yandexZenCategories: yandexZenCategoriesVal
    // announceImage: defaultPublication.announceImage,
    // canonicalUrl: defaultPublication.canonicalUrl,
    // comments: [],
    // commentsCount: 0,
    // content: {
    //   elements: []
    // },
    // creator: defaultPublication.creator,
    // hasOverviewBlock: defaultPublication.hasOverviewBlock,
    // socialMediaImage: defaultPublication.socialMediaImage,
    // type: defaultPublication.type,
    // updatedAt: new Date(),
  }
}

export {updatePublicationAdapterToClient}
