import {Button, Dialog, Paper, TextField} from '@mui/material'
import styled, {css} from 'styled-components'
import {HorizontalRule} from '@mui/icons-material'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {grey20} from '@theme/vars'

export const StyledDialog = styled(Dialog)`
  z-index: 111111111111111111;
`

export const StyledPaper = styled(Paper)`
  height: 100%;
  max-height: 100%;
  padding: 0;
  max-width: 898px !important;
  width: 898px !important;
`
export const DialogHeader = styled.div`
  display: flex;
  justify-content: center;
  min-height: 64px;
`

export const DialogTitle = styled.h2`
  margin: 20px auto;
  line-height: 24px;
  font-size: 20px;
  font-weight: 500;
`
export const DialogButton = styled(Button)`
  min-width: auto;
  min-height: auto;
  align-self: center;
  margin-right: 10px;
`
export const DialogContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: auto;
  padding-bottom: 20px;
  overflow-y: auto;
`
export const DialogItem = styled.div<{isUser: boolean; isError: boolean}>`
  border: ${({isUser}) => (isUser ? 'none' : '1px solid')};
  border-color: ${({isUser, isError}) => (!isUser && isError ? '#EB3846' : !isUser ? '#c4c4c4' : 'none')};
  background-color: ${({isUser}) => (isUser ? '#FAFAFA' : '')};
  font-size: 18px;
  padding: 16px;
  border-radius: 4px;
  max-width: 700px;
  margin-bottom: 16px;
  margin-right: ${({isUser}) => (isUser ? '24px' : 'auto')};
  margin-left: ${({isUser}) => (isUser ? 'auto' : '24px')};
  white-space: pre-wrap;
`
export const DialogItemButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
  align-items: to;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const DialogItemButton = styled(Button)`
  color: #1964e7;
  padding: 5px 5px 5px 0;
  min-height: auto;
`
export const DialogFooter = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 106px;
  max-height: 280px;
  background-color: #fafafa;
  border-top: 1px solid #c4c4c4;
  max-width: 898px;
  padding: 20px;
`
export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-end;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  min-height: 58px;
`

export const DialogInput = styled(TextField)`
  flex: 1;
  align-self: center;
  & .MuiOutlinedInput-root {
    padding: 0;
    border-radius: 4px;
    border: none; /* Убираем рамку инпута */
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none; /* Убираем рамку */
  }
  textarea {
    resize: none; /* Отключаем изменение размера */
    overflow: auto;
    padding: 8px;
    padding-right: 65px;
    padding-bottom: 8px;
  }
`

export const DialogActionButtonNn = styled(Button)`
  position: absolute;
  right: 35px;
  bottom: 35px;
  background-color: #1964e7;
  padding: 0;
  margin: 0;
  min-width: 42px;
  min-height: 42px;
  color: #ffffff;
  &:hover {
    background-color: #4019e7;
  }
  &:disabled {
    background-color: #3b79e7;
    color: #ffffff;
  }
  @media screen and (max-width: 768px) {
    right: 20px;
  }
`
export const Paragraph = styled.p`
  margin-bottom: 10px;
`

export const Modal = styled(SimpleModal)`
  & .paper {
    width: 450px;
  }

  & .paper > div > button {
    min-height: 32px !important;
    max-height: 32px !important;
  }

  & .paper > div > p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20.8px;
    color: rgba(0, 0, 0, 1);
    white-space: pre-wrap;
    text-align: center;
    width: 200px;
  }
`
export const HorizontalLine = styled(HorizontalRule)`
  color: #7f7f83;
`
export const WrapperRollUp = styled.div`
  position: fixed;
  left: 0;
  bottom: 80px;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const RollUp = styled.div`
  display: flex;
  background-color: #ffffff;
  border: 1px solid ${grey20};
  border-radius: 4px;
  width: 400px;
  height: 48px;
  align-items: center;
  padding: 12px;
`

export const TextRollUpTitle = styled.span`
  font-weight: 500;
  margin-right: 5px;
`

export const TextRollUp = styled.span<{color?: string}>`
  ${({color}) =>
    color &&
    css`
      color: ${color};
    `}
`
