import React, {FC, useCallback} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Checkbox, FormControlLabel, FormControlProps} from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

type RHFCheckboxLabelType = {
  name: string
  label: string
  handleClick?: (name: string) => void
} & FormControlProps

const RHFCheckboxLabel: FC<RHFCheckboxLabelType> = ({name, label, style, disabled, handleClick}) => {
  const {control} = useFormContext()

  return (
    <FormControlLabel
      label={label}
      style={style}
      control={
        <Controller
          name={name}
          control={control}
          render={({field}) => (
            <Checkbox
              {...field}
              icon={
                disabled ? (
                  <DisabledByDefaultIcon />
                ) : field.value === true ? (
                  <CheckBoxIcon color='primary' />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              color='primary'
              checked={field.value}
              disabled={disabled}
              onClick={
                handleClick
                  ? () => {
                      handleClick(name)
                    }
                  : undefined
              }
            />
          )}
        />
      }
    />
  )
}

export default RHFCheckboxLabel
