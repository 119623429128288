import {makeAutoObservable} from 'mobx'
import PublicationStore from '@stores/publicationStore/publicationStore'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import IModals from '../IModals'
import IImageModalStore from '../IImageModalStore'
import {ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS} from './announceImageModalStore.consts'
import {IAnnounceImageModalStore} from './IAnnounceImageModalStore'
import AnnounceImageModalCacheStore from './announceImageModalCacheStore/announceImageModalCacheStore'

class AnnounceImageModalStore implements IModals, IImageModalStore, IAnnounceImageModalStore {
  publicationStore: PublicationStore
  announceImageModalCacheStore = new AnnounceImageModalCacheStore(this)

  imageDetail: ImageBlotProps = ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS.defaultImageDetail
  announceFigureCache: ImageBlotProps | undefined = undefined
  isModalDirty = false
  isOpen = false
  imageModalLoading = false
  updatePublicationAnnounceFieldsLoading = false
  updatePublicationExtAnnounceParamsLoading = false
  updateSocialImageOnlyLoading = false

  constructor(publicationStore: PublicationStore) {
    this.publicationStore = publicationStore
    makeAutoObservable(this)
  }
  setUpdatePublicationAnnounceFieldsLoading = (value: boolean): void => {
    this.updatePublicationAnnounceFieldsLoading = value
  }

  setUpdatePublicationExtAnnounceParamsLoading = (value: boolean): void => {
    this.updatePublicationExtAnnounceParamsLoading = value
  }

  setUpdateSocialImageOnlyLoading = (value: boolean): void => {
    this.updateSocialImageOnlyLoading = value
  }

  setIsModalDirty = (value: boolean): void => {
    this.isModalDirty = value
  }
  setIsOpen = (value: boolean): void => {
    this.isOpen = value
  }

  setImageDetail = (value: ImageBlotProps): void => {
    this.imageDetail = value
  }

  setAnnounceFigureCache = (value: ImageBlotProps | undefined): void => {
    this.announceFigureCache = value
  }

  setImageModalLoading = (value: boolean): void => {
    this.imageModalLoading = value
  }

  reset = (): void => {
    this.imageDetail = ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS.defaultImageDetail
    this.isModalDirty = false
  }
}

export default AnnounceImageModalStore
