import React, {FC, useCallback, useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {observer} from 'mobx-react-lite'
import {CircularProgress} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {useRubricsQuery} from '@pages/Publication/gql/Publication.generated'
import {PublicationStatus} from '@graphql/types'
import SubRubricPicker from '../SubrubricPicker/SubRubricPicker'
import {useUpdatePublicationRubricAndSubrubricMutation} from '../../gql/PublicationEditor.generated'
import {Rubric} from '../../../../../types/Rubric'
import {RubricPickerButton, RubricPickerButtonGroup, RubricTitleWrapper} from './RubricPicker.styles'
import {RUBRIC_PICKER_CONSTS} from './RubricPicker.consts'

const RubricPicker: FC = () => {
  const {publicationStore} = useStore()
  const {publication, rubricAndSubrubricStore, setPublication, setPublicationWithPrevState} = publicationStore
  const {
    rubricId,
    rubricIdCache,
    subrubricId,
    subrubricIdCache,
    setRubricIdCache,
    setRubricAvailableForRambler,
    setRubricId,
    setSubrubricId,
    setRubricsForRamblerList
  } = rubricAndSubrubricStore

  const {getValues} = useFormContext()
  const {data, loading} = useRubricsQuery({
    fetchPolicy: 'cache-and-network'
  })
  const [rubricList, setRubricList] = useState<Rubric[]>([])
  const [updatePublicationRubricAndSubrubric, {loading: updatePubliationRubricAndSubrubricLoading}] =
    useUpdatePublicationRubricAndSubrubricMutation()

  useEffect(() => {
    if (!loading && data) {
      if (data?.rubrics?.length) {
        setRubricList(data?.rubrics)

        const availableRubrics: string[] = []

        data?.rubrics?.forEach(rubric => {
          const availableSubrubrics = []

          for (const subrubric of Object.values(rubric.subrubrics)) {
            if (subrubric.availableForRambler) {
              availableSubrubrics.push(subrubric.title)
            }
          }

          if (rubric.availableForRambler && availableSubrubrics.length === rubric.subrubrics.length) {
            availableRubrics.push(rubric.title)
          } else if (availableSubrubrics.length) {
            availableSubrubrics.forEach(sub => {
              availableRubrics.push(`${rubric.title} - ${sub}`)
            })
          }
          setRubricsForRamblerList(availableRubrics)
        })
      }
    }
  }, [loading, data, setRubricList, setRubricsForRamblerList])

  const handleOnChange = useCallback(
    (newValue: Rubric | null) => {
      setRubricIdCache(newValue?.id || '')
      setRubricAvailableForRambler(newValue?.availableForRambler || false)
    },
    [setRubricIdCache, setRubricAvailableForRambler]
  )

  /**
   * Сохранение рубрики и подрубрики в черновиках
   */
  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      ((rubricId || '') != (rubricIdCache || '') || subrubricId !== subrubricIdCache)
    ) {
      const timer = setTimeout(async () => {
        const variablesData = {
          publicationUid: publication.uid,
          rubricId: rubricIdCache?.length ? rubricIdCache : null,
          subrubricId: subrubricIdCache?.length ? subrubricIdCache : null
        }

        const updatePublicationData = await updatePublicationRubricAndSubrubric({
          variables: {
            data: variablesData
          }
        })

        if (!updatePublicationData?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            rubric: updatePublicationData?.data?.updatePublication?.publication?.rubric,
            subrubric: updatePublicationData?.data?.updatePublication?.publication?.subrubric
          }))
          setRubricId(updatePublicationData?.data?.updatePublication?.publication?.rubric?.id || '')
          setSubrubricId(updatePublicationData?.data?.updatePublication?.publication?.subrubric?.id || '')
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [
    publication.status,
    publication.uid,
    rubricId,
    rubricIdCache,
    subrubricId,
    subrubricIdCache,
    updatePublicationRubricAndSubrubric,
    setPublication,
    setPublicationWithPrevState,
    publication.rubric,
    publication.subrubric,
    setRubricId,
    setSubrubricId
  ])

  return (
    <div>
      <RubricTitleWrapper>
        {RUBRIC_PICKER_CONSTS.rubricMsg} {updatePubliationRubricAndSubrubricLoading && <CircularProgress size='1rem' />}
      </RubricTitleWrapper>
      {data?.rubrics && (
        <RubricPickerButtonGroup
          variant='contained'
          aria-label='outlined primary button group'
          disabled={getValues('cantEditPublication') || updatePubliationRubricAndSubrubricLoading}
        >
          {data?.rubrics &&
            data?.rubrics.map(rubric => (
              <RubricPickerButton
                className={`${rubricIdCache === rubric.id ? 'active' : ''}`}
                variant='contained'
                color='secondary'
                key={rubric.id}
                id={`rubric-item-${rubric.id}`}
                onClick={() => {
                  handleOnChange(rubric)
                }}
              >
                {rubric.title}
              </RubricPickerButton>
            ))}
        </RubricPickerButtonGroup>
      )}
      <SubRubricPicker loading={updatePubliationRubricAndSubrubricLoading} rubricList={rubricList} />
    </div>
  )
}

export default observer(RubricPicker)
