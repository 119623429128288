import React, {FC} from 'react'
import {IconButton, Menu, Paper} from '@mui/material'
import {DebugInfoIcon} from '../../UI/Icons/DebugInfo'
import CopyToClipboard from '../../UI/Buttons/CopyToClipboard/CopyToClipboard'
import {grayColor} from '../../../@theme/vars'
import {DebugInfoBlock, DebugInfoBlockLink, DebugInfoBlockRow} from './DebugInfo.styles'
import {DEBUG_INFO_CONSTS} from './DebugInfo.consts'

type DebugInfoProps = {
  id: string
  oldAdminUrl: string
  url: string
  deprecatedId: number
}

const DebugInfo: FC<DebugInfoProps> = ({id, oldAdminUrl, url, deprecatedId}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openLink = url => {
    window.open(url, '_blank')
  }

  return (
    <Paper elevation={0}>
      <IconButton
        id='debug-info-button'
        aria-controls={open ? 'debug-info-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          float: 'right',
          padding: '3px'
        }}
        disabled={false}
      >
        <DebugInfoIcon />
      </IconButton>
      <Menu
        id='debug-info-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'debug-info-button'
        }}
      >
        <DebugInfoBlock>
          <DebugInfoBlockRow
            style={{
              color: grayColor,
              fontWeight: 500,
              marginBottom: '8px'
            }}
          >
            {DEBUG_INFO_CONSTS.titleLabel}
          </DebugInfoBlockRow>
          <DebugInfoBlockRow>
            <div>ID&nbsp;-&nbsp;{id}&nbsp;</div>
            <div>
              <CopyToClipboard data={id} />
            </div>
          </DebugInfoBlockRow>
          {deprecatedId > 0 && (
            <DebugInfoBlockRow>
              <div>deprecatedId&nbsp;-&nbsp;{deprecatedId}&nbsp;</div>
              <div>
                <CopyToClipboard data={deprecatedId.toString()} />
              </div>
            </DebugInfoBlockRow>
          )}
          <DebugInfoBlockLink onClick={() => openLink(oldAdminUrl)}>
            {DEBUG_INFO_CONSTS.oldAdminUrlTitle}
          </DebugInfoBlockLink>
          <DebugInfoBlockLink onClick={() => openLink(url)}>{DEBUG_INFO_CONSTS.urlTitle}</DebugInfoBlockLink>
        </DebugInfoBlock>
      </Menu>
    </Paper>
  )
}

export default DebugInfo
