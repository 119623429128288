import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useFormContext, useWatch} from 'react-hook-form'
import {useKeycloak} from '@react-keycloak/web'
import {
  CreateFigureVersionInput,
  CropInput,
  Figure,
  FigureVersion,
  SocialMediaFigureVersion,
  SocialMediaImage
} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import {useStore} from '@stores/rootStoreContext'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import SimpleModal from '../../SimpleModal/SimpleModal'
import {useCreateFigureVersionMutation} from '../../../Publication/Form/gql/PublicationEditor.generated'
import {ImageBlotProps} from '../../../TextEditor/Blots/ImageBlot/ImageBlotProps'
import AnnounceImageModalFields from '../AnnounceImageModalFields/AnnounceImageModalFields'
import {ANOUNCE_IMAGE_MODAL_CONSTS} from './AnnounceImageModal.consts'
import {AnnounceImageModalProps} from './AnnounceImageModalProps'

/**
 * Компонент отрисовки модалки для анонс-фото
 * @param param0 параметры модалки
 * @returns
 */
const AnnounceImageModal: FC<AnnounceImageModalProps> = ({
  defaultFigure,
  defaultSocialImage,
  modalId,
  onClose,
  title,
  okLabel,
  noLabel,
  figureTypeSlug,
  defaultExtParams,
  defaultExtAnnounceParams,
  setImage,
  setSocialImage,
  setExtParams,
  setExtAnnounceParams
}) => {
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  const [disabledFormState, setDisabledFormState] = useState(true)

  const [currentHasWatermark, setCurrentHasWatermark] = useState(!!defaultFigure?.hasWatermark)
  const [currentHasDiagonalWatermark, setCurrentHasDiagonalWatermark] = useState(!!defaultFigure?.hasDiagonalWatermark)
  const [currentDescription, setCurrentDescription] = useState(defaultFigure?.description)
  const [id, setId] = useState(modalId || generateGuid())
  const [currentFigure, setCurrentFigure] = useState<Figure>(defaultFigure as Figure)
  const [currentAuthor, setCurrentAuthor] = useState(defaultFigure?.author)
  const [currentAlt, setCurrentAlt] = useState(defaultFigure?.alt)
  const [currentAlignY, setCurrentAlignY] = useState(defaultSocialImage?.socialMediaFigureVersion?.alignY || 0)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [currentExtParams, setCurrentExtParams] = useState(defaultExtParams)
  const [currentExtAnnounceParams, setCurrentExtAnnounceParams] = useState(defaultExtAnnounceParams)
  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()

  const {cropperStore, publicationStore} = useStore()
  const {announceImageModalStore, setCheckingPhotoEditorCache} = publicationStore
  const {cropper, setCropper, setIsCropperChanged} = cropperStore
  const {isOpen, isModalDirty, announceFigureCache} = announceImageModalStore

  useEffect(() => {
    setIsCropperChanged(false)
    setCropper(defaultFigure?.latestVersion?.crop as CropInput)
  }, [defaultFigure?.latestVersion, setCropper, setIsCropperChanged])

  const {setValue} = useFormContext()
  const {keycloak} = useKeycloak()

  const closeModal = useCallback(() => {
    onClose()
    setIsOpenConfirmDialog(false)
  }, [onClose])

  useEffect(() => {
    setCurrentExtParams(defaultExtParams)
  }, [defaultExtParams])

  //TODO может для соц сетей нужен тоже такой useEffect???
  useEffect(() => {
    setCurrentExtAnnounceParams(defaultExtAnnounceParams)
  }, [defaultExtAnnounceParams])

  const toggleIsOpenConfirmDialog = useCallback(() => {
    !isModalDirty ? onClose() : setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isOpenConfirmDialog, isModalDirty, onClose])

  const createFigureVersionAction = useCallback(
    (createFigureVersionInputData: CreateFigureVersionInput) => {
      const newFigure = createFigureVersion({
        variables: {
          data: createFigureVersionInputData
        }
      })

      return newFigure
    },
    [createFigureVersion]
  )

  const createFigureAndSocialImageVersions = useCallback(async () => {
    let newFigure

    if (
      currentFigure?.id !== defaultFigure?.id ||
      currentHasWatermark !== !!defaultFigure?.hasWatermark ||
      currentHasDiagonalWatermark !== !!defaultFigure?.hasDiagonalWatermark ||
      cropper.height !== defaultFigure?.latestVersion?.crop?.height ||
      cropper.width !== defaultFigure?.latestVersion?.crop?.width ||
      cropper.top !== defaultFigure?.latestVersion?.crop?.top ||
      cropper.left !== defaultFigure?.latestVersion?.crop?.left
    ) {
      newFigure = await createFigureVersionAction({
        figureId: currentFigure?.id,
        hasWatermark: currentHasWatermark,
        hasDiagonalWatermark: currentHasDiagonalWatermark,
        crop: cropper
      } as CreateFigureVersionInput)
    }

    if (
      newFigure ||
      currentAuthor !== defaultFigure?.author ||
      currentDescription !== defaultFigure?.description ||
      currentExtParams?.isFullWidth !== defaultExtParams?.isFullWidth
    ) {
      const imageBlot = {
        id: id || generateGuid(),
        crop: cropper,
        figureId:
          announceFigureCache?.figureId ||
          newFigure?.data?.createFigureVersion?.figureVersion?.figure?.id ||
          currentFigure?.id ||
          '',
        figureUid:
          announceFigureCache?.figureUid ||
          newFigure?.data?.createFigureVersion?.figureVersion?.figure?.uid ||
          currentFigure?.uid ||
          '',
        figureVersionId:
          announceFigureCache?.figureVersionId ||
          newFigure?.data?.createFigureVersion?.figureVersion?.id ||
          currentFigure?.latestVersion?.id ||
          '',
        src:
          newFigure?.data?.createFigureVersion?.figureVersion?.figure?.latestVersion.primaryProcessedImage?.url ||
          currentFigure?.src ||
          '',
        cropperPreviewUrl:
          announceFigureCache?.cropperPreviewUrl ||
          newFigure?.data?.createFigureVersion?.figureVersion?.figure?.cropperPreview?.url ||
          currentFigure?.cropperPreview?.url ||
          '',
        isFullWidth: currentExtParams?.isFullWidth || false,
        // ogTitle: currentExtAnnounceParams?.ogTitle || '',
        // ogDescription: currentExtAnnounceParams?.ogDescription || false,
        author: currentAuthor || (keycloak?.tokenParsed && keycloak?.tokenParsed['name']),
        description: currentDescription || '',
        alt: currentAlt || '',
        isannouncingphoto: true,
        hasWatermark: currentHasWatermark || false,
        hasDiagonalWatermark: currentHasDiagonalWatermark || false
      } as ImageBlotProps

      setImage && setImage(imageBlot, undefined)
      setValue(
        'announceFigureVersionId',
        newFigure?.data?.createFigureVersion?.figureVersion?.id || currentFigure?.latestVersion?.id || ''
      )
    }

    if (currentAlignY !== (defaultSocialImage?.socialMediaFigureVersion?.alignY || 0)) {
      setSocialImage &&
        setSocialImage({
          id: defaultSocialImage?.id || '',
          socialMediaFigureVersion: {
            alignY: currentAlignY,
            announceFigureVersion: {
              crop: defaultSocialImage?.socialMediaFigureVersion?.announceFigureVersion?.crop
            } as FigureVersion,
            id: currentFigure?.id,
            processedImage: {
              url: currentFigure?.cropperPreview?.url
            },
            title: fields?.title || ''
          } as SocialMediaFigureVersion
        } as SocialMediaImage)
    }

    setCheckingPhotoEditorCache(false)

    if (currentExtParams?.isFullWidth !== defaultExtParams?.isFullWidth) {
      setExtParams &&
        setExtParams({
          isFullWidth: currentExtParams?.isFullWidth || false
        })
    }

    if (
      currentExtAnnounceParams?.ogTitle !== defaultExtAnnounceParams?.ogTitle ||
      currentExtAnnounceParams?.ogDescription !== defaultExtAnnounceParams?.ogDescription
    ) {
      setExtAnnounceParams &&
        setExtAnnounceParams({
          ogTitle: currentExtAnnounceParams?.ogTitle || '',
          ogDescription: currentExtAnnounceParams?.ogDescription || ''
        })
    }
    onClose()
    setId('')
  }, [
    createFigureVersionAction,
    announceFigureCache,
    fields?.title,
    currentHasWatermark,
    currentHasDiagonalWatermark,
    keycloak?.tokenParsed,
    cropper,
    currentAlignY,
    defaultSocialImage,
    currentAlt,
    currentAuthor,
    currentDescription,
    currentFigure,
    defaultFigure,
    onClose,
    setValue,
    id,
    setImage,
    setSocialImage,
    // figureVersionId,
    setExtParams,
    setExtAnnounceParams,
    currentExtParams,
    defaultExtParams,
    currentExtAnnounceParams,
    defaultExtAnnounceParams,
    setCheckingPhotoEditorCache
  ])

  return (
    <SimpleModal
      open={isOpen}
      mode='alert'
      onClose={toggleIsOpenConfirmDialog}
      title={title}
      text={''}
      onOk={createFigureAndSocialImageVersions}
      onNo={() => {
        onClose()
        setId('')
      }}
      okButtonDisabled={disabledFormState || !isModalDirty}
      okButtonDisabledTooltip={
        disabledFormState ? ANOUNCE_IMAGE_MODAL_CONSTS.fillRequiredFields : ANOUNCE_IMAGE_MODAL_CONSTS.makeChangeMsg
      }
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={noLabel}
      loading={createFigureVersionLoading}
    >
      <AnnounceImageModalFields
        defaultFigure={defaultFigure}
        defaultSocialImage={defaultSocialImage}
        modalId={modalId || generateGuid()}
        figureTypeSlug={figureTypeSlug}
        defaultExtParams={defaultExtParams}
        defaultExtAnnounceParams={defaultExtAnnounceParams}
        figureIsLoading={createFigureVersionLoading}
        breakingNews={false}
        setDisabledFormState={setDisabledFormState}
        setCurrentHasWatermark={setCurrentHasWatermark}
        setCurrentHasDiagonalWatermark={setCurrentHasDiagonalWatermark}
        setCurrentDescription={setCurrentDescription}
        setCurrentAuthor={setCurrentAuthor}
        setCurrentAlt={setCurrentAlt}
        setCurrentFigure={setCurrentFigure}
        setCurrentAlignY={setCurrentAlignY}
        setCurrentExtParams={setCurrentExtParams}
        setCurrentExtAnnounceParams={setCurrentExtAnnounceParams}
        publicationTitle={fields?.title || ''}
      />
      <SimpleModal
        open={isOpenConfirmDialog}
        mode='alert'
        title={ANOUNCE_IMAGE_MODAL_CONSTS.warningMsg}
        okLabel={ANOUNCE_IMAGE_MODAL_CONSTS.closeConfirmBtn}
        onOk={closeModal}
        onYes={closeModal}
        onClose={toggleIsOpenConfirmDialog}
        onAbort={toggleIsOpenConfirmDialog}
        onNo={toggleIsOpenConfirmDialog}
        text={ANOUNCE_IMAGE_MODAL_CONSTS.closeConfirmationText}
      />
    </SimpleModal>
  )
}

export default observer(AnnounceImageModal)
