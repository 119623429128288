import {FC, useState} from 'react'
import {ArrowUpward, CropSquare} from '@mui/icons-material'
import {DialogActionButtonNn, DialogInput, WrapperInput} from '../DialogNn.styles'
import {TOOLBAR_DIALOG_CONSTS} from '../../ToolbarNn.consts'

interface DialogInputNnProps {
  isLoading: boolean
  isError: boolean
  handleCancel: () => void
  handleSendMessage: (message: string) => void
}

/**
 * Компонент для ввода нового сообщения в диалог, с кнопками для отправки сообщения или отмены.
 *
 * @param {boolean} isLoading - Определяет, идет ли загрузка (используется для блокировки ввода).
 * @param {boolean} isError - Определяет, есть ли ошибка в процессе отправки сообщения.
 * @param {Function} handleCancel - Функция для отмены операции.
 * @param {Function} handleSendMessage - Функция для отправки сообщения.
 */

const DialogInputNn: FC<DialogInputNnProps> = ({isError, isLoading, handleCancel, handleSendMessage}) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value)
  }

  //Функция для того что бы на enter добавлять сообщение
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (inputValue.trim().length > 0) {
        const message = inputValue.trim()
        setInputValue('')
        handleSendMessage(message)
      }
    }
  }

  return (
    <WrapperInput>
      <DialogInput
        placeholder={TOOLBAR_DIALOG_CONSTS.FOOTER.placeholderInput}
        multiline
        maxRows={8}
        value={inputValue}
        disabled={isLoading || isError}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        variant='outlined'
      />
      {isLoading ? (
        <DialogActionButtonNn
          onClick={() => {
            handleCancel()
          }}
        >
          <CropSquare fontSize='large' />
        </DialogActionButtonNn>
      ) : (
        <DialogActionButtonNn
          disabled={inputValue.length === 0 || isError}
          onClick={() => {
            setInputValue('')
            handleSendMessage(inputValue)
          }}
        >
          <ArrowUpward width={32} height={32} />
        </DialogActionButtonNn>
      )}
    </WrapperInput>
  )
}

export default DialogInputNn
