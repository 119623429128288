import React, {FC} from 'react'
import {Box, IconButton, Popover, Typography} from '@mui/material'
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state'
import {InfoOutlined} from '@mui/icons-material'

type PopoverInfoErrorButtonProps = {
  children: React.ReactNode
  styleBtnScale?: number
}

const PopoverInfoErrorButton: FC<PopoverInfoErrorButtonProps> = ({children, styleBtnScale}) => {
  return (
    <PopupState variant='popover' popupId='error-info-popup-popover'>
      {popupState => (
        <div>
          <IconButton size='small' {...bindTrigger(popupState)} style={{padding: 0}}>
            <InfoOutlined
              htmlColor='#EB3846'
              style={{
                transform: `scale(${styleBtnScale ? styleBtnScale : 0.6})`
              }}
            />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Box maxWidth='360px' padding='10px'>
              <Typography color='secondary'>{children}</Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default PopoverInfoErrorButton
