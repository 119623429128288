import {FetchResult} from '@apollo/client'
import {UpdatePublicationAnnounceImageOnlyMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UiUpdatePublicationAnnounceImageOnlyResult} from 'entities/types/UiPublication'
import {UiAnnounceImage} from 'entities/types/UiAnnounceImage'
import {UiFigureVersion} from 'entities/types/UiFigureVersion'
import {UiCrop} from 'entities/types/UiCrop'
import {UiImage} from 'entities/types/UiImage'
import {ImageCode} from '@graphql/types'
import {UiFigure} from 'entities/types/UiFigure'
import {UiFigureType} from 'entities/types/UiFigureType'
import defaultPublication from '../publication.consts'

const updatePublicationAnnounceImageOnlyAdapterToClient = (
  response: FetchResult<UpdatePublicationAnnounceImageOnlyMutation>
): UiUpdatePublicationAnnounceImageOnlyResult => {
  if (
    response.data?.updatePublication?.publication === null ||
    response.data?.updatePublication?.publication === undefined
  ) {
    return defaultPublication
  }

  const {announceImage} = response.data?.updatePublication?.publication

  let announceImageElement: UiAnnounceImage

  if (announceImage) {
    const {id: announceImageId, alt, author, description, figureVersion, isFullWidth} = announceImage

    let figureVersionElement: UiFigureVersion | null = null

    if (figureVersion) {
      const {
        crop,
        figure,
        hasWatermark,
        hasDiagonalWatermark,
        id: figureVersionId,
        primaryProcessedImage,
        uid: figureVersionUid
      } = figureVersion

      let cropElement: UiCrop | null = null

      if (crop) {
        const {height, left, top, width} = crop
        cropElement = {
          height: height || 0,
          left: left || 0,
          top: top || 0,
          width: width || 0
        }
      }

      let figureElement: UiFigure | null = null

      if (figure) {
        const {id: figureId, uid: figureUid, src, cropperPreview} = figure

        let cropperPreviewElement: UiImage | null = null

        if (cropperPreview) {
          const {url} = cropperPreview

          cropperPreviewElement = {
            url: url || '',
            code: ImageCode.L,
            size: {
              width: 0,
              height: 0
            }
          }
        }

        figureElement = {
          id: figureId,
          uid: figureUid,
          src,
          cropperPreview: cropperPreviewElement ?? ({} as UiImage),
          alt,
          author,
          createdAt: new Date(),
          description,
          hasWatermark,
          hasDiagonalWatermark,
          updatedAt: new Date(),
          sourceImage: cropperPreviewElement ?? ({} as UiImage),
          type: {} as UiFigureType,
          latestVersion: {} as UiFigureVersion
        }
      }

      let primaryProcessedImageElement: UiImage | undefined = undefined
      if (primaryProcessedImage) {
        const {url} = primaryProcessedImage
        primaryProcessedImageElement = {
          url: url || '',
          code: ImageCode.L,
          size: {
            width: 0,
            height: 0
          }
        }
      }

      figureVersionElement = {
        crop: cropElement ?? ({} as UiCrop),
        figure: figureElement ?? ({} as UiFigure),
        hasWatermark,
        hasDiagonalWatermark,
        id: figureVersionId,
        primaryProcessedImage: primaryProcessedImageElement,
        uid: figureVersionUid,
        createdAt: new Date(),
        processedImages: []
      }
    }

    announceImageElement = {
      id: announceImageId,
      alt,
      author,
      description,
      figureVersion: figureVersionElement ?? ({} as UiFigureVersion),
      isFullWidth
    }
  } else {
    announceImageElement = defaultPublication.announceImage
  }

  return {
    announceImage: announceImageElement
  }
}

export {updatePublicationAnnounceImageOnlyAdapterToClient}
