import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'
import {blueColor} from '../../../@theme/vars'

export const RamblerIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.2247 1.00002C6.51492 1.00037 3.6205 1.01269 2.30102 2.30121C1.01257 3.6204 1 6.5105 1 10.0996C1 13.6887 1.01234 16.3799 2.30079 17.6991C3.62027 18.988 6.19083 19 9.9006 19C13.6104 19 16.3795 18.988 17.699 17.6991C18.9874 16.3799 18.9998 13.6887 19.0001 10.0996M10.0995 1C13.8093 1.00035 16.3795 1.01269 17.699 2.30121C18.9874 3.6204 18.9997 6.73878 19.0001 10.3279M10.0001 5H13.5001L10.0001 14.5H6.50011L10.0001 5Z'
        stroke={props.color === 'primary' ? blueColor : '#C4C4C4'}
        strokeWidth='2'
      />
    </svg>
  )
}
