import React, {FC, useCallback, useEffect, useState} from 'react'
import {CircularProgress} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {updatePublicationAnnounceImageOnlyAdapterToClient} from '@entities/api/publication/adapters/updatePublicationAnnounceImageOnlyAdapterToClient'
import {useStore} from '@stores/rootStoreContext'
import AnnounceImageModal from '@components/UI/ImageEditor/AnnounceImageModal/AnnounceImageModal'
import {ImageBlot} from '@components/TextEditor/Blots'
import {generateGuid} from '@utils/generateGuid'
import {
  AnnounceImage,
  Figure,
  FigureVersion,
  PublicationStatus,
  SocialMediaFigureVersion,
  SocialMediaImage
} from '@graphql/types'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import {ExtParams} from '../ExtParams'
import {ExtAnnounceParams} from '../ExtAnnounceParams'
import {useUpdatePublicationAnnounceImageOnlyMutation} from '../../gql/PublicationEditor.generated'
import {PublicationFormAnnounceImageProps} from './PublicationFormAnnounceImageProps'
import {AnnounceImageWrapper} from './PublicationFormAnnounceImage.styles'
import {PUBLICATION_FORM_ANNONCE_IMAGE_CONSTS} from './PublicationFormAnnounceImage.consts'

/**
 * Компонент формы для анонс-фото
 * @param {PublicationFormAnnounceImageProps}
 * @returns
 */
export const PublicationFormAnnounceImage: FC<PublicationFormAnnounceImageProps> = observer(
  ({publicationTitle, defaultExtAnnounceParams}) => {
    const {publicationStore} = useStore()
    const {publication, setPublicationWithPrevState, announceImageModalStore} = publicationStore
    const {
      announceFigureCache,
      setIsModalDirty,
      isOpen,
      imageDetail,
      imageModalLoading,
      announceImageModalCacheStore,
      setIsOpen,
      setAnnounceFigureCache,
      setUpdatePublicationAnnounceFieldsLoading
    } = announceImageModalStore
    const {extAnnounceParamsCache, socialImageCache, setExtAnnounceParamsCache, setSocialImageCache} =
      announceImageModalCacheStore
    const [currentFigure, setCurrentFigure] = useState({} as Figure)
    const [defaultExtParams, setDefaultExtParams] = useState<ExtParams>({
      isFullWidth: publication?.announceImage?.isFullWidth || false
    } as ExtParams)
    const [currentExtParams, setCurrentExtParams] = useState(defaultExtParams)
    const [currentExtAnnounceParams, setCurrentExtAnnounceParams] = useState(defaultExtAnnounceParams)
    const [currentSocialImage, setCurrentSocialImage] = useState({
      socialMediaFigureVersion: {} as SocialMediaFigureVersion
    } as SocialMediaImage)
    const [currentAnnounceFigureCache, setCurrentAnnounceFigureCache] = useState<ImageBlotProps | undefined>()
    const [currentExtAnnounceParamsCache, setCurrentExtAnnounceParamsCache] = useState<ExtAnnounceParams | undefined>()
    // const [currentExtParamsCache, setCurrentExtParamsCache] = useState<ExtParams | undefined>()
    const [currentSocialImageCache, setCurrentSocialImageCache] = useState<SocialMediaImage | undefined>()
    const [curPublicationTitle, setCurPublicationTitle] = useState(publicationTitle)
    const [curPublicationStatus, setCurPublicationStatus] = useState(publication?.status)
    const [updateAnnounceImageLoading, setUpdateAnnounceImageLoading] = useState(false)
    const modalLoading = imageModalLoading || updateAnnounceImageLoading

    const [updateAnnounceImageOnly, {loading: updateAnnounceImageOnlyLoading}] =
      useUpdatePublicationAnnounceImageOnlyMutation()

    useEffect(() => {
      setCurPublicationTitle(publicationTitle)
    }, [publicationTitle])

    useEffect(() => {
      setCurPublicationStatus(publication?.status)
    }, [publication?.status])

    useEffect(() => {
      console.log('updateAnnounceImageOnlyLoading', updateAnnounceImageOnlyLoading)
      setUpdateAnnounceImageLoading(updateAnnounceImageOnlyLoading)
      setUpdatePublicationAnnounceFieldsLoading(updateAnnounceImageOnlyLoading)
    }, [updateAnnounceImageOnlyLoading, setUpdatePublicationAnnounceFieldsLoading])

    useEffect(() => {
      setCurrentExtParams(defaultExtParams)
    }, [defaultExtParams])

    useEffect(() => {
      // setCurrentFigure({} as Figure)
      setIsOpen(false)

      if (announceFigureCache) {
        setCurrentAnnounceFigureCache(announceFigureCache)
        setDefaultExtParams({
          isFullWidth: announceFigureCache.isFullWidth
        } as ExtParams)
      }
    }, [announceFigureCache, setIsOpen])

    useEffect(() => {
      setCurrentSocialImageCache(socialImageCache)
      setCurrentSocialImage({} as SocialMediaImage)
    }, [socialImageCache])

    useEffect(() => {
      if (extAnnounceParamsCache) {
        setCurrentExtAnnounceParamsCache(extAnnounceParamsCache)
        setCurrentExtAnnounceParams(extAnnounceParamsCache)
      }
    }, [extAnnounceParamsCache])

    const openAnnounceModalImage = useCallback(
      (detail: ImageBlotProps) => {
        if (detail?.figureUid) {
          if (currentAnnounceFigureCache) {
            setCurrentFigure({
              alt: currentAnnounceFigureCache.alt || '',
              author: currentAnnounceFigureCache.author || '',
              cropperPreview: {
                url: currentAnnounceFigureCache.cropperPreviewUrl || ''
              },
              description: currentAnnounceFigureCache.description || '',
              hasWatermark: currentAnnounceFigureCache.hasWatermark || false,
              hasDiagonalWatermark: currentAnnounceFigureCache.hasDiagonalWatermark || false,
              id: currentAnnounceFigureCache.figureId,
              latestVersion: {
                id: detail.figureVersionId || detail.id,
                crop: {
                  width: currentAnnounceFigureCache.crop.width,
                  top: currentAnnounceFigureCache.crop.top,
                  left: currentAnnounceFigureCache.crop.left,
                  height: currentAnnounceFigureCache.crop.height
                }
              },
              src: currentAnnounceFigureCache.src || '',
              uid: currentAnnounceFigureCache.figureUid || ''
            } as Figure)
          } else {
            setCurrentFigure({
              alt: detail.alt || '',
              author: detail.author || '',
              cropperPreview: {
                url: detail.cropperPreviewUrl || '',
                size: {
                  width: detail.crop?.width || 0,
                  height: detail.crop?.height || 0
                }
              },
              description: detail.description || '',
              hasWatermark: detail.hasWatermark || false,
              hasDiagonalWatermark: detail.hasDiagonalWatermark || false,
              id: detail.figureId,
              latestVersion: {
                id: detail.figureVersionId,
                crop: {
                  width: detail.crop?.width || 0,
                  height: detail.crop?.height || 0,
                  top: detail.crop?.top || 0,
                  left: detail.crop?.left || 0
                }
              },
              src: detail.src || '',
              uid: detail.figureUid || ''
            } as Figure)
          }
          if (currentSocialImageCache) {
            setCurrentSocialImage({
              id: currentSocialImageCache?.id || '',
              socialMediaFigureVersion: {
                alignY: currentSocialImageCache?.socialMediaFigureVersion?.alignY,
                announceFigureVersion: {
                  crop: currentSocialImageCache?.socialMediaFigureVersion?.announceFigureVersion?.crop
                } as FigureVersion,
                id: currentSocialImageCache?.socialMediaFigureVersion?.id,
                processedImage: currentSocialImageCache?.socialMediaFigureVersion?.processedImage,
                title: publicationTitle || ''
              } as SocialMediaFigureVersion
            } as SocialMediaImage)
          }
          if (currentExtAnnounceParamsCache) {
            setCurrentExtAnnounceParams({
              ogTitle: currentExtAnnounceParamsCache.ogTitle || '',
              ogDescription: currentExtAnnounceParamsCache.ogDescription || ''
            })
          }
        }
        setIsOpen(true)
      },
      [
        currentAnnounceFigureCache,
        currentSocialImageCache,
        publicationTitle,
        currentExtAnnounceParamsCache,
        setCurrentFigure,
        setIsOpen,
        setCurrentSocialImage,
        setCurrentExtAnnounceParams
      ]
    )

    useEffect(() => {
      if (isOpen) {
        openAnnounceModalImage(imageDetail)
      }
    }, [isOpen, imageDetail, openAnnounceModalImage])

    const savePublication = useCallback(
      async e => {
        if (announceFigureCache) {
          const variablesData = {
            publicationUid: publication.uid,
            announceImage: {
              figureVersionId: announceFigureCache?.figureVersionId || publication.announceImage?.figureVersion?.id,
              isFullWidth:
                announceFigureCache?.isFullWidth !== undefined
                  ? announceFigureCache?.isFullWidth || false
                  : publication.announceImage?.isFullWidth || false,
              description: announceFigureCache?.description || publication.announceImage?.description || '',
              author: announceFigureCache?.author || publication.announceImage?.author || '',
              alt: announceFigureCache?.description || publication.announceImage?.description || ''
            }
          }

          const updateAnnounceImageRes = await updateAnnounceImageOnly({
            variables: {
              data: variablesData
            }
          })

          const updateAnnounceImageData = updatePublicationAnnounceImageOnlyAdapterToClient(updateAnnounceImageRes)

          if (updateAnnounceImageData?.announceImage) {
            setPublicationWithPrevState(prev => ({
              ...prev,
              announceImage: updateAnnounceImageData?.announceImage
            }))

            setAnnounceFigureCache(undefined)
          }
        }
      },
      [
        updateAnnounceImageOnly,
        setPublicationWithPrevState,
        publication.uid,
        setAnnounceFigureCache,
        announceFigureCache,
        publication.announceImage
      ]
    )

    const closeDialogImage = useCallback(() => {
      window.removeEventListener('savePublicationEvent', e => savePublication(e))
      setIsOpen(false)
      setIsModalDirty(false)
    }, [savePublication, setIsOpen, setIsModalDirty])

    useEffect(() => {
      window.addEventListener('savePublicationEvent', e => {
        savePublication(e)
      })
    }, [])

    const setAnnounceImage = useCallback(
      (announceFigureCacheItem?: ImageBlotProps, announceImage?: AnnounceImage) => {
        const announcePhotoBlock = document.getElementById('announcePhoto')

        if (announcePhotoBlock && (announceFigureCacheItem || announceImage)) {
          setCurrentAnnounceFigureCache(announceFigureCacheItem)
          setAnnounceFigureCache(announceFigureCacheItem)

          announcePhotoBlock.innerHTML = ''

          const img = ImageBlot.create({
            alt: announceFigureCacheItem?.alt || announceImage?.alt || announceFigureCacheItem?.description,
            id: announceFigureCacheItem?.id || announceImage?.figureVersion?.figure?.id || 'announceFigureId',
            src: announceFigureCacheItem?.src || announceImage?.figureVersion?.primaryProcessedImage?.url || '',
            cropperPreviewUrl:
              announceFigureCacheItem?.cropperPreviewUrl ||
              announceImage?.figureVersion?.figure?.cropperPreview?.url ||
              '',
            crop: {
              left: announceFigureCacheItem?.crop.left || announceImage?.figureVersion?.crop.left || 0,
              top: announceFigureCacheItem?.crop.top || announceImage?.figureVersion?.crop.top || 0,
              width: announceFigureCacheItem?.crop.width || announceImage?.figureVersion?.crop.width || 0,
              height: announceFigureCacheItem?.crop.height || announceImage?.figureVersion?.crop.height || 0
            },
            figureId: announceFigureCacheItem?.id || announceImage?.figureVersion?.figure?.id || '',
            figureUid: announceFigureCacheItem?.figureUid || announceImage?.figureVersion?.figure?.uid || '',
            figureVersionId: announceFigureCacheItem?.figureVersionId || announceImage?.figureVersion?.id || '',
            author:
              announceFigureCacheItem?.author ||
              announceImage?.author ||
              PUBLICATION_FORM_ANNONCE_IMAGE_CONSTS.unknownMsg,
            description: announceFigureCacheItem?.description || announceImage?.description || '',
            isFullWidth:
              currentExtParams?.isFullWidth !== undefined
                ? currentExtParams?.isFullWidth
                : announceFigureCacheItem
                ? announceFigureCacheItem.isFullWidth
                : announceImage
                ? announceImage.isFullWidth
                : false,
            isannouncingphoto: true,
            hasWatermark: false,
            hasDiagonalWatermark: false
          })
          announcePhotoBlock.appendChild(img)
        }
      },
      [setCurrentAnnounceFigureCache, setAnnounceFigureCache, currentExtParams]
    )

    useEffect(() => {
      if (!currentAnnounceFigureCache) {
        setAnnounceImage(undefined, publication?.announceImage)
      }
    }, [currentAnnounceFigureCache, publication?.announceImage, setAnnounceImage])

    useEffect(() => {
      // setCurrentExtAnnounceParamsCache(undefined)
      setCurrentExtAnnounceParams(defaultExtAnnounceParams)
    }, [defaultExtAnnounceParams, setCurrentExtAnnounceParamsCache])

    /**
     * Установка картинки для соцсетей
     * @param socialImageCacheItem
     * @returns {void}
     */
    const setSocialImage = useCallback(
      (socialImageCacheItem?: SocialMediaImage) => {
        setCurrentSocialImageCache(socialImageCacheItem)
        setSocialImageCache(socialImageCacheItem)
      },
      [setCurrentSocialImageCache, setSocialImageCache]
    )

    const setExtAnnounceParams = useCallback(
      (extAnnounceParamItem?: ExtAnnounceParams) => {
        setCurrentExtAnnounceParamsCache(extAnnounceParamItem)
        setExtAnnounceParamsCache(extAnnounceParamItem)
      },
      [setCurrentExtAnnounceParamsCache, setExtAnnounceParamsCache]
    )

    useEffect(() => {
      setCurrentSocialImageCache(undefined)
      setSocialImage(publication?.socialMediaImage)
    }, [publication?.socialMediaImage, setSocialImage])

    /**
     * Сохранение параметров анонс-фото в черновиках
     */
    useEffect(() => {
      if ([PublicationStatus.Draft, PublicationStatus.Review].includes(curPublicationStatus)) {
        if (announceFigureCache) {
          const timer = setTimeout(async () => {
            const variablesData = {
              publicationUid: publication.uid,
              announceImage: {
                figureVersionId: announceFigureCache?.figureVersionId || publication.announceImage?.figureVersion?.id,
                isFullWidth:
                  announceFigureCache?.isFullWidth !== undefined
                    ? announceFigureCache?.isFullWidth || false
                    : publication.announceImage?.isFullWidth || false,
                description: announceFigureCache?.description || publication.announceImage?.description || '',
                author: announceFigureCache?.author || publication.announceImage?.author || '',
                alt: announceFigureCache?.description || publication.announceImage?.description || ''
              }
            }

            const updateAnnounceImageRes = await updateAnnounceImageOnly({
              variables: {
                data: variablesData
              }
            })

            const updateAnnounceImageData = updatePublicationAnnounceImageOnlyAdapterToClient(updateAnnounceImageRes)

            if (updateAnnounceImageData?.announceImage) {
              setPublicationWithPrevState(prev => ({
                ...prev,
                announceImage: updateAnnounceImageData?.announceImage
              }))

              setAnnounceFigureCache(undefined)
            }
          }, 0)
          return () => clearTimeout(timer)
        }
      }
    }, [
      updateAnnounceImageOnly,
      setPublicationWithPrevState,
      publication.uid,
      curPublicationStatus,
      setAnnounceFigureCache,
      announceFigureCache,
      curPublicationTitle,
      publication.announceImage
    ])

    const handleOpenModal = useCallback(() => {
      if (publication?.announceImage?.id && modalLoading) {
        return
      }
      setIsOpen(true)
    }, [publication?.announceImage?.id, modalLoading, setIsOpen])

    return (
      <AnnounceImageWrapper>
        <div
          id='announcePhoto'
          onClick={handleOpenModal}
          style={{
            opacity: `${publication?.announceImage?.id && modalLoading ? '0.5' : '1'}`
          }}
        />
        {publication?.announceImage?.description && modalLoading ? (
          <CircularProgress
            size='3rem'
            style={{
              position: 'absolute',
              margin: '55px 97px'
            }}
          />
        ) : undefined}
        <AnnounceImageModal
          open={isOpen}
          defaultFigure={currentFigure}
          defaultSocialImage={currentSocialImage}
          modalId={publication?.announceImage?.id || generateGuid()}
          onClose={closeDialogImage}
          title={PUBLICATION_FORM_ANNONCE_IMAGE_CONSTS.insertingImageMsg}
          okLabel={PUBLICATION_FORM_ANNONCE_IMAGE_CONSTS.changeMsg}
          noLabel={PUBLICATION_FORM_ANNONCE_IMAGE_CONSTS.cancelMsg}
          figureTypeSlug='publication'
          defaultExtParams={currentExtParams}
          defaultExtAnnounceParams={currentExtAnnounceParams}
          setImage={setAnnounceImage}
          setExtParams={setCurrentExtParams}
          setExtAnnounceParams={setExtAnnounceParams}
          setSocialImage={setSocialImage}
        />
      </AnnounceImageWrapper>
    )
  }
)
