import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, CircularProgress, FormHelperText, InputLabel, OutlinedInput, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {generateGuid} from '@utils/generateGuid'
import {SideBarBlot} from '@components/TextEditor/Blots/SideBar/SideBar'
import SimpleModal, {SimpleModalProps} from '../SimpleModal/SimpleModal'
import {StyledFormControl} from '../styled'
import {SimpleFigure} from '../Icons/simpleFigure'
import {usePublicationByUrlFromEditorLazyQuery} from './gql/TextEditorSidebarModal.generated'
import {redColor} from './../../../@theme/vars'
import {TEXT_EDITOR_SIDEBAR_MODAL_CONSTS} from './TextEditorSidebarModal.consts'
import {
  AddButton,
  DetailsRowSelects,
  SidebarPreviewWrapper,
  SimpleFigureWrapper,
  StyledImage
} from './TextEditorSidebarModal.styles'

type TextEditorSidebarModalProps = {
  currentSideBarPublicationUrl?: string
  modalId?: string
  toggleModal: () => void
  okAction: ({}: SideBarBlot) => void
} & SimpleModalProps

const TextEditorSidebarModal: FC<TextEditorSidebarModalProps> = ({
  currentSideBarPublicationUrl,
  modalId,
  toggleModal,
  open,
  onClose,
  text,
  okAction
}) => {
  const [searchUrl, setSeachUrl] = useState('')
  const [errorText, setErrorText] = useState('')
  const [id, setId] = useState(modalId || generateGuid())
  const error = ''
  const snackbar = useSnackbar()

  const [publicationByUrl, {data, loading}] = usePublicationByUrlFromEditorLazyQuery({
    fetchPolicy: 'cache-and-network'
  })

  const search = useCallback(async () => {
    try {
      await publicationByUrl({
        variables: {url: searchUrl}
      })
    } catch {
      snackbar.enqueueSnackbar(TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.publicationNotFound, {variant: 'error'})
    }
  }, [publicationByUrl, searchUrl, snackbar])

  const changeUrl = useCallback(val => {
    setSeachUrl(val)

    if (val && val.search(new RegExp(`${process.env.REACT_APP_NEWS_REGEX}`)) !== 0) {
      setErrorText(TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.incorrectLinkMsg)
      return
    }

    setErrorText('')
  }, [])

  useEffect(() => {
    setId(modalId || generateGuid())
    setSeachUrl(currentSideBarPublicationUrl || '')
    search()
  }, [open])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.sidebarCreationMsg}
      text={text}
      onOk={() => {
        okAction({
          imageSrc:
            data?.publicationByUrl?.__typename === 'Publication'
              ? data?.publicationByUrl['announceImage']?.figureVersion?.primaryProcessedImage?.url || ''
              : data?.publicationByUrl?.announcingImageUrl || '',
          title: data?.publicationByUrl?.title || '',
          id: id,
          sidebarId: searchUrl,
          publicationUrl: searchUrl
        })
        toggleModal()
      }}
      onNo={() => {
        toggleModal()
      }}
      okLabel={TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.saveMsg}
      maxWidth='sm'
      okButtonDisabled={!data?.publicationByUrl?.title || !data?.publicationByUrl}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box display='flex' justifyContent='center' width={'100%'}>
          <DetailsRowSelects>
            <StyledFormControl width='250px'>
              <InputLabel>{TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.linkOnNewsMsg}</InputLabel>
              <OutlinedInput
                label={TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.linkOnNewsMsg}
                onChange={e => changeUrl(e.target.value)}
                value={searchUrl}
                error={!!errorText}
                // disabled={loading}
                autoFocus
              />
              {!!errorText && (
                <FormHelperText
                  style={{
                    color: redColor
                  }}
                >
                  {errorText}
                </FormHelperText>
              )}
              <FormHelperText>{error}</FormHelperText>
            </StyledFormControl>
          </DetailsRowSelects>
          <AddButton
            variant='contained'
            color='primary'
            onClick={search}
            disabled={!!errorText || !searchUrl || loading}
          >
            {loading ? <CircularProgress size='1rem' /> : <span>{TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.addMsg}</span>}
          </AddButton>
        </Box>
        <SidebarPreviewWrapper>
          <Typography>{TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.newsByThemeMsg}</Typography>
          {!data?.publicationByUrl && (
            <SimpleFigureWrapper>
              <SimpleFigure width='48px' height='48px' viewBox='0 0 48 48' />
            </SimpleFigureWrapper>
          )}
          {data?.publicationByUrl?.__typename === 'Publication' && data?.publicationByUrl?.announceImage && (
            <StyledImage src={data?.publicationByUrl.announceImage?.figureVersion?.primaryProcessedImage?.url} />
          )}
          {data?.publicationByUrl?.__typename === 'ExternalPublication' &&
            data?.publicationByUrl?.announcingImageUrl && (
              <StyledImage src={data?.publicationByUrl.announcingImageUrl} />
            )}
          <Typography variant='h1'>
            {data?.publicationByUrl
              ? data?.publicationByUrl.title
              : TEXT_EDITOR_SIDEBAR_MODAL_CONSTS.publicationTitleMsg}
          </Typography>
        </SidebarPreviewWrapper>
      </Box>
    </SimpleModal>
  )
}

export default TextEditorSidebarModal
