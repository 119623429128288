import React, {FC, useCallback, useEffect, useState} from 'react'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {Figure, FigureVersion, SocialMediaFigureVersion, SocialMediaImage} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import DeleteIcon from '@components/UI/Icons/DeleteIcon'
import EditIcon from '@components/UI/Icons/EditIcon'
import {useStore} from '@stores/rootStoreContext'
import {redColor} from '../../../../@theme/vars'
import {InputStringWithValidation} from '../../InputStringWithValidation/InputStringWithValidation'
import {StyledFormControl} from '../../styled'
import {ANOUNCE_IMAGE_MODAL_CONSTS} from '../AnnounceImageModal/AnnounceImageModal.consts'
import {TitleLength} from '../AnnounceImageModal/AnnounceImageModal.styles'
import ImageCropper from '../ImageCropper/ImageCropper'
import {SocialImage} from '../SocialImage/SocialImage'
import {IMAGE_EDITOR_MODAL_CONSTS} from '../ImageEditorModal/ImageEditorModal.consts'
import useWindowResize from '../../../../hooks/useWindowResize'
import {CropperType} from '../../../../types/CropperType'
import {AnnounceImageModalFieldsProps} from './AnnounceImageModalFieldsProps'
import {ANNOUNCE_IMAGE_MODAL_FIELDS_CONSTS} from './AnnounceImageModalFields.consts'
import {
  AccordionDetailsWrapper,
  AccordionSummaryTitleWrapper,
  AccordionSummaryWrapper,
  AccordionWrapper,
  AnnounceImageModalImagesWrapper,
  ArrowDropDownIconWrapper,
  DetailRowWrapper,
  InfoWrapper,
  SocialImageWrapper
} from './AnnounceImageModalFields.styles'

/**
 * Компонент полей для анонс-фото
 * @param param0
 * @returns
 */
const AnnounceImageModalFields: FC<AnnounceImageModalFieldsProps> = ({
  defaultFigure,
  defaultSocialImage,
  modalId,
  figureTypeSlug,
  defaultExtParams,
  defaultExtAnnounceParams,
  setDisabledFormState,
  setCurrentHasWatermark,
  setCurrentHasDiagonalWatermark,
  setCurrentDescription,
  setCurrentAuthor,
  setCurrentAlt,
  setCurrentFigure,
  setCurrentAlignY,
  setCurrentExtParams,
  setCurrentExtAnnounceParams,
  figureIsLoading,
  publicationTitle,
  breakingNews = false
}) => {
  const {cropperStore, publicationStore} = useStore()
  const {publication, announceImageModalStore} = publicationStore
  const [imageUrl, setImageUrl] = useState(defaultFigure?.src)
  const [id, setId] = useState(modalId || generateGuid())
  const [figure, setFigure] = useState<Figure>(defaultFigure as Figure)
  const [curSocialImage, setCurSocialImage] = useState<SocialMediaImage>(defaultSocialImage as SocialMediaImage)
  const {cropper, cropperIsValid, isCropperChanged} = cropperStore
  const {setIsModalDirty} = announceImageModalStore
  const [alignY, setAlignY] = useState(0)
  const [description, setDescription] = useState(defaultFigure?.description || '')
  const [descriptionDirty, setDescriptionDirty] = useState(false)
  const [descriptionErrorText, setDescriptionErrorText] = useState('')
  const [defaultAuthor, setDefaultAuthor] = useState(defaultFigure?.author)
  const [defaultAlt, setDefaultAlt] = useState(defaultFigure?.alt)
  const [author, setAuthor] = useState(defaultFigure?.author || '')
  const [authorErrorText, setAuthorErrorText] = useState('')
  const [alt, setAlt] = useState(defaultFigure?.alt || '')
  const [altErrorText, setAltErrorText] = useState('')
  const [hasWatermark, setHasWatermark] = useState(!!defaultFigure?.hasWatermark)
  const [hasDiagonalWatermark, setHasDiagonalWatermark] = useState(!!defaultFigure?.hasDiagonalWatermark)
  const [extParams, setExtParams] = useState(defaultExtParams)
  const [extAnnounceParams, setExtAnnounceParams] = useState(defaultExtAnnounceParams)
  const [clearFieldEventCounter, setClearFieldEventCounter] = useState(0)
  const [expanded, setExpanded] = React.useState('')
  const [ogTitle, setOgTitle] = useState(defaultExtAnnounceParams?.ogTitle || '')
  const [ogTitleErrorText, setOgTitleErrorText] = useState('')
  const [ogDescription, setOgDescription] = useState(defaultExtAnnounceParams?.ogDescription || '')
  const [ogDescriptionErrorText, setOgDescriptionErrorText] = useState('')
  const [isFullWidth, setIsFullWidth] = useState(defaultExtParams?.isFullWidth === true ? true : false)
  const [width] = useWindowResize()

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    debounce(() => {
      setId(modalId || generateGuid())
      setFigure(defaultFigure as Figure)
      // setCurSocialImage(defaultSocialImage as SocialMediaImage)
      // setAlignY(defaultSocialImage?.socialMediaFigureVersion?.alignY || 0)
      setImageUrl(defaultFigure?.src || '')
      setHasDiagonalWatermark(!!defaultFigure?.hasDiagonalWatermark)
      setDefaultAuthor(defaultFigure?.author || '')
      setDefaultAlt(defaultFigure?.alt || '')
      setAuthor(defaultFigure?.author || '')
      setDescription(defaultFigure?.description || '')
      setAlt(defaultFigure?.alt || '')
    }, 300)
  }, [defaultFigure, modalId])

  useEffect(() => {
    if (defaultExtAnnounceParams) {
      let slicedOgTitle = defaultExtAnnounceParams.ogTitle
      if (defaultExtAnnounceParams.ogTitle.length > 100) {
        slicedOgTitle = defaultExtAnnounceParams.ogTitle.slice(0, 100)
      }
      setOgTitle(slicedOgTitle)
      setOgDescription(defaultExtAnnounceParams.ogDescription)
    }
  }, [defaultExtAnnounceParams])

  useEffect(() => {
    if (defaultExtParams) {
      setIsFullWidth(defaultExtParams.isFullWidth)
      setExtParams(defaultExtParams)
    }
  }, [defaultExtParams])

  useEffect(() => {
    if (extAnnounceParams) {
      setCurrentExtAnnounceParams && setCurrentExtAnnounceParams(extAnnounceParams)
    }
  }, [extAnnounceParams, setCurrentExtAnnounceParams])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurSocialImage({
        id: figure?.id || '',
        socialMediaFigureVersion: {
          alignY: defaultSocialImage?.socialMediaFigureVersion?.alignY || 0,
          announceFigureVersion: {
            crop: defaultSocialImage?.socialMediaFigureVersion?.announceFigureVersion?.crop
          } as FigureVersion,
          id: figure?.id,
          processedImage: {
            url: figure?.cropperPreview?.url
          },
          title: publicationTitle || ''
        } as SocialMediaFigureVersion
      } as SocialMediaImage)
      setAlignY(defaultSocialImage?.socialMediaFigureVersion?.alignY || 0)
    }, 100)
    return () => clearTimeout(timer)
  }, [figure, defaultSocialImage, publicationTitle])

  useEffect(() => {
    const timer = setTimeout(() => {
      const newIsDirtyVal =
        author !== defaultFigure?.author ||
        description !== defaultFigure?.description ||
        alt !== defaultAlt ||
        isCropperChanged ||
        cropper.width !== defaultFigure?.latestVersion?.crop.width ||
        cropper.height !== defaultFigure?.latestVersion?.crop.height ||
        cropper.left !== defaultFigure?.latestVersion?.crop.left ||
        cropper.top !== defaultFigure?.latestVersion?.crop.top ||
        imageUrl !== defaultFigure?.src ||
        ogTitle !== defaultExtAnnounceParams?.ogTitle ||
        ogDescription !== defaultExtAnnounceParams?.ogDescription ||
        isFullWidth !== defaultExtParams?.isFullWidth
      // TODO надо будет вернуть после починки сдвига
      // alignY !== defaultSocialImage?.socialMediaFigureVersion?.alignY

      setIsModalDirty && setIsModalDirty(newIsDirtyVal)
    }, 100)
    return () => clearTimeout(timer)
  }, [
    setIsModalDirty,
    author,
    description,
    alt,
    cropper,
    isCropperChanged,
    ogTitle,
    ogDescription,
    isFullWidth,
    defaultExtParams,
    defaultExtAnnounceParams,
    defaultFigure?.author,
    defaultFigure?.src,
    defaultFigure?.description,
    defaultFigure?.alt,
    defaultFigure?.latestVersion?.crop,
    defaultSocialImage?.socialMediaFigureVersion,
    id,
    figure?.uid,
    imageUrl,
    defaultAlt,
    alignY
  ])

  useEffect(() => {
    setDisabledFormState(
      !author ||
        !figure?.uid ||
        // (!breakingNews && !alt) ||
        !description ||
        !cropperIsValid ||
        !!descriptionErrorText ||
        !!authorErrorText ||
        // !!altErrorText ||
        (!breakingNews && !ogTitle) ||
        !!ogTitleErrorText ||
        !!ogDescriptionErrorText ||
        figureIsLoading === true
    )
  }, [
    setDisabledFormState,
    author,
    figure?.uid,
    breakingNews,
    alt,
    description,
    ogTitle,
    ogTitleErrorText,
    ogDescriptionErrorText,
    cropperIsValid,
    descriptionErrorText,
    authorErrorText,
    altErrorText,
    figureIsLoading
  ])

  useEffect(() => {
    setCurrentHasWatermark && setCurrentHasWatermark(hasWatermark)
  }, [setCurrentHasWatermark, hasWatermark])

  useEffect(() => {
    setCurrentHasDiagonalWatermark && setCurrentHasDiagonalWatermark(hasDiagonalWatermark)
  }, [setCurrentHasDiagonalWatermark, hasDiagonalWatermark])

  useEffect(() => {
    setCurrentDescription && setCurrentDescription(description)
  }, [setCurrentDescription, description])

  useEffect(() => {
    setCurrentAuthor && setCurrentAuthor(author)
  }, [setCurrentAuthor, author])

  useEffect(() => {
    setCurrentAlt && setCurrentAlt(alt)
  }, [setCurrentAlt, alt])

  useEffect(() => {
    setCurrentFigure && setCurrentFigure(figure)
  }, [setCurrentFigure, figure])

  useEffect(() => {
    setCurrentAlignY && setCurrentAlignY(alignY)
  }, [setCurrentAlignY, alignY])

  useEffect(() => {
    setCurrentExtParams &&
      setCurrentExtParams({
        isFullWidth: extParams?.isFullWidth || false
      })
  }, [setCurrentExtParams, extParams])

  const getAlt = useCallback(() => {
    return alt
  }, [alt])

  useEffect(() => {
    const currentAlt = getAlt()
    if (
      description?.length &&
      (currentAlt === '' ||
        description?.includes(currentAlt || '', 0) ||
        description?.includes(
          (currentAlt || '').slice(0, (currentAlt?.length || 0) > 0 ? (currentAlt?.length || 0) - 1 : 0),
          0
        ))
    ) {
      setDefaultAlt(description)
    }
    if (description && description?.length > ANOUNCE_IMAGE_MODAL_CONSTS.maxDescriptionLength) {
      setDescriptionErrorText(ANOUNCE_IMAGE_MODAL_CONSTS.characterLimitReachedMsg)
    } else {
      setDescriptionErrorText('')
    }
    if (!description && descriptionDirty) {
      setDescriptionErrorText(ANOUNCE_IMAGE_MODAL_CONSTS.fieldIsRequiredMsg)
    }
  }, [description, descriptionDirty, getAlt, setDefaultAlt, setDescriptionErrorText])

  const clearFigure = useCallback(() => {
    setFigure({} as Figure)
    setCurSocialImage({} as SocialMediaImage)
    setAlignY(0)
    setImageUrl('')
    setHasDiagonalWatermark(false)
    setDefaultAlt('')
    setAuthor('')
    setAuthorErrorText('')
    setDefaultAuthor('')
    setDescription('')
    setDescriptionDirty(false)
    setDescriptionErrorText('')
    setAlt('')
    // setIsFullWidth(false)
    setExtParams({isFullWidth: false})
    setClearFieldEventCounter(prev => prev + 1)
    // setDisabledFormState(true)
  }, [])

  useEffect(() => {
    setExtParams({isFullWidth})
  }, [isFullWidth])

  useEffect(() => {
    setExtAnnounceParams({ogTitle, ogDescription})
  }, [ogTitle, ogDescription])

  return (
    <>
      <AnnounceImageModalImagesWrapper>
        <ImageCropper
          figure={figure}
          imageUrl={imageUrl}
          description={description || ''}
          hasWatermark={hasWatermark || false}
          hasDiagonalWatermark={hasDiagonalWatermark || false}
          setImageUrl={setImageUrl}
          setAuthor={author => {
            setDefaultAuthor(author)
            setAuthor(author)
          }}
          setDescription={setDescription}
          setFigure={setFigure}
          setAlt={setDefaultAlt}
          setHasWatermark={setHasWatermark}
          setHasDiagonalWatermark={setHasDiagonalWatermark}
          ratio={16 / 9}
          figureTypeSlug={figureTypeSlug}
          cropperType={breakingNews ? CropperType.isBreakingNews : CropperType.isAnnounce}
          isCustomRatio={true}
          figureIsLoading={figureIsLoading}
        />
        {figure.src && (
          <div
            style={{
              display: 'block',
              position: 'absolute',
              left: width >= 655 ? (breakingNews ? '161px' : '228px') : '90%',
              top: breakingNews ? '121px' : '13px'
            }}
          >
            <Tooltip title={ANOUNCE_IMAGE_MODAL_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton edge='end' size='small' id='announce-image-modal-delete-photo' onClick={() => clearFigure()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {defaultSocialImage?.id && modalId.includes(defaultSocialImage?.id) && (
              <Tooltip title={ANOUNCE_IMAGE_MODAL_CONSTS.changeCropLabelMsg}>
                <IconButton edge='end' size='small' onClick={() => clearFigure()} id='announce-image-modal-change-crop'>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
        {curSocialImage?.id && !breakingNews && (
          <SocialImageWrapper>
            <SocialImage socialImage={curSocialImage} publicationTitle={publicationTitle || ''} setAlygnY={setAlignY} />
          </SocialImageWrapper>
        )}
      </AnnounceImageModalImagesWrapper>
      <Box display='flex' flexDirection='column' marginTop='33px' width={!!breakingNews ? '206px' : '100%'}>
        <Box>
          <StyledFormControl width='100%' required error={!description && descriptionDirty}>
            <div style={{flexDirection: 'row'}}>
              <InputLabel
                style={{
                  width: '100%'
                }}
              >
                {breakingNews
                  ? ANOUNCE_IMAGE_MODAL_CONSTS.descriptionPhotoLabelMsg
                  : ANOUNCE_IMAGE_MODAL_CONSTS.descriptionLabelMsg}
              </InputLabel>
              <OutlinedInput
                label={
                  breakingNews
                    ? ANOUNCE_IMAGE_MODAL_CONSTS.descriptionPhotoLabelMsg
                    : ANOUNCE_IMAGE_MODAL_CONSTS.descriptionLabelMsg
                }
                error={!!descriptionErrorText}
                multiline
                style={{
                  float: 'left',
                  width: '100%',
                  marginBottom: '16px'
                }}
                id='announce-image-modal-description'
                onChange={e => {
                  setDescription(e.target.value)
                  setDescriptionDirty(true)
                }}
                value={description}
                required
              />
              {/* {breakingNews === false && (
                <TitleLength
                  style={{
                    color: !!descriptionErrorText ? redColor : 'inherit'
                  }}
                >
                  {`${description?.length || 0} / ${ANOUNCE_IMAGE_MODAL_CONSTS.maxDescriptionLength}`}
                </TitleLength>
              )} */}
            </div>
            {!!descriptionErrorText && (
              <FormHelperText
                style={{
                  color: redColor
                }}
              >
                {descriptionErrorText}
              </FormHelperText>
            )}
          </StyledFormControl>
        </Box>
        <Box display='flex' justifyContent='space-between' marginTop={!!breakingNews ? '30px' : '0px'}>
          <InputStringWithValidation
            title={
              breakingNews ? ANOUNCE_IMAGE_MODAL_CONSTS.authorPhotoLabelMsg : ANOUNCE_IMAGE_MODAL_CONSTS.authorLabelMsg
            }
            defaultValue={author || defaultAuthor}
            setValue={setAuthor}
            setErrorText={setAuthorErrorText}
            clearFieldEventCounter={clearFieldEventCounter}
            showTitleLength={false}
            maxLength={250}
            width={'100%'}
            id={'announce-image-modal-author'}
          />
          {/* {!breakingNews && (
            <InputStringWithValidation
              id={'announce-image-modal-alt'}
              title={ANOUNCE_IMAGE_MODAL_CONSTS.altLabelMsg}
              defaultValue={defaultAlt}
              setValue={setAlt}
              setErrorText={setAltErrorText}
              showTitleLength={false}
              maxLength={777}
              width={'45%'}
            />
          )} */}
        </Box>
        {!breakingNews && (
          <Box display='flex' flexDirection='column' marginTop='4px' width={'100%'}>
            <AccordionWrapper
              square
              expanded={expanded === 'announce-image-accordion'}
              onChange={handleChange('announce-image-accordion')}
            >
              <AccordionSummaryWrapper
                aria-controls='announce-image-accordion-content'
                id='announce-image-accordion-header'
              >
                <AccordionSummaryTitleWrapper>
                  {ANNOUNCE_IMAGE_MODAL_FIELDS_CONSTS.editHeaderAndAnnounceMsg}
                </AccordionSummaryTitleWrapper>
                <ArrowDropDownIconWrapper>
                  <ArrowDropDownIcon
                    fontSize='medium'
                    style={{
                      transform: `${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}`
                    }}
                  />
                </ArrowDropDownIconWrapper>
              </AccordionSummaryWrapper>
              <AccordionDetailsWrapper>
                <DetailRowWrapper>
                  <InputStringWithValidation
                    id={'announce-image-modal-social-title'}
                    title={ANNOUNCE_IMAGE_MODAL_FIELDS_CONSTS.ogTitleLabelMsg}
                    defaultValue={ogTitle}
                    setValue={setOgTitle}
                    setErrorText={setOgTitleErrorText}
                    showTitleLength={false}
                    maxLength={100}
                    width={'100%'}
                  />
                  <TitleLength
                    style={{
                      color: !!ogTitleErrorText ? redColor : 'inherit'
                    }}
                  >
                    {`${ogTitle?.length || 0} / ${ANOUNCE_IMAGE_MODAL_CONSTS.maxOgTitleLength}`}
                  </TitleLength>
                </DetailRowWrapper>
                <Typography color='primary' component='div' marginTop={'5px'}>
                  <InfoWrapper>
                    <InfoOutlinedIcon
                      style={{
                        color: '#1964e7',
                        transform: 'scale(0.6)'
                      }}
                    />
                    {ANNOUNCE_IMAGE_MODAL_FIELDS_CONSTS.autoCompleteTitleMsg}
                  </InfoWrapper>
                </Typography>
                <DetailRowWrapper>
                  <InputStringWithValidation
                    id={'announce-image-modal-social-announce'}
                    title={ANNOUNCE_IMAGE_MODAL_FIELDS_CONSTS.ogDescriptionLabelMsg}
                    defaultValue={ogDescription}
                    setValue={setOgDescription}
                    setErrorText={setOgDescriptionErrorText}
                    showTitleLength={false}
                    maxLength={140}
                    width={'100%'}
                    required={false}
                  />
                  <TitleLength
                    style={{
                      color: !!ogDescriptionErrorText ? redColor : 'inherit'
                    }}
                  >
                    {`${ogDescription?.length || 0} / ${ANOUNCE_IMAGE_MODAL_CONSTS.maxOgDescriptionLength}`}
                  </TitleLength>
                </DetailRowWrapper>
              </AccordionDetailsWrapper>
            </AccordionWrapper>
          </Box>
        )}
      </Box>
      {publication.typeSlug && (publication.typeSlug === 'articles' || publication.typeSlug === 'news') && (
        <Box marginTop={'10px'}>
          <FormControlLabel
            label={IMAGE_EDITOR_MODAL_CONSTS.fullWidthLabelMsg}
            control={
              <Switch
                checked={isFullWidth}
                value={isFullWidth}
                onChange={() => setIsFullWidth(prevState => !prevState)}
              />
            }
          />
        </Box>
      )}
    </>
  )
}
export default observer(AnnounceImageModalFields)
