import React, {FC} from 'react'

const FullScreenNerIcon: FC<any> = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11 1H15V5' stroke='#1964E7' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 6L15 1' stroke='#1964E7' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 15H1V11' stroke='#1964E7' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 10L1 15' stroke='#1964E7' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default FullScreenNerIcon
