import React, {FC, useEffect, useState} from 'react'
import {Box, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {SEND_TO_RAMBLER_PICKER_CONSTS} from './SendToRamblerPicker.consts'

const RamblerRssFillingRules: FC = () => {
  const {publicationStore} = useStore()
  const {rubricAndSubrubricStore} = publicationStore
  const {rubricsForRamblerList} = rubricAndSubrubricStore

  const [availableRubricList, setAvaliableRubricList] = useState('')

  useEffect(() => {
    if (rubricsForRamblerList?.length) {
      setAvaliableRubricList(rubricsForRamblerList.join(', '))
    }
  }, [rubricsForRamblerList])
  return (
    <div>
      <Box>
        <Typography variant='text14R'>Правила заполнения заголовка:</Typography>
      </Box>
      <Box marginTop={'10px'}>
        <Typography variant='text14R'>
          1. В Рамблер публикуются новости по следующим рубрикам и подрубрикам:{' '}
        </Typography>
        <Typography variant='text14M'>{availableRubricList}</Typography>
      </Box>
      <Box marginTop={'10px'}>
        <Typography variant='text14R'>2. Заголовок для Рамблера не должен превышать</Typography>
      </Box>
      <Box>
        <Typography variant='text14M'>{SEND_TO_RAMBLER_PICKER_CONSTS.ramblerTitleMaxLength} символов</Typography>
      </Box>
    </div>
  )
}

export default observer(RamblerRssFillingRules)
