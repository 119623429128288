import styled from 'styled-components'

export const PublicationFormDetailsWrapper = styled.div`
  /* position: relative;
    overflow-y: auto; */
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  max-width: 1266px;
  /* padding: 20px 10px 80px; */
  /* background-color: #fafafa; */
  /* box-shadow: -1px 0 4px rgb(0 0 0 / 25%); */
`
export const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-bottom: 12px;
  width: 100%;
`

export const DetailsRowSelectsCheckBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const DetailsDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`

export const DeviderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  border: 1px solid #edeef1;
  margin: 8px 0px;
`

export const SpecialProjectWrapper = styled.div`
  margin-bottom: 12px;
`

export const SpecialProjectWithInsideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
`

export const BoxWithInfo = styled.div`
  display: flex;
  align-items: center;
`
