import {makeAutoObservable, runInAction} from 'mobx'
import {toast} from 'react-toastify'
import {fetchCheckInoagents} from '@fetch/NnAPI'
import {RootStore} from '@stores/rootStore'
import IAgentCheckStore from './IAgentCheckStore'

class AgentCheckStore implements IAgentCheckStore {
  isOpen = false
  isChecking = false
  isPending = false
  isDisabled = false
  actionType = ''
  listInoagent = {}
  inoagentMark = undefined
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setParamDefault = () => {
    this.isOpen = false
    this.isChecking = false
    this.isPending = false
    this.isDisabled = false
    this.actionType = ''
    this.listInoagent = {}
  }

  // Метод для проверки иноагентов перед действием
  async checkAgents(actionType, methods?, fields?): Promise<void> {
    const {editorStore, publicationStore} = this.rootStore
    const {editorRef} = editorStore
    const {publication} = publicationStore

    const quill = editorRef.current?.getEditor()
    if (!quill) {
      return
    }

    const delta = quill.getContents()
    let requestText = ''

    delta.forEach(item => {
      if (typeof item.insert === 'string') {
        requestText += item.insert
        requestText += ' '
      }
    })

    const cleanText = requestText.replace(/\s+\*/g, '*').trim()

    const data = {
      params: cleanText,
      publicationId: publication.deprecatedId,
      publicationUid: publication.uid
    }

    try {
      this.isDisabled = true
      this.isChecking = true

      const response = await fetchCheckInoagents(data)

      if (response && (response['INOAGENT'].length > 0 || response['EXTREMIST'].length > 0)) {
        // Обнаружены неотмеченные иноагенты
        runInAction(() => {
          this.isOpen = true
          this.actionType = actionType
          this.isChecking = false
          this.listInoagent = response
        })
      } else {
        runInAction(() => {
          this.isChecking = false
          this.actionType = actionType
          this.inoagentMark = response['INOAGENT MARKED']
        })
        this.onConfirm(methods, fields)

        //toast вывести
        return
      }
    } catch (error) {
      // NER-сервис недоступен\
      runInAction(() => {
        this.isOpen = true
        this.actionType = actionType
        this.isChecking = false
        this.listInoagent = {}
      })
    }
  }

  // Пользователь подтвердил действие
  onConfirm(methods, fields: any): void {
    this.isOpen = false
    this.isPending = true
    this.isDisabled = true
    if (this.actionType) {
      this.proceedAction(this.actionType, methods, fields)
    }
  }

  // Пользователь отменил действие
  onCancel() {
    this.isOpen = false
    this.isChecking = false
    this.isDisabled = false
  }

  // Выполняем исходное действие после проверки
  proceedAction(actionType, methods?, fields?): void {
    const {publicationStore} = this.rootStore
    switch (actionType) {
      case 'Опубликовать':
        publicationStore.publishPublicationAction(methods, fields).then(() => {
          runInAction(() => {
            this.isDisabled = false
            this.isPending = false
          })
          if (this.inoagentMark !== undefined) {
            if (this.inoagentMark) {
              toast.success('Все иноагенты отмечены', {
                containerId: 'success'
              })
              return
            }
            toast.success('Иноагенты не найдены', {
              containerId: 'success'
            })
          }
        }) //для черновиков
        break
      // case 'Сохранить':
      //   publicationStore.savePublicationAction() // для опубликованных
      //   break
      // case 'Закончить':
      //   publicationStore.sendPublicationForReviewAction() // для авторов
      //   break
      default:
        break
    }
  }
}

export default AgentCheckStore
