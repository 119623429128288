import {makeAutoObservable} from 'mobx'
import {IDialogStore, MessageType} from './INnStore'

class DialogStore implements IDialogStore {
  isDialogOpen = false
  messages: MessageType[] = []
  abortController: AbortController | null = null
  isLoading = false
  isLoadingNer = false
  isRollUp = false
  messageIdCounter = 1
  indexSelectedText: number | undefined
  lengthSelectedText: number | undefined

  constructor() {
    makeAutoObservable(this)
  }
  setSelectedText(index: number, length: number): void {
    this.indexSelectedText = index
    this.lengthSelectedText = length
  }

  openDialog(): void {
    this.isDialogOpen = true
  }

  roleUpDialog(): void {
    this.isDialogOpen = !this.isDialogOpen
    this.isRollUp = !this.isRollUp
  }

  closeDialog(): void {
    this.isDialogOpen = false
    this.resetMessages()
    this.handleCancel()
  }

  addMessage(message: Omit<MessageType, 'id'>): void {
    this.messages.push({id: this.messageIdCounter++, ...message})
  }

  deleteLastMessage(): void {
    this.messages.pop()
    this.messageIdCounter--
  }

  deleteErrorMessage(): void {
    this.messages.splice(-2)
    this.messageIdCounter -= 2
  }

  resetMessages(): void {
    this.messages = []
    this.messageIdCounter = 1
    this.indexSelectedText = undefined
    this.lengthSelectedText = undefined
  }

  setAbortController(controller: AbortController | null): void {
    this.abortController = controller
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading
  }

  setIsLoadingNer(loading: boolean): void {
    this.isLoadingNer = loading
  }

  handleCancel(): void {
    if (this.abortController) {
      this.abortController.abort()
      this.setAbortController(null)
      this.setIsLoading(false)
    }
  }
}

export default DialogStore
