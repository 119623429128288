import {styled} from 'styled-components'
import {mainTextColor} from '@theme/vars'

export const DebugInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-family: 'Roboto';
  font-style: 'SemiBold';
  font-size: 18px;
  line-height: 24px;
`

export const DebugInfoBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const DebugInfoBlockLink = styled.a`
  display: block;
  margin-top: 8px;
  font-size: 18px;
  color: ${mainTextColor};
  text-decoration: underline;
`
