import {Quill} from 'react-quill'

interface insertSelectedTextParams {
  quill: Quill
  index: number
  length: number
  text: string
}

const Delta = Quill.import('delta')

export const insertSelectedText = ({quill, index, length, text}: insertSelectedTextParams): void => {
  quill.updateContents(
    index !== 0
      ? new Delta({
          ops: [
            {
              retain: index
            },
            {
              delete: length
            },
            {
              insert: text
            }
          ]
        })
      : new Delta({
          ops: [
            {
              delete: length
            },
            {
              insert: text
            }
          ]
        }),
    'user'
  )
  quill.focus()
  quill.setSelection(index + text.length, 0)
}
