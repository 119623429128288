import {format} from 'date-fns'
import {isValidDate} from './isValidDate'

export const isEqualDates = (date1: Date | null | undefined, date2: Date | null | undefined): boolean => {
  if ((!date1 && !date2) || date1 == date2) {
    return true
  }

  if (!isValidDate((date1 || '').toString()) || !isValidDate((date2 || '').toString())) {
    return false
  }

  const curTime = new Date()
  const date1Val = format(new Date(date1 ?? curTime), 'yyyy-MM-dd HH:mm')
  const date2Val = format(new Date(date2 ?? curTime), 'yyyy-MM-dd HH:mm')

  return date1Val == date2Val
}
