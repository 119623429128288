import {mainTextColor} from '../../../../@theme/vars'
import {NnItem} from './NnItem'

export const TOOLBAR_SIZE_CONSTS = {
  sizeItems: [
    {
      title: 'Написать бэк',
      color: mainTextColor,
      size: 'background'
    } as NnItem,
    // {
    //   title: 'Написать бэк (вектор)',
    //   color: mainTextColor,
    //   size: 'background'
    // } as NnItem,
    {
      title: 'Сгенерировать текст по теме',
      color: mainTextColor,
      size: 'generation'
    } as NnItem,
    {
      title: 'Переписать выделенный текст',
      color: mainTextColor,
      size: 'rerite'
    } as NnItem,
    // {
    //   title: 'Тэгирование',
    //   color: mainTextColor,
    //   size: 'taging'
    // } as NnItem,
    {
      title: 'Рерайт',
      color: mainTextColor,
      size: 'testrerite'
    } as NnItem
  ],
  textSize: 'Воспользоваться нейросетью',
  textLoading: 'Запрос в обработке'
}

export const TOOLBAR_BUTTONS_TITLES = {
  inoagent: {
    inoagentTitle: 'Проверить иноагентов и экстремистов',
    inoagentMarked: 'Все иноагенты и экстремисты помечены',
    inoagentNotFound: 'Найдено: 0 иноагентов и экстремистов'
  },
  generateTextTopic: 'Сгенерировать текст по теме',
  back: {
    backTitle: 'Написать бэк',
    backWithRegionsTitle: 'Написать бэк(регионы)',
    notFoundRegions: 'Сначала выберите регионы',
    backNotFound: 'Нет похожих публикаций',
    publicationNotFound: 'Публикация не найдена'
  },
  rewriteText: {
    rewrite: 'Рерайт',
    overwrite: 'Переписать выделенный текст'
  },
  notSelectionText: 'Сначала выделите текст',
  notFoundText: 'Сначала напишите текст',
  fetchError: 'Ошибка при получении данных от сервера'
}

export const TOOLBAR_DIALOG_CONSTS = {
  HEADER: {
    helpNn: 'Помощь ИИ'
  },
  CONTENT: {
    textForRewrite: 'Сделай рерайт текста:',
    textForReword: 'Перепиши выделенный текст:',
    textForGenerate: 'Сгенерируй текст по теме',
    saveButton: 'Сохранить правки',
    retryButton: 'Попробовать еще раз'
  },
  FOOTER: {
    placeholderInput: 'Напишите свой запрос ИИ'
  },
  MODAL: {
    titleModal: 'Внимание!',
    textModal: 'Применить изменения, предложенные ИИ?',
    okButtonModal: 'Применять',
    noButtonModal: 'Не применять'
  },
  ROLLUP: {
    rollUpLoading: 'запрос в обработке',
    rollUpError: 'произошла ошибка',
    rollUpSuccess: 'запрос обработан'
  }
}
