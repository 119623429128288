import React, {FC, useCallback, useEffect, useState} from 'react'
import {InputLabel, OutlinedInput, Typography} from '@mui/material'
import styled from 'styled-components'
import {IFrameElement} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import {useVideoEmbedUrlLazyQuery} from '@pages/Publication/gql/Publication.generated'
import SimpleModal, {SimpleModalProps} from '../SimpleModal/SimpleModal'
import {StyledFormControl} from '../styled'
import {VideoBlot} from '../../TextEditor/Blots/VideoBlot/Video'

const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const BottomText = styled(Typography)`
  margin: 8px 0 0 12px;
  text-align: center;
`

type VideoEditorModalProps = {
  defaultVideo?: IFrameElement
  onClose: () => void
  setVideo: ({}: VideoBlot) => void
} & SimpleModalProps

const VideoEditorModal: FC<VideoEditorModalProps> = ({
  defaultVideo,
  open,
  onClose,
  title,
  text,
  okLabel,
  noLabel,
  setVideo
}) => {
  const bottomText =
    'Поддерживаются VK, YouTube, Rutube, 5-tv. Для всех остальных площадок нужно воспользоваться вставкой html-кода'
  const videoLinkText = 'Ссылка на видео'
  const [id, setId] = useState(defaultVideo?.id || generateGuid())
  const [src, setSrc] = useState(defaultVideo?.src || '')

  useEffect(() => {
    if (defaultVideo?.id && defaultVideo?.src) {
      setId(defaultVideo?.id || '')
      setSrc(defaultVideo?.src || '')
    }
  }, [defaultVideo])

  const [videoEmbedUrl, {loading}] = useVideoEmbedUrlLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.videoEmbedUrl || defaultVideo?.src) {
        setVideo({
          id: id || defaultVideo?.id || generateGuid(),
          src: data?.videoEmbedUrl || defaultVideo?.src || '',
          title: defaultVideo?.title || '',
          width: defaultVideo?.width || null,
          height: defaultVideo?.height || null,
          allow: defaultVideo?.allow || '',
          allowFullscreen: defaultVideo?.allowFullscreen || null,
          frameborder: defaultVideo?.frameborder || null
        })
        clearForm()
      }
    }
  })

  const clearForm = useCallback(() => {
    setId('')
    setSrc('')
  }, [])

  const updateVideoAction = useCallback(() => {
    if (src) {
      videoEmbedUrl({
        variables: {url: src || defaultVideo?.src || ''}
      })
    }
  }, [setVideo, src])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={title}
      text={text}
      onOk={() => {
        updateVideoAction()
        onClose()
      }}
      onNo={() => {
        onClose()
        clearForm()
      }}
      okButtonDisabled={!src || loading}
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={noLabel}
    >
      <>
        <DetailsRowSelects>
          <StyledFormControl width='100%'>
            <InputLabel>{videoLinkText}</InputLabel>
            <OutlinedInput
              label={videoLinkText}
              onChange={e => setSrc(e.target.value)}
              value={src}
              disabled={loading}
              multiline
              autoFocus
            />
          </StyledFormControl>
        </DetailsRowSelects>
        {bottomText && <BottomText>{bottomText}</BottomText>}
      </>
    </SimpleModal>
  )
}

export default VideoEditorModal
