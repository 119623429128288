import {makeAutoObservable, runInAction} from 'mobx'
import {toast} from 'react-toastify'
import {format} from 'date-fns'
import {isNull} from 'lodash'
import {updatePublicationAdapterToClient} from '@entities/api/publication/adapters/updatePublicationAdapterToClient'
import {PublicationStatus, UpdatePublicationExportInput, UpdatePublicationInput} from '@graphql/types'
import {RootStore} from '@stores/rootStore'
import {getQuillEditor} from '@utils/getQuillEditor'
import {TypographReplacer} from '@hooks/typographReplacer'
import {clearRemovedUrlsPersons} from '@utils/removedPersonsStorage'
import {fetchCheckText} from '@fetch/NnAPI'
import {PUBLICATION_ACTION_BUTTON_CONSTS} from '@components/Publication/Form/PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationActionButton/PublicationActionButton.consts'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {UiPublication} from 'entities/types/UiPublication'
import {UiStory} from 'entities/types/UiStory'
import {UiFigure} from 'entities/types/UiFigure'
import {UiRegion} from 'entities/types/UiRegion'
import IPublicationStore from './IPublicationStore'
import ImageModalStore from './modals/imageModalStore/imageModalStore'
import AnnounceImageModalStore from './modals/announceImageModalStore/announceImageModalStore'
import OverviewBlockImageModalStore from './modals/overviewBlockImageModalStore/overviewBlockImageModalStore'
import RubricAndSubrubricStore from './rubricAndSubrubricStore/rubricAndSubrubricStore'
import GalleryModalStore from './modals/galleryModalStore/galleryModalStore'
import {defaultPublication} from './publicationStore.defaultValues'

class PublicationStore implements IPublicationStore {
  announceImageModalStore = new AnnounceImageModalStore(this)
  galleryModalStore = new GalleryModalStore(this)
  imageModalStore = new ImageModalStore(this)
  overviewBlockImageModalStore = new OverviewBlockImageModalStore(this)
  rubricAndSubrubricStore = new RubricAndSubrubricStore(this)

  publication: UiPublication = defaultPublication

  publicationLoading = false
  publicationContentLoading = false
  destinationsDirty = false
  checkYandexDzenTitleValidation = false
  checkingPhotoEditor = false
  checkingPhotoEditorCache = false
  hasTitlesError = false
  publicationFormDirty = false
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setCheckingPhotoEditor = (value: boolean): void => {
    this.checkingPhotoEditor = value
  }

  setCheckingPhotoEditorCache = (value: boolean): void => {
    this.checkingPhotoEditorCache = value
  }

  setPublication = (value: UiPublication): void => {
    this.publication = value
  }

  setPublicationWithPrevState = (cb: (prev: UiPublication) => UiPublication): void => {
    this.publication = cb(this.publication)
  }

  setDefaultPublication = (): void => {
    this.publication = defaultPublication
  }

  setPublicationLoading = (value: boolean): void => {
    this.publicationLoading = value
  }

  setPublicationContentLoading = (value: boolean): void => {
    this.publicationContentLoading = value
  }

  setDestinationsDirty = (value: boolean): void => {
    this.destinationsDirty = value
  }

  setCheckYandexDzenTitleValidation = (value: boolean): void => {
    this.checkYandexDzenTitleValidation = value
  }

  setTitlesError = (value: boolean): void => {
    this.hasTitlesError = value
  }
  setPublicationFormDirty = (value: boolean): void => {
    this.publicationFormDirty = value
  }

  publishPublicationAction = async (
    methods: {
      updatePublication: (options: any) => Promise<any>
      publishPublication: (options: any) => Promise<any>
      publishOverviewBlocksDraft: (options: any) => Promise<any>
    },
    fields: PublicationInputType
  ): Promise<any> => {
    const quill = getQuillEditor(this.rootStore.editorStore.editorRef)

    if (quill) {
      const quotesReplacer = new TypographReplacer()
      quotesReplacer.runTypograph(quill.container)
    }

    const messageForToast =
      fields.publishedAt < new Date() || isNaN(fields.publishedAt) || isNull(fields.publishedAt)
        ? PUBLICATION_ACTION_BUTTON_CONSTS.publishedMsg
        : PUBLICATION_ACTION_BUTTON_CONSTS.willPublishMsg + format(fields.publishedAt, 'dd.MM.yyyy HH:mm')

    try {
      let isFullWidthValue = true
      if (!fields.announceImage?.figureVersion?.id) {
        isFullWidthValue = this.publication.typeSlug === 'articles' ? true : false
      } else {
        isFullWidthValue =
          fields.announceImage?.figureVersion?.isFullWidth || this.publication.announceImage?.isFullWidth || false
      }

      // Формируем данные для мутации
      const variablesData: UpdatePublicationInput = {
        publicationUid: this.publication.uid,
        title: fields.title,
        yandexTitle: fields.yandexTitle,
        yandexDzenTitle: fields.yandexDzenTitle || '',
        subtitle: fields.subtitle,
        isCommentsEnabled: fields.isCommentsDisabled == true ? false : true,
        isAutomaticCommentsDisabled: fields.isAutomaticCommentsDisabled == true ? true : false,
        isImportant: fields.isImportant === true ? true : false,
        specProjectUrl: fields.specProjectUrl || '',
        regionUids: fields.regionUids,
        overviewTitle: fields.overviewTitle || '',
        overviewDescription: fields.overviewDescription || '',
        isInside: fields.isInside,
        tagUids: fields.tagsUids,
        collectionUids: fields.collectionUids,
        storyUid: fields.storyUid?.length ? fields.storyUid : null,
        rubricId: this.rubricAndSubrubricStore.rubricIdCache?.length
          ? this.rubricAndSubrubricStore.rubricIdCache
          : null,
        subrubricId: this.rubricAndSubrubricStore.subrubricIdCache?.length
          ? this.rubricAndSubrubricStore.subrubricIdCache
          : null,
        subscriptionBlockUid: fields.subscriptionBlockUid?.length ? fields.subscriptionBlockUid : null,
        authors: fields.authors?.filter(author => author),
        keywords: fields.keywords,
        announceImage: {
          figureVersionId:
            this.announceImageModalStore.announceFigureCache?.figureVersionId ||
            this.publication?.announceImage?.figureVersion?.id ||
            fields.announceImage?.figureVersion?.id,
          isFullWidth:
            this.announceImageModalStore.announceFigureCache?.isFullWidth ||
            this.publication?.announceImage?.isFullWidth !== undefined
              ? this.publication?.announceImage?.isFullWidth || false
              : fields.announceImage?.isFullWidth || false,
          description:
            this.announceImageModalStore.announceFigureCache?.description ||
            this.publication?.announceImage?.description ||
            fields.announceImage?.description ||
            '',
          author:
            this.announceImageModalStore.announceFigureCache?.author ||
            this.publication?.announceImage?.author ||
            fields.announceImage?.author ||
            '',
          alt:
            this.announceImageModalStore.announceFigureCache?.description ||
            this.publication?.announceImage?.description ||
            fields.announceImage?.description ||
            ''
        }
      }

      if (fields.seoTitle?.length) {
        variablesData.seoTitle = fields.seoTitle
      }
      if (fields.overviewFigureVersionId?.length) {
        variablesData.overviewFigureVersionId = fields.overviewFigureVersionId
      }
      if (fields.overviewFigureId?.length) {
        variablesData.overviewFigureId = fields.overviewFigureId
      }
      if (fields.ogTitle?.length) {
        variablesData.ogTitle = this.publication?.ogTitle || fields.ogTitle || ''
      }
      if (fields.ogDescription?.length) {
        variablesData.ogDescription = this.publication?.ogDescription || fields.ogDescription || ''
      }

      const updatePublicationRes = await methods.updatePublication({
        variables: {
          data: variablesData
        }
      })
      const updatePublicationData = updatePublicationAdapterToClient(updatePublicationRes)

      if (updatePublicationData) {
        const newPublicationSchema = await methods.publishPublication({
          variables: {
            data: {
              publicationUid: this.publication?.uid,
              publishedAt: fields.publishedAt
            }
          }
        })

        this.setPublicationWithPrevState(prev => ({
          ...prev,
          title: updatePublicationData?.title,
          yandexTitle: updatePublicationData?.yandexTitle,
          yandexDzenTitle: updatePublicationData?.yandexDzenTitle,
          subtitle: updatePublicationData?.subtitle,
          seoTitle: updatePublicationData?.seoTitle,
          isCommentsEnabled: updatePublicationData?.isCommentsEnabled,
          isImportant: updatePublicationData?.isImportant,
          sendToRambler: updatePublicationData?.sendToRambler,
          specProjectUrl: updatePublicationData?.specProjectUrl,
          isInside: updatePublicationData?.isInside,
          story: updatePublicationData?.story ? ({uid: updatePublicationData?.story?.uid || ''} as UiStory) : null,
          overviewTitle: updatePublicationData?.overviewTitle,
          overviewDescription: updatePublicationData?.overviewDescription,
          authors: updatePublicationData?.authors,
          keywords: updatePublicationData?.keywords || '',
          yandexNewsVideos:
            updatePublicationData?.yandexNewsVideos?.map(item => ({
              id: item.id,
              title: item.title,
              uri: item.uri,
              uid: item.uid,
              thumbnail: item.thumbnail as UiFigure
            })) || [],
          subscriptionBlockUid: updatePublicationData?.subscriptionBlock?.uid || '',
          storyUid: updatePublicationData?.story?.uid || null,
          regionUids: updatePublicationData?.regions?.map(v => v?.uid) || [],
          regions: (updatePublicationData?.regions as UiRegion[]) || [],
          tagsUids: updatePublicationData?.tags?.map(v => v?.uid) || [],
          collectionUids: updatePublicationData?.collections?.map(v => v?.uid) || [],
          isCommentsDisabled: !(updatePublicationData?.isCommentsEnabled as boolean),
          publishedAt:
            updatePublicationData?.status === PublicationStatus.Published ? updatePublicationData?.publishedAt : null,
          destinationExport: {
            destinationSlugs: (updatePublicationData?.destinations?.map(v => v?.slug) as string[]) || [],
            yandexZenCategoryUids: (updatePublicationData?.yandexZenCategories?.map(v => v?.id) as string[]) || []
          } as UpdatePublicationExportInput,
          imageUrl: '',
          relatedLinks: updatePublicationData?.relatedLinks || [],
          overviewFigureId: updatePublicationData?.overviewFigureId,
          overviewFigureVersionId: updatePublicationData?.overviewFigureVersionId,
          destinationIds: (updatePublicationData?.destinations?.map(v => v?.id) as string[]) || [],
          // announceImage: updatePublicationData?.announceImage as UiAnnounceImage,
          ogTitle: updatePublicationData?.ogTitle || '',
          ogDescription: updatePublicationData?.ogDescription || '',
          // announceFigureVersionId:
          //   updatePublicationData?.announceImage?.figureVersion?.id || '',
          badwords: updatePublicationData?.badwords || false
        }))

        this.announceImageModalStore.setAnnounceFigureCache(undefined)

        if (!newPublicationSchema?.errors?.length) {
          if (newPublicationSchema.data?.publishPublication?.publication?.status) {
            const publishOverviewBlocksDraftData = await methods.publishOverviewBlocksDraft({
              variables: {
                data: {
                  publicationId: this.publication?.id,
                  url:
                    this.publication.canonicalUrl ||
                    newPublicationSchema.data?.publishPublication?.publication?.canonicalUrl ||
                    this.publication.url ||
                    '',
                  figureVersionId:
                    this.publication?.overviewFigureVersionId || this.publication?.announceImage?.figureVersion?.id,
                  title: this.publication?.overviewTitle || this.publication?.title || '',
                  subtitle: this.publication?.overviewDescription || this.publication?.subtitle || ''
                }
              }
            })

            const delta = quill.getContents()
            const deprecatedId = newPublicationSchema.data.publishPublication.publication.deprecatedId
            let requestText = ''

            delta.forEach(item => {
              if (typeof item.insert === 'string') {
                requestText += item.insert
                requestText += ' '
              }
            })

            fetchCheckText({publicationId: deprecatedId, publicationUid: this.publication.id, params: requestText})

            runInAction(() => {
              this.publication = {
                ...this.publication,
                status:
                  newPublicationSchema.data?.publishPublication?.publication?.status || PublicationStatus.Published,
                canonicalUrl: newPublicationSchema.data?.publishPublication?.publication?.canonicalUrl || ''
              }
            })
          }

          toast.success(messageForToast, {
            autoClose: false,
            hideProgressBar: true,
            containerId: 'success'
          })
          clearRemovedUrlsPersons(this.publication.uid)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default PublicationStore
