import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdatePublicationMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsFragment>
    }
  >
}

export type UpdatePublicationMainFieldsMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationMainFieldsMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & Pick<
          Types.Publication,
          | 'title'
          | 'yandexTitle'
          | 'yandexDzenTitle'
          | 'subtitle'
          | 'deprecatedId'
          | 'seoTitle'
          | 'overviewTitle'
          | 'overviewDescription'
          | 'isCommentsEnabled'
          | 'isAutomaticCommentsDisabled'
          | 'isImportant'
          | 'sendToRambler'
          | 'specProjectUrl'
          | 'isInside'
          | 'keywords'
        > & {
            tags?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'uid' | 'title'>>>>
            collections?: Types.Maybe<
              Array<Types.Maybe<{__typename?: 'Collection'} & Pick<Types.Collection, 'uid' | 'title'>>>
            >
            story?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'uid' | 'title'>>
            subscriptionBlock?: Types.Maybe<
              {__typename?: 'SubscriptionBlock'} & Pick<Types.SubscriptionBlock, 'uid' | 'title'>
            >
            authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
          }
      >
    }
  >
}

export type UpdatePublicationRegionsMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationRegionsMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & {
          regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>>
        }
      >
    }
  >
}

export type UpdatePublicationAnnounceImageOnlyMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationAnnounceImageOnlyMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsAnnounceImageOnlyFragment>
    }
  >
}

export type UpdatePublicationSocialImageOnlyMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationSocialImageOnlyMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsSocialImageOnlyFragment>
    }
  >
}

export type UpdatePublicationAnnounceFieldsMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationAnnounceFieldsMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'ogTitle' | 'ogDescription'>>
    }
  >
}

export type UpdateAnnounceImagePublicationMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdateAnnounceImagePublicationMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & {
          announceImage: {__typename?: 'AnnounceImage'} & Pick<
            Types.AnnounceImage,
            'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
          > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
          socialMediaImage: {__typename?: 'SocialMediaImage'} & {
            socialMediaFigureVersion?: Types.Maybe<
              {__typename?: 'SocialMediaFigureVersion'} & Pick<
                Types.SocialMediaFigureVersion,
                'id' | 'uid' | 'alignY' | 'title'
              >
            >
          }
        }
      >
    }
  >
}

export type UpdatePublicationExtAnnounceParamsMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationExtAnnounceParamsMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'ogTitle' | 'ogDescription'>>
    }
  >
}

export type UpdatePublicationRubricAndSubrubricMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationRubricAndSubrubricMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & {
          rubric?: Types.Maybe<{__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title' | 'availableForRambler'>>
          subrubric?: Types.Maybe<
            {__typename?: 'Subrubric'} & Pick<Types.Subrubric, 'id' | 'title' | 'availableForRambler'>
          >
        }
      >
    }
  >
}

export type UpdatePublicationOverviewFigureMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationInput
}>

export type UpdatePublicationOverviewFigureMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & {
          overviewFigure?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>
        }
      >
    }
  >
}

export type ConvertPublicationToArticleMutationVariables = Types.Exact<{
  data: Types.ConvertPublicationToArticleInput
}>

export type ConvertPublicationToArticleMutation = {__typename?: 'Mutation'} & {
  convertPublicationToArticle?: Types.Maybe<
    {__typename?: 'ConvertPublicationToArticlePayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsWithAnnounceImageFragment>
    }
  >
}

export type SendPublicationForReviewMutationVariables = Types.Exact<{
  data: Types.SendPublicationForReviewInput
}>

export type SendPublicationForReviewMutation = {__typename?: 'Mutation'} & {
  sendPublicationForReview?: Types.Maybe<
    {__typename?: 'SendPublicationForReviewPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsWithAnnounceImageFragment>
    }
  >
}

export type CreateFastNewsMutationVariables = Types.Exact<{
  data: Types.FastNewsInput
}>

export type CreateFastNewsMutation = {__typename?: 'Mutation'} & {
  createFastNews?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsWithAnnounceImageFragment>
}

export type PublishPublicationMutationVariables = Types.Exact<{
  data: Types.PublishPublicationInput
}>

export type PublishPublicationMutation = {__typename?: 'Mutation'} & {
  publishPublication?: Types.Maybe<
    {__typename?: 'PublishPublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationEditorFieldsWithAnnounceImageFragment>
    }
  >
}

export type UnpublishPublicationMutationVariables = Types.Exact<{
  data: Types.UnpublishPublicationInput
}>

export type UnpublishPublicationMutation = {__typename?: 'Mutation'} & {
  unpublishPublication?: Types.Maybe<
    {__typename?: 'UnpublishPublicationPayload'} & Pick<Types.UnpublishPublicationPayload, 'status'> & {
        publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'status' | 'redirectUrl'>>
      }
  >
}

export type CreateFigureMutationVariables = Types.Exact<{
  data: Types.CreateFigureInput
}>

export type CreateFigureMutation = {__typename?: 'Mutation'} & {
  createFigure?: Types.Maybe<
    {__typename?: 'CreateFigurePayload'} & {
      figure?: Types.Maybe<
        {__typename?: 'Figure'} & {sourceImage: {__typename?: 'Image'} & Pick<Types.Image, 'url'>} & FigureFragment
      >
    }
  >
}

export type CreateFigureVersionMutationVariables = Types.Exact<{
  data: Types.CreateFigureVersionInput
}>

export type CreateFigureVersionMutation = {__typename?: 'Mutation'} & {
  createFigureVersion?: Types.Maybe<
    {__typename?: 'CreateFigureVersionPayload'} & {
      figureVersion?: Types.Maybe<
        {__typename?: 'FigureVersion'} & Pick<
          Types.FigureVersion,
          'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
        > & {
            figure: {__typename?: 'Figure'} & {
              sourceImage: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
            } & FigureFragment
          }
      >
    }
  >
}

export type CreateSocialMediaFigureVersionMutationVariables = Types.Exact<{
  data: Types.CreateSocialMediaFigureVersionInput
}>

export type CreateSocialMediaFigureVersionMutation = {__typename?: 'Mutation'} & {
  createSocialMediaFigureVersion?: Types.Maybe<
    {__typename?: 'CreateSocialMediaFigureVersionPayload'} & Pick<
      Types.CreateSocialMediaFigureVersionPayload,
      'status'
    > & {
        socialMediaFigureVersion?: Types.Maybe<
          {__typename?: 'SocialMediaFigureVersion'} & Pick<
            Types.SocialMediaFigureVersion,
            'id' | 'title' | 'alignY'
          > & {
              announceFigureVersion: {__typename?: 'FigureVersion'} & FigureVersionFragment
              processedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
            }
        >
      }
  >
}

export type SocialMediaFigureVersionQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']
}>

export type SocialMediaFigureVersionQuery = {__typename?: 'Query'} & {
  socialMediaFigureVersion?: Types.Maybe<
    {__typename?: 'SocialMediaFigureVersion'} & Pick<Types.SocialMediaFigureVersion, 'id' | 'title' | 'alignY'> & {
        announceFigureVersion: {__typename?: 'FigureVersion'} & FigureVersionFragment
        processedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
      }
  >
}

export type UpdatePublicationExportMutationVariables = Types.Exact<{
  data: Types.UpdatePublicationExportInput
}>

export type UpdatePublicationExportMutation = {__typename?: 'Mutation'} & {
  updatePublicationExport?: Types.Maybe<
    {__typename?: 'UpdatePublicationExportPayload'} & Pick<Types.UpdatePublicationExportPayload, 'status'> & {
        publication?: Types.Maybe<
          {__typename?: 'Publication'} & {
            yandexNewsVideos: Array<
              {__typename?: 'PublicationYandexNewsVideo'} & Pick<
                Types.PublicationYandexNewsVideo,
                'id' | 'uri' | 'title' | 'uid'
              > & {thumbnail: {__typename?: 'Figure'} & FigureFragment}
            >
            destinations?: Types.Maybe<
              Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'id' | 'slug' | 'title'>>>
            >
            yandexZenCategories: Array<
              {__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'id' | 'uid' | 'title'>
            >
          }
        >
      }
  >
}

export type UpdateFigureMutationVariables = Types.Exact<{
  data: Types.UpdateFigureInput
}>

export type UpdateFigureMutation = {__typename?: 'Mutation'} & {
  updateFigure?: Types.Maybe<
    {__typename?: 'UpdateFigurePayload'} & {figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>}
  >
}

export type StoriesPublicationFormQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
  search?: Types.Maybe<Types.Scalars['String']>
}>

export type StoriesPublicationFormQuery = {__typename?: 'Query'} & {
  stories?: Types.Maybe<
    {__typename?: 'StoriesConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'StoriesEdge'} & {
            node?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid' | 'title'>>
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export type TagsPublicationFormQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
  search?: Types.Maybe<Types.Scalars['String']>
}>

export type TagsPublicationFormQuery = {__typename?: 'Query'} & {
  tags: {__typename?: 'TagsConnection'} & {
    edges: Array<
      Types.Maybe<
        {__typename?: 'TagsEdge'} & {node?: Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title'>>}
      >
    >
    pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type CollectionsPublicationFormQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
  search?: Types.Maybe<Types.Scalars['String']>
}>

export type CollectionsPublicationFormQuery = {__typename?: 'Query'} & {
  collections?: Types.Maybe<
    {__typename?: 'CollectionsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'CollectionsEdge'} & {
            node?: Types.Maybe<{__typename?: 'Collection'} & Pick<Types.Collection, 'id' | 'uid' | 'title'>>
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
    }
  >
}

export type AuthorsPublicationQueryVariables = Types.Exact<{[key: string]: never}>

export type AuthorsPublicationQuery = {__typename?: 'Query'} & {
  authors?: Types.Maybe<Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>>
}

export type YandexZenCategoriesQueryVariables = Types.Exact<{[key: string]: never}>

export type YandexZenCategoriesQuery = {__typename?: 'Query'} & {
  yandexZenCategories?: Types.Maybe<
    Array<{__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'uid' | 'title'>>
  >
}

export type RegionsQueryVariables = Types.Exact<{[key: string]: never}>

export type RegionsQuery = {__typename?: 'Query'} & {
  regions?: Types.Maybe<Array<{__typename?: 'Region'} & RegionsFieldsFragment>>
}

export type RegionsFieldsFragment = {__typename?: 'Region'} & Pick<Types.Region, 'id' | 'uid' | 'title'>

export type PublicationByDeprecatedIdQueryVariables = Types.Exact<{
  deprecatedId: Types.Scalars['Int']
}>

export type PublicationByDeprecatedIdQuery = {__typename?: 'Query'} & {
  publicationByDeprecatedId?: Types.Maybe<
    {__typename?: 'Publication'} & Pick<Types.Publication, 'id' | 'uid' | 'deprecatedId' | 'title'>
  >
}

export type CreateRelatedLinkMutationVariables = Types.Exact<{
  data: Types.CreateRelatedLinkInput
}>

export type CreateRelatedLinkMutation = {__typename?: 'Mutation'} & {
  createRelatedLink?: Types.Maybe<
    {__typename?: 'CreateRelatedLinkPayload'} & Pick<Types.CreateRelatedLinkPayload, 'status'> & {
        link?: Types.Maybe<{__typename?: 'RelatedLink'} & Pick<Types.RelatedLink, 'id' | 'url' | 'text'>>
      }
  >
}

export type DeleteRelatedLinkMutationVariables = Types.Exact<{
  data: Types.DeleteRelatedLinkInput
}>

export type DeleteRelatedLinkMutation = {__typename?: 'Mutation'} & {
  deleteRelatedLink?: Types.Maybe<
    {__typename?: 'DeleteRelatedLinkPayload'} & Pick<Types.DeleteRelatedLinkPayload, 'status' | 'linkId'>
  >
}

export type SubscriptionBlocksQueryVariables = Types.Exact<{[key: string]: never}>

export type SubscriptionBlocksQuery = {__typename?: 'Query'} & {
  subscriptionBlocks?: Types.Maybe<
    Array<{__typename?: 'SubscriptionBlock'} & Pick<Types.SubscriptionBlock, 'id' | 'uid' | 'title'>>
  >
}

export type RandomSeoTitleExampleQueryVariables = Types.Exact<{[key: string]: never}>

export type RandomSeoTitleExampleQuery = {__typename?: 'Query'} & {
  randomTitleExamle?: Types.Maybe<{__typename?: 'TitleExample'} & Pick<Types.TitleExample, 'seoTitle' | 'title' | 'id'>>
}

export type BadwordsListQueryVariables = Types.Exact<{[key: string]: never}>

export type BadwordsListQuery = {__typename?: 'Query'} & {
  badwords?: Types.Maybe<Array<{__typename?: 'BadWords'} & Pick<Types.BadWords, 'id' | 'verbal_expression'>>>
}

export type OfficialWordsListQueryVariables = Types.Exact<{[key: string]: never}>

export type OfficialWordsListQuery = {__typename?: 'Query'} & {
  officialWords?: Types.Maybe<Array<{__typename?: 'OfficialWord'} & Pick<Types.OfficialWord, 'id' | 'officialWord'>>>
}

export type UsersEdgeSelectFragment = {__typename?: 'UsersEdge'} & {
  node?: Types.Maybe<{__typename?: 'User'} & Pick<Types.User, 'uid' | 'firstname' | 'lastname'>>
}

export type FigureVersionFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & FigureFragment
  }

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export type PublicationEditorFieldsFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'id'
  | 'uid'
  | 'badwords'
  | 'title'
  | 'yandexTitle'
  | 'yandexDzenTitle'
  | 'seoTitle'
  | 'subtitle'
  | 'status'
  | 'isCommentsEnabled'
  | 'isAutomaticCommentsDisabled'
  | 'isImportant'
  | 'sendToRambler'
  | 'ogTitle'
  | 'ogDescription'
  | 'createdAt'
  | 'typeSlug'
  | 'isInside'
  | 'specProjectUrl'
  | 'publishedAt'
  | 'deprecatedId'
  | 'url'
  | 'oldAdminUrl'
  | 'keywords'
  | 'overviewTitle'
  | 'overviewDescription'
  | 'overviewFigureId'
  | 'overviewFigureVersionId'
> & {
    story?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid' | 'title'>>
    regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'id' | 'uid' | 'title'>>>>
    tags?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title'>>>>
    collections?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Collection'} & Pick<Types.Collection, 'id' | 'uid' | 'title'>>>
    >
    authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
    destinations?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'id' | 'slug' | 'title'>>>
    >
    yandexZenCategories: Array<
      {__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'id' | 'uid' | 'title'>
    >
    relatedLinks: Array<{__typename?: 'RelatedLink'} & Pick<Types.RelatedLink, 'id' | 'url' | 'text'>>
    subscriptionBlock?: Types.Maybe<{__typename?: 'SubscriptionBlock'} & Pick<Types.SubscriptionBlock, 'uid' | 'title'>>
    yandexNewsVideos: Array<
      {__typename?: 'PublicationYandexNewsVideo'} & Pick<
        Types.PublicationYandexNewsVideo,
        'id' | 'uri' | 'title' | 'uid'
      > & {thumbnail: {__typename?: 'Figure'} & FigureFragment}
    >
    rubric?: Types.Maybe<{__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title' | 'availableForRambler'>>
    subrubric?: Types.Maybe<{__typename?: 'Subrubric'} & Pick<Types.Subrubric, 'id' | 'title' | 'availableForRambler'>>
  }

export type PublicationEditorFieldsAnnounceImageOnlyFragment = {__typename?: 'Publication'} & {
  announceImage: {__typename?: 'AnnounceImage'} & Pick<
    Types.AnnounceImage,
    'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
  > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
}

export type PublicationEditorFieldsSocialImageOnlyFragment = {__typename?: 'Publication'} & {
  socialMediaImage: {__typename?: 'SocialMediaImage'} & {
    socialMediaFigureVersion?: Types.Maybe<
      {__typename?: 'SocialMediaFigureVersion'} & Pick<Types.SocialMediaFigureVersion, 'alignY'>
    >
  }
}

export type PublicationEditorFieldsWithAnnounceImageFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'badwords'
  | 'canonicalUrl'
  | 'createdAt'
  | 'deprecatedId'
  | 'id'
  | 'isAutomaticCommentsDisabled'
  | 'isCommentsEnabled'
  | 'isImportant'
  | 'isInside'
  | 'keywords'
  | 'ogTitle'
  | 'ogDescription'
  | 'oldAdminUrl'
  | 'overviewDescription'
  | 'overviewFigureId'
  | 'overviewFigureVersionId'
  | 'overviewTitle'
  | 'publishedAt'
  | 'redirectUrl'
  | 'sendToRambler'
  | 'seoTitle'
  | 'specProjectUrl'
  | 'status'
  | 'subtitle'
  | 'title'
  | 'typeSlug'
  | 'uid'
  | 'yandexTitle'
  | 'yandexDzenTitle'
> & {
    announceImage: {__typename?: 'AnnounceImage'} & Pick<
      Types.AnnounceImage,
      'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
    > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
    authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
    collections?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Collection'} & Pick<Types.Collection, 'id' | 'uid' | 'title'>>>
    >
    destinations?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'id' | 'slug' | 'title'>>>
    >
    regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'id' | 'uid' | 'title'>>>>
    relatedLinks: Array<{__typename?: 'RelatedLink'} & Pick<Types.RelatedLink, 'id' | 'url' | 'text'>>
    socialMediaImage: {__typename?: 'SocialMediaImage'} & {
      socialMediaFigureVersion?: Types.Maybe<
        {__typename?: 'SocialMediaFigureVersion'} & Pick<Types.SocialMediaFigureVersion, 'alignY'>
      >
    }
    story?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid' | 'title'>>
    subscriptionBlock?: Types.Maybe<{__typename?: 'SubscriptionBlock'} & Pick<Types.SubscriptionBlock, 'uid' | 'title'>>
    tags?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title'>>>>
    yandexNewsVideos: Array<
      {__typename?: 'PublicationYandexNewsVideo'} & Pick<
        Types.PublicationYandexNewsVideo,
        'id' | 'uri' | 'title' | 'uid'
      > & {thumbnail: {__typename?: 'Figure'} & FigureFragment}
    >
    yandexZenCategories: Array<
      {__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'id' | 'uid' | 'title'>
    >
  }

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const RegionsFieldsFragmentDoc = gql`
  fragment RegionsFields on Region {
    id
    uid
    title
  }
`
export const UsersEdgeSelectFragmentDoc = gql`
  fragment UsersEdgeSelect on UsersEdge {
    node {
      uid
      firstname
      lastname
    }
  }
`
export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const FigureVersionFragmentDoc = gql`
  fragment FigureVersion on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      ...Figure
    }
  }
  ${FigureFragmentDoc}
`
export const PublicationEditorFieldsFragmentDoc = gql`
  fragment PublicationEditorFields on Publication {
    id
    uid
    badwords
    title
    yandexTitle
    yandexDzenTitle
    seoTitle
    subtitle
    status
    isCommentsEnabled
    isAutomaticCommentsDisabled
    isImportant
    sendToRambler
    ogTitle
    ogDescription
    createdAt
    typeSlug
    isInside
    specProjectUrl
    publishedAt
    deprecatedId
    url
    oldAdminUrl
    keywords
    story {
      id
      uid
      title
    }
    regions {
      id
      uid
      title
    }
    overviewTitle
    overviewDescription
    overviewFigureId
    overviewFigureVersionId
    tags {
      id
      uid
      title
    }
    collections {
      id
      uid
      title
    }
    authors {
      uid
      firstName
      lastName
    }
    destinations {
      id
      slug
      title
    }
    yandexZenCategories {
      id
      uid
      title
    }
    relatedLinks {
      id
      url
      text
    }
    subscriptionBlock {
      uid
      title
    }
    yandexNewsVideos {
      id
      uri
      title
      uid
      thumbnail {
        ...Figure
      }
    }
    rubric {
      id
      title
      availableForRambler
    }
    subrubric {
      id
      title
      availableForRambler
    }
  }
  ${FigureFragmentDoc}
`
export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const PublicationEditorFieldsAnnounceImageOnlyFragmentDoc = gql`
  fragment PublicationEditorFieldsAnnounceImageOnly on Publication {
    announceImage {
      id
      isFullWidth
      description
      author
      alt
      figureVersion {
        ...FigureVersionFragment
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`
export const PublicationEditorFieldsSocialImageOnlyFragmentDoc = gql`
  fragment PublicationEditorFieldsSocialImageOnly on Publication {
    socialMediaImage {
      socialMediaFigureVersion {
        alignY
      }
    }
  }
`
export const PublicationEditorFieldsWithAnnounceImageFragmentDoc = gql`
  fragment PublicationEditorFieldsWithAnnounceImage on Publication {
    announceImage {
      id
      isFullWidth
      description
      author
      alt
      figureVersion {
        ...FigureVersionFragment
      }
    }
    authors {
      uid
      firstName
      lastName
    }
    badwords
    canonicalUrl
    collections {
      id
      uid
      title
    }
    createdAt
    deprecatedId
    destinations {
      id
      slug
      title
    }
    id
    isAutomaticCommentsDisabled
    isCommentsEnabled
    isImportant
    isInside
    keywords
    ogTitle
    ogDescription
    oldAdminUrl
    overviewDescription
    overviewFigureId
    overviewFigureVersionId
    overviewTitle
    publishedAt
    redirectUrl
    regions {
      id
      uid
      title
    }
    relatedLinks {
      id
      url
      text
    }
    sendToRambler
    seoTitle
    socialMediaImage {
      socialMediaFigureVersion {
        alignY
      }
    }
    specProjectUrl
    status
    story {
      id
      uid
      title
    }
    subscriptionBlock {
      uid
      title
    }
    subtitle
    tags {
      id
      uid
      title
    }
    title
    typeSlug
    uid
    yandexTitle
    yandexDzenTitle
    yandexNewsVideos {
      id
      uri
      title
      uid
      thumbnail {
        ...Figure
      }
    }
    yandexZenCategories {
      id
      uid
      title
    }
  }
  ${FigureVersionFragmentFragmentDoc}
  ${FigureFragmentDoc}
`
export const UpdatePublicationDocument = gql`
  mutation updatePublication($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        ...PublicationEditorFields
      }
    }
  }
  ${PublicationEditorFieldsFragmentDoc}
`
export type UpdatePublicationMutationFn = Apollo.MutationFunction<
  UpdatePublicationMutation,
  UpdatePublicationMutationVariables
>

/**
 * __useUpdatePublicationMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationMutation, { data, loading, error }] = useUpdatePublicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePublicationMutation, UpdatePublicationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationMutation, UpdatePublicationMutationVariables>(
    UpdatePublicationDocument,
    options
  )
}
export type UpdatePublicationMutationHookResult = ReturnType<typeof useUpdatePublicationMutation>
export type UpdatePublicationMutationResult = Apollo.MutationResult<UpdatePublicationMutation>
export type UpdatePublicationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationMutation,
  UpdatePublicationMutationVariables
>
export const UpdatePublicationMainFieldsDocument = gql`
  mutation updatePublicationMainFields($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        title
        yandexTitle
        yandexDzenTitle
        subtitle
        deprecatedId
        seoTitle
        overviewTitle
        overviewDescription
        isCommentsEnabled
        isAutomaticCommentsDisabled
        isImportant
        sendToRambler
        specProjectUrl
        isInside
        tags {
          uid
          title
        }
        collections {
          uid
          title
        }
        story {
          uid
          title
        }
        subscriptionBlock {
          uid
          title
        }
        authors {
          uid
          firstName
          lastName
        }
        keywords
      }
    }
  }
`
export type UpdatePublicationMainFieldsMutationFn = Apollo.MutationFunction<
  UpdatePublicationMainFieldsMutation,
  UpdatePublicationMainFieldsMutationVariables
>

/**
 * __useUpdatePublicationMainFieldsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationMainFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationMainFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationMainFieldsMutation, { data, loading, error }] = useUpdatePublicationMainFieldsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationMainFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationMainFieldsMutation,
    UpdatePublicationMainFieldsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationMainFieldsMutation, UpdatePublicationMainFieldsMutationVariables>(
    UpdatePublicationMainFieldsDocument,
    options
  )
}
export type UpdatePublicationMainFieldsMutationHookResult = ReturnType<typeof useUpdatePublicationMainFieldsMutation>
export type UpdatePublicationMainFieldsMutationResult = Apollo.MutationResult<UpdatePublicationMainFieldsMutation>
export type UpdatePublicationMainFieldsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationMainFieldsMutation,
  UpdatePublicationMainFieldsMutationVariables
>
export const UpdatePublicationRegionsDocument = gql`
  mutation updatePublicationRegions($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        regions {
          uid
          title
        }
      }
    }
  }
`
export type UpdatePublicationRegionsMutationFn = Apollo.MutationFunction<
  UpdatePublicationRegionsMutation,
  UpdatePublicationRegionsMutationVariables
>

/**
 * __useUpdatePublicationRegionsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationRegionsMutation, { data, loading, error }] = useUpdatePublicationRegionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationRegionsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePublicationRegionsMutation, UpdatePublicationRegionsMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationRegionsMutation, UpdatePublicationRegionsMutationVariables>(
    UpdatePublicationRegionsDocument,
    options
  )
}
export type UpdatePublicationRegionsMutationHookResult = ReturnType<typeof useUpdatePublicationRegionsMutation>
export type UpdatePublicationRegionsMutationResult = Apollo.MutationResult<UpdatePublicationRegionsMutation>
export type UpdatePublicationRegionsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationRegionsMutation,
  UpdatePublicationRegionsMutationVariables
>
export const UpdatePublicationAnnounceImageOnlyDocument = gql`
  mutation updatePublicationAnnounceImageOnly($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        ...PublicationEditorFieldsAnnounceImageOnly
      }
    }
  }
  ${PublicationEditorFieldsAnnounceImageOnlyFragmentDoc}
`
export type UpdatePublicationAnnounceImageOnlyMutationFn = Apollo.MutationFunction<
  UpdatePublicationAnnounceImageOnlyMutation,
  UpdatePublicationAnnounceImageOnlyMutationVariables
>

/**
 * __useUpdatePublicationAnnounceImageOnlyMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationAnnounceImageOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationAnnounceImageOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationAnnounceImageOnlyMutation, { data, loading, error }] = useUpdatePublicationAnnounceImageOnlyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationAnnounceImageOnlyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationAnnounceImageOnlyMutation,
    UpdatePublicationAnnounceImageOnlyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdatePublicationAnnounceImageOnlyMutation,
    UpdatePublicationAnnounceImageOnlyMutationVariables
  >(UpdatePublicationAnnounceImageOnlyDocument, options)
}
export type UpdatePublicationAnnounceImageOnlyMutationHookResult = ReturnType<
  typeof useUpdatePublicationAnnounceImageOnlyMutation
>
export type UpdatePublicationAnnounceImageOnlyMutationResult =
  Apollo.MutationResult<UpdatePublicationAnnounceImageOnlyMutation>
export type UpdatePublicationAnnounceImageOnlyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationAnnounceImageOnlyMutation,
  UpdatePublicationAnnounceImageOnlyMutationVariables
>
export const UpdatePublicationSocialImageOnlyDocument = gql`
  mutation updatePublicationSocialImageOnly($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        ...PublicationEditorFieldsSocialImageOnly
      }
    }
  }
  ${PublicationEditorFieldsSocialImageOnlyFragmentDoc}
`
export type UpdatePublicationSocialImageOnlyMutationFn = Apollo.MutationFunction<
  UpdatePublicationSocialImageOnlyMutation,
  UpdatePublicationSocialImageOnlyMutationVariables
>

/**
 * __useUpdatePublicationSocialImageOnlyMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationSocialImageOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationSocialImageOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationSocialImageOnlyMutation, { data, loading, error }] = useUpdatePublicationSocialImageOnlyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationSocialImageOnlyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationSocialImageOnlyMutation,
    UpdatePublicationSocialImageOnlyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdatePublicationSocialImageOnlyMutation,
    UpdatePublicationSocialImageOnlyMutationVariables
  >(UpdatePublicationSocialImageOnlyDocument, options)
}
export type UpdatePublicationSocialImageOnlyMutationHookResult = ReturnType<
  typeof useUpdatePublicationSocialImageOnlyMutation
>
export type UpdatePublicationSocialImageOnlyMutationResult =
  Apollo.MutationResult<UpdatePublicationSocialImageOnlyMutation>
export type UpdatePublicationSocialImageOnlyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationSocialImageOnlyMutation,
  UpdatePublicationSocialImageOnlyMutationVariables
>
export const UpdatePublicationAnnounceFieldsDocument = gql`
  mutation updatePublicationAnnounceFields($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        ogTitle
        ogDescription
      }
    }
  }
`
export type UpdatePublicationAnnounceFieldsMutationFn = Apollo.MutationFunction<
  UpdatePublicationAnnounceFieldsMutation,
  UpdatePublicationAnnounceFieldsMutationVariables
>

/**
 * __useUpdatePublicationAnnounceFieldsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationAnnounceFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationAnnounceFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationAnnounceFieldsMutation, { data, loading, error }] = useUpdatePublicationAnnounceFieldsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationAnnounceFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationAnnounceFieldsMutation,
    UpdatePublicationAnnounceFieldsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationAnnounceFieldsMutation, UpdatePublicationAnnounceFieldsMutationVariables>(
    UpdatePublicationAnnounceFieldsDocument,
    options
  )
}
export type UpdatePublicationAnnounceFieldsMutationHookResult = ReturnType<
  typeof useUpdatePublicationAnnounceFieldsMutation
>
export type UpdatePublicationAnnounceFieldsMutationResult =
  Apollo.MutationResult<UpdatePublicationAnnounceFieldsMutation>
export type UpdatePublicationAnnounceFieldsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationAnnounceFieldsMutation,
  UpdatePublicationAnnounceFieldsMutationVariables
>
export const UpdateAnnounceImagePublicationDocument = gql`
  mutation updateAnnounceImagePublication($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        announceImage {
          id
          isFullWidth
          description
          author
          alt
          figureVersion {
            ...FigureVersionFragment
          }
        }
        socialMediaImage {
          socialMediaFigureVersion {
            id
            uid
            alignY
            title
          }
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`
export type UpdateAnnounceImagePublicationMutationFn = Apollo.MutationFunction<
  UpdateAnnounceImagePublicationMutation,
  UpdateAnnounceImagePublicationMutationVariables
>

/**
 * __useUpdateAnnounceImagePublicationMutation__
 *
 * To run a mutation, you first call `useUpdateAnnounceImagePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnounceImagePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnounceImagePublicationMutation, { data, loading, error }] = useUpdateAnnounceImagePublicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAnnounceImagePublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnounceImagePublicationMutation,
    UpdateAnnounceImagePublicationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateAnnounceImagePublicationMutation, UpdateAnnounceImagePublicationMutationVariables>(
    UpdateAnnounceImagePublicationDocument,
    options
  )
}
export type UpdateAnnounceImagePublicationMutationHookResult = ReturnType<
  typeof useUpdateAnnounceImagePublicationMutation
>
export type UpdateAnnounceImagePublicationMutationResult = Apollo.MutationResult<UpdateAnnounceImagePublicationMutation>
export type UpdateAnnounceImagePublicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnounceImagePublicationMutation,
  UpdateAnnounceImagePublicationMutationVariables
>
export const UpdatePublicationExtAnnounceParamsDocument = gql`
  mutation updatePublicationExtAnnounceParams($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        ogTitle
        ogDescription
      }
    }
  }
`
export type UpdatePublicationExtAnnounceParamsMutationFn = Apollo.MutationFunction<
  UpdatePublicationExtAnnounceParamsMutation,
  UpdatePublicationExtAnnounceParamsMutationVariables
>

/**
 * __useUpdatePublicationExtAnnounceParamsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationExtAnnounceParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationExtAnnounceParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationExtAnnounceParamsMutation, { data, loading, error }] = useUpdatePublicationExtAnnounceParamsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationExtAnnounceParamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationExtAnnounceParamsMutation,
    UpdatePublicationExtAnnounceParamsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdatePublicationExtAnnounceParamsMutation,
    UpdatePublicationExtAnnounceParamsMutationVariables
  >(UpdatePublicationExtAnnounceParamsDocument, options)
}
export type UpdatePublicationExtAnnounceParamsMutationHookResult = ReturnType<
  typeof useUpdatePublicationExtAnnounceParamsMutation
>
export type UpdatePublicationExtAnnounceParamsMutationResult =
  Apollo.MutationResult<UpdatePublicationExtAnnounceParamsMutation>
export type UpdatePublicationExtAnnounceParamsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationExtAnnounceParamsMutation,
  UpdatePublicationExtAnnounceParamsMutationVariables
>
export const UpdatePublicationRubricAndSubrubricDocument = gql`
  mutation updatePublicationRubricAndSubrubric($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        rubric {
          id
          title
          availableForRambler
        }
        subrubric {
          id
          title
          availableForRambler
        }
      }
    }
  }
`
export type UpdatePublicationRubricAndSubrubricMutationFn = Apollo.MutationFunction<
  UpdatePublicationRubricAndSubrubricMutation,
  UpdatePublicationRubricAndSubrubricMutationVariables
>

/**
 * __useUpdatePublicationRubricAndSubrubricMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationRubricAndSubrubricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationRubricAndSubrubricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationRubricAndSubrubricMutation, { data, loading, error }] = useUpdatePublicationRubricAndSubrubricMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationRubricAndSubrubricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationRubricAndSubrubricMutation,
    UpdatePublicationRubricAndSubrubricMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdatePublicationRubricAndSubrubricMutation,
    UpdatePublicationRubricAndSubrubricMutationVariables
  >(UpdatePublicationRubricAndSubrubricDocument, options)
}
export type UpdatePublicationRubricAndSubrubricMutationHookResult = ReturnType<
  typeof useUpdatePublicationRubricAndSubrubricMutation
>
export type UpdatePublicationRubricAndSubrubricMutationResult =
  Apollo.MutationResult<UpdatePublicationRubricAndSubrubricMutation>
export type UpdatePublicationRubricAndSubrubricMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationRubricAndSubrubricMutation,
  UpdatePublicationRubricAndSubrubricMutationVariables
>
export const UpdatePublicationOverviewFigureDocument = gql`
  mutation updatePublicationOverviewFigure($data: UpdatePublicationInput!) {
    updatePublication(data: $data) {
      publication {
        overviewFigure {
          ...FigureVersionFragment
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`
export type UpdatePublicationOverviewFigureMutationFn = Apollo.MutationFunction<
  UpdatePublicationOverviewFigureMutation,
  UpdatePublicationOverviewFigureMutationVariables
>

/**
 * __useUpdatePublicationOverviewFigureMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationOverviewFigureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationOverviewFigureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationOverviewFigureMutation, { data, loading, error }] = useUpdatePublicationOverviewFigureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationOverviewFigureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicationOverviewFigureMutation,
    UpdatePublicationOverviewFigureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationOverviewFigureMutation, UpdatePublicationOverviewFigureMutationVariables>(
    UpdatePublicationOverviewFigureDocument,
    options
  )
}
export type UpdatePublicationOverviewFigureMutationHookResult = ReturnType<
  typeof useUpdatePublicationOverviewFigureMutation
>
export type UpdatePublicationOverviewFigureMutationResult =
  Apollo.MutationResult<UpdatePublicationOverviewFigureMutation>
export type UpdatePublicationOverviewFigureMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationOverviewFigureMutation,
  UpdatePublicationOverviewFigureMutationVariables
>
export const ConvertPublicationToArticleDocument = gql`
  mutation convertPublicationToArticle($data: ConvertPublicationToArticleInput!) {
    convertPublicationToArticle(data: $data) {
      publication {
        ...PublicationEditorFieldsWithAnnounceImage
      }
    }
  }
  ${PublicationEditorFieldsWithAnnounceImageFragmentDoc}
`
export type ConvertPublicationToArticleMutationFn = Apollo.MutationFunction<
  ConvertPublicationToArticleMutation,
  ConvertPublicationToArticleMutationVariables
>

/**
 * __useConvertPublicationToArticleMutation__
 *
 * To run a mutation, you first call `useConvertPublicationToArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertPublicationToArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertPublicationToArticleMutation, { data, loading, error }] = useConvertPublicationToArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConvertPublicationToArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertPublicationToArticleMutation,
    ConvertPublicationToArticleMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ConvertPublicationToArticleMutation, ConvertPublicationToArticleMutationVariables>(
    ConvertPublicationToArticleDocument,
    options
  )
}
export type ConvertPublicationToArticleMutationHookResult = ReturnType<typeof useConvertPublicationToArticleMutation>
export type ConvertPublicationToArticleMutationResult = Apollo.MutationResult<ConvertPublicationToArticleMutation>
export type ConvertPublicationToArticleMutationOptions = Apollo.BaseMutationOptions<
  ConvertPublicationToArticleMutation,
  ConvertPublicationToArticleMutationVariables
>
export const SendPublicationForReviewDocument = gql`
  mutation sendPublicationForReview($data: SendPublicationForReviewInput!) {
    sendPublicationForReview(data: $data) {
      publication {
        ...PublicationEditorFieldsWithAnnounceImage
      }
    }
  }
  ${PublicationEditorFieldsWithAnnounceImageFragmentDoc}
`
export type SendPublicationForReviewMutationFn = Apollo.MutationFunction<
  SendPublicationForReviewMutation,
  SendPublicationForReviewMutationVariables
>

/**
 * __useSendPublicationForReviewMutation__
 *
 * To run a mutation, you first call `useSendPublicationForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPublicationForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPublicationForReviewMutation, { data, loading, error }] = useSendPublicationForReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendPublicationForReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<SendPublicationForReviewMutation, SendPublicationForReviewMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SendPublicationForReviewMutation, SendPublicationForReviewMutationVariables>(
    SendPublicationForReviewDocument,
    options
  )
}
export type SendPublicationForReviewMutationHookResult = ReturnType<typeof useSendPublicationForReviewMutation>
export type SendPublicationForReviewMutationResult = Apollo.MutationResult<SendPublicationForReviewMutation>
export type SendPublicationForReviewMutationOptions = Apollo.BaseMutationOptions<
  SendPublicationForReviewMutation,
  SendPublicationForReviewMutationVariables
>
export const CreateFastNewsDocument = gql`
  mutation createFastNews($data: FastNewsInput!) {
    createFastNews(data: $data) {
      ...PublicationEditorFieldsWithAnnounceImage
    }
  }
  ${PublicationEditorFieldsWithAnnounceImageFragmentDoc}
`
export type CreateFastNewsMutationFn = Apollo.MutationFunction<CreateFastNewsMutation, CreateFastNewsMutationVariables>

/**
 * __useCreateFastNewsMutation__
 *
 * To run a mutation, you first call `useCreateFastNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFastNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFastNewsMutation, { data, loading, error }] = useCreateFastNewsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFastNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFastNewsMutation, CreateFastNewsMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateFastNewsMutation, CreateFastNewsMutationVariables>(CreateFastNewsDocument, options)
}
export type CreateFastNewsMutationHookResult = ReturnType<typeof useCreateFastNewsMutation>
export type CreateFastNewsMutationResult = Apollo.MutationResult<CreateFastNewsMutation>
export type CreateFastNewsMutationOptions = Apollo.BaseMutationOptions<
  CreateFastNewsMutation,
  CreateFastNewsMutationVariables
>
export const PublishPublicationDocument = gql`
  mutation publishPublication($data: PublishPublicationInput!) {
    publishPublication(data: $data) {
      publication {
        ...PublicationEditorFieldsWithAnnounceImage
      }
    }
  }
  ${PublicationEditorFieldsWithAnnounceImageFragmentDoc}
`
export type PublishPublicationMutationFn = Apollo.MutationFunction<
  PublishPublicationMutation,
  PublishPublicationMutationVariables
>

/**
 * __usePublishPublicationMutation__
 *
 * To run a mutation, you first call `usePublishPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPublicationMutation, { data, loading, error }] = usePublishPublicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePublishPublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishPublicationMutation, PublishPublicationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<PublishPublicationMutation, PublishPublicationMutationVariables>(
    PublishPublicationDocument,
    options
  )
}
export type PublishPublicationMutationHookResult = ReturnType<typeof usePublishPublicationMutation>
export type PublishPublicationMutationResult = Apollo.MutationResult<PublishPublicationMutation>
export type PublishPublicationMutationOptions = Apollo.BaseMutationOptions<
  PublishPublicationMutation,
  PublishPublicationMutationVariables
>
export const UnpublishPublicationDocument = gql`
  mutation unpublishPublication($data: UnpublishPublicationInput!) {
    unpublishPublication(data: $data) {
      status
      publication {
        status
        redirectUrl
      }
    }
  }
`
export type UnpublishPublicationMutationFn = Apollo.MutationFunction<
  UnpublishPublicationMutation,
  UnpublishPublicationMutationVariables
>

/**
 * __useUnpublishPublicationMutation__
 *
 * To run a mutation, you first call `useUnpublishPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPublicationMutation, { data, loading, error }] = useUnpublishPublicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnpublishPublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UnpublishPublicationMutation, UnpublishPublicationMutationVariables>(
    UnpublishPublicationDocument,
    options
  )
}
export type UnpublishPublicationMutationHookResult = ReturnType<typeof useUnpublishPublicationMutation>
export type UnpublishPublicationMutationResult = Apollo.MutationResult<UnpublishPublicationMutation>
export type UnpublishPublicationMutationOptions = Apollo.BaseMutationOptions<
  UnpublishPublicationMutation,
  UnpublishPublicationMutationVariables
>
export const CreateFigureDocument = gql`
  mutation createFigure($data: CreateFigureInput!) {
    createFigure(data: $data) {
      figure {
        sourceImage {
          url
        }
        ...Figure
      }
    }
  }
  ${FigureFragmentDoc}
`
export type CreateFigureMutationFn = Apollo.MutationFunction<CreateFigureMutation, CreateFigureMutationVariables>

/**
 * __useCreateFigureMutation__
 *
 * To run a mutation, you first call `useCreateFigureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFigureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFigureMutation, { data, loading, error }] = useCreateFigureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFigureMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFigureMutation, CreateFigureMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateFigureMutation, CreateFigureMutationVariables>(CreateFigureDocument, options)
}
export type CreateFigureMutationHookResult = ReturnType<typeof useCreateFigureMutation>
export type CreateFigureMutationResult = Apollo.MutationResult<CreateFigureMutation>
export type CreateFigureMutationOptions = Apollo.BaseMutationOptions<
  CreateFigureMutation,
  CreateFigureMutationVariables
>
export const CreateFigureVersionDocument = gql`
  mutation createFigureVersion($data: CreateFigureVersionInput!) {
    createFigureVersion(data: $data) {
      figureVersion {
        id
        uid
        hasWatermark
        hasDiagonalWatermark
        figure {
          sourceImage {
            url
          }
          ...Figure
        }
      }
    }
  }
  ${FigureFragmentDoc}
`
export type CreateFigureVersionMutationFn = Apollo.MutationFunction<
  CreateFigureVersionMutation,
  CreateFigureVersionMutationVariables
>

/**
 * __useCreateFigureVersionMutation__
 *
 * To run a mutation, you first call `useCreateFigureVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFigureVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFigureVersionMutation, { data, loading, error }] = useCreateFigureVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFigureVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFigureVersionMutation, CreateFigureVersionMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateFigureVersionMutation, CreateFigureVersionMutationVariables>(
    CreateFigureVersionDocument,
    options
  )
}
export type CreateFigureVersionMutationHookResult = ReturnType<typeof useCreateFigureVersionMutation>
export type CreateFigureVersionMutationResult = Apollo.MutationResult<CreateFigureVersionMutation>
export type CreateFigureVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateFigureVersionMutation,
  CreateFigureVersionMutationVariables
>
export const CreateSocialMediaFigureVersionDocument = gql`
  mutation createSocialMediaFigureVersion($data: CreateSocialMediaFigureVersionInput!) {
    createSocialMediaFigureVersion(data: $data) {
      status
      socialMediaFigureVersion {
        id
        title
        alignY
        announceFigureVersion {
          ...FigureVersion
        }
        processedImage {
          url
        }
      }
    }
  }
  ${FigureVersionFragmentDoc}
`
export type CreateSocialMediaFigureVersionMutationFn = Apollo.MutationFunction<
  CreateSocialMediaFigureVersionMutation,
  CreateSocialMediaFigureVersionMutationVariables
>

/**
 * __useCreateSocialMediaFigureVersionMutation__
 *
 * To run a mutation, you first call `useCreateSocialMediaFigureVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSocialMediaFigureVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSocialMediaFigureVersionMutation, { data, loading, error }] = useCreateSocialMediaFigureVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSocialMediaFigureVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSocialMediaFigureVersionMutation,
    CreateSocialMediaFigureVersionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateSocialMediaFigureVersionMutation, CreateSocialMediaFigureVersionMutationVariables>(
    CreateSocialMediaFigureVersionDocument,
    options
  )
}
export type CreateSocialMediaFigureVersionMutationHookResult = ReturnType<
  typeof useCreateSocialMediaFigureVersionMutation
>
export type CreateSocialMediaFigureVersionMutationResult = Apollo.MutationResult<CreateSocialMediaFigureVersionMutation>
export type CreateSocialMediaFigureVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateSocialMediaFigureVersionMutation,
  CreateSocialMediaFigureVersionMutationVariables
>
export const SocialMediaFigureVersionDocument = gql`
  query socialMediaFigureVersion($id: UUID!) {
    socialMediaFigureVersion(id: $id) {
      id
      title
      alignY
      announceFigureVersion {
        ...FigureVersion
      }
      processedImage {
        url
      }
    }
  }
  ${FigureVersionFragmentDoc}
`

/**
 * __useSocialMediaFigureVersionQuery__
 *
 * To run a query within a React component, call `useSocialMediaFigureVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaFigureVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialMediaFigureVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSocialMediaFigureVersionQuery(
  baseOptions: Apollo.QueryHookOptions<SocialMediaFigureVersionQuery, SocialMediaFigureVersionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<SocialMediaFigureVersionQuery, SocialMediaFigureVersionQueryVariables>(
    SocialMediaFigureVersionDocument,
    options
  )
}
export function useSocialMediaFigureVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SocialMediaFigureVersionQuery, SocialMediaFigureVersionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<SocialMediaFigureVersionQuery, SocialMediaFigureVersionQueryVariables>(
    SocialMediaFigureVersionDocument,
    options
  )
}
export type SocialMediaFigureVersionQueryHookResult = ReturnType<typeof useSocialMediaFigureVersionQuery>
export type SocialMediaFigureVersionLazyQueryHookResult = ReturnType<typeof useSocialMediaFigureVersionLazyQuery>
export type SocialMediaFigureVersionQueryResult = Apollo.QueryResult<
  SocialMediaFigureVersionQuery,
  SocialMediaFigureVersionQueryVariables
>
export const UpdatePublicationExportDocument = gql`
  mutation updatePublicationExport($data: UpdatePublicationExportInput!) {
    updatePublicationExport(data: $data) {
      status
      publication {
        yandexNewsVideos {
          id
          uri
          title
          uid
          thumbnail {
            ...Figure
          }
        }
        destinations {
          id
          slug
          title
        }
        yandexZenCategories {
          id
          uid
          title
        }
      }
    }
  }
  ${FigureFragmentDoc}
`
export type UpdatePublicationExportMutationFn = Apollo.MutationFunction<
  UpdatePublicationExportMutation,
  UpdatePublicationExportMutationVariables
>

/**
 * __useUpdatePublicationExportMutation__
 *
 * To run a mutation, you first call `useUpdatePublicationExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicationExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicationExportMutation, { data, loading, error }] = useUpdatePublicationExportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicationExportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePublicationExportMutation, UpdatePublicationExportMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdatePublicationExportMutation, UpdatePublicationExportMutationVariables>(
    UpdatePublicationExportDocument,
    options
  )
}
export type UpdatePublicationExportMutationHookResult = ReturnType<typeof useUpdatePublicationExportMutation>
export type UpdatePublicationExportMutationResult = Apollo.MutationResult<UpdatePublicationExportMutation>
export type UpdatePublicationExportMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicationExportMutation,
  UpdatePublicationExportMutationVariables
>
export const UpdateFigureDocument = gql`
  mutation updateFigure($data: UpdateFigureInput!) {
    updateFigure(data: $data) {
      figure {
        ...Figure
      }
    }
  }
  ${FigureFragmentDoc}
`
export type UpdateFigureMutationFn = Apollo.MutationFunction<UpdateFigureMutation, UpdateFigureMutationVariables>

/**
 * __useUpdateFigureMutation__
 *
 * To run a mutation, you first call `useUpdateFigureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFigureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFigureMutation, { data, loading, error }] = useUpdateFigureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFigureMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFigureMutation, UpdateFigureMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateFigureMutation, UpdateFigureMutationVariables>(UpdateFigureDocument, options)
}
export type UpdateFigureMutationHookResult = ReturnType<typeof useUpdateFigureMutation>
export type UpdateFigureMutationResult = Apollo.MutationResult<UpdateFigureMutation>
export type UpdateFigureMutationOptions = Apollo.BaseMutationOptions<
  UpdateFigureMutation,
  UpdateFigureMutationVariables
>
export const StoriesPublicationFormDocument = gql`
  query storiesPublicationForm($first: Int, $after: String, $search: String) {
    stories(filter: {isActive: true, search: $search}, sort: UPDATED_AT_DESC, limit: 15, first: $first, after: $after) {
      edges {
        node {
          id
          uid
          title
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/**
 * __useStoriesPublicationFormQuery__
 *
 * To run a query within a React component, call `useStoriesPublicationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesPublicationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesPublicationFormQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStoriesPublicationFormQuery(
  baseOptions?: Apollo.QueryHookOptions<StoriesPublicationFormQuery, StoriesPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StoriesPublicationFormQuery, StoriesPublicationFormQueryVariables>(
    StoriesPublicationFormDocument,
    options
  )
}
export function useStoriesPublicationFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoriesPublicationFormQuery, StoriesPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StoriesPublicationFormQuery, StoriesPublicationFormQueryVariables>(
    StoriesPublicationFormDocument,
    options
  )
}
export type StoriesPublicationFormQueryHookResult = ReturnType<typeof useStoriesPublicationFormQuery>
export type StoriesPublicationFormLazyQueryHookResult = ReturnType<typeof useStoriesPublicationFormLazyQuery>
export type StoriesPublicationFormQueryResult = Apollo.QueryResult<
  StoriesPublicationFormQuery,
  StoriesPublicationFormQueryVariables
>
export const TagsPublicationFormDocument = gql`
  query tagsPublicationForm($first: Int, $after: String, $search: String) {
    tags(limit: 15, filter: {isActive: true, search: $search}, sort: TITLE_ASC, first: $first, after: $after) {
      edges {
        node {
          id
          uid
          title
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

/**
 * __useTagsPublicationFormQuery__
 *
 * To run a query within a React component, call `useTagsPublicationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsPublicationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsPublicationFormQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsPublicationFormQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsPublicationFormQuery, TagsPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TagsPublicationFormQuery, TagsPublicationFormQueryVariables>(
    TagsPublicationFormDocument,
    options
  )
}
export function useTagsPublicationFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsPublicationFormQuery, TagsPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TagsPublicationFormQuery, TagsPublicationFormQueryVariables>(
    TagsPublicationFormDocument,
    options
  )
}
export type TagsPublicationFormQueryHookResult = ReturnType<typeof useTagsPublicationFormQuery>
export type TagsPublicationFormLazyQueryHookResult = ReturnType<typeof useTagsPublicationFormLazyQuery>
export type TagsPublicationFormQueryResult = Apollo.QueryResult<
  TagsPublicationFormQuery,
  TagsPublicationFormQueryVariables
>
export const CollectionsPublicationFormDocument = gql`
  query collectionsPublicationForm($first: Int, $after: String, $search: String) {
    collections(
      limit: 15
      filter: {isActive: true, search: $search}
      sort: UPDATED_AT_DESC
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          uid
          title
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

/**
 * __useCollectionsPublicationFormQuery__
 *
 * To run a query within a React component, call `useCollectionsPublicationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsPublicationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsPublicationFormQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCollectionsPublicationFormQuery(
  baseOptions?: Apollo.QueryHookOptions<CollectionsPublicationFormQuery, CollectionsPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<CollectionsPublicationFormQuery, CollectionsPublicationFormQueryVariables>(
    CollectionsPublicationFormDocument,
    options
  )
}
export function useCollectionsPublicationFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionsPublicationFormQuery, CollectionsPublicationFormQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<CollectionsPublicationFormQuery, CollectionsPublicationFormQueryVariables>(
    CollectionsPublicationFormDocument,
    options
  )
}
export type CollectionsPublicationFormQueryHookResult = ReturnType<typeof useCollectionsPublicationFormQuery>
export type CollectionsPublicationFormLazyQueryHookResult = ReturnType<typeof useCollectionsPublicationFormLazyQuery>
export type CollectionsPublicationFormQueryResult = Apollo.QueryResult<
  CollectionsPublicationFormQuery,
  CollectionsPublicationFormQueryVariables
>
export const AuthorsPublicationDocument = gql`
  query authorsPublication {
    authors {
      uid
      firstName
      lastName
    }
  }
`

/**
 * __useAuthorsPublicationQuery__
 *
 * To run a query within a React component, call `useAuthorsPublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorsPublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorsPublicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorsPublicationQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthorsPublicationQuery, AuthorsPublicationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<AuthorsPublicationQuery, AuthorsPublicationQueryVariables>(AuthorsPublicationDocument, options)
}
export function useAuthorsPublicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthorsPublicationQuery, AuthorsPublicationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<AuthorsPublicationQuery, AuthorsPublicationQueryVariables>(
    AuthorsPublicationDocument,
    options
  )
}
export type AuthorsPublicationQueryHookResult = ReturnType<typeof useAuthorsPublicationQuery>
export type AuthorsPublicationLazyQueryHookResult = ReturnType<typeof useAuthorsPublicationLazyQuery>
export type AuthorsPublicationQueryResult = Apollo.QueryResult<
  AuthorsPublicationQuery,
  AuthorsPublicationQueryVariables
>
export const YandexZenCategoriesDocument = gql`
  query yandexZenCategories {
    yandexZenCategories {
      uid
      title
    }
  }
`

/**
 * __useYandexZenCategoriesQuery__
 *
 * To run a query within a React component, call `useYandexZenCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useYandexZenCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYandexZenCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useYandexZenCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<YandexZenCategoriesQuery, YandexZenCategoriesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<YandexZenCategoriesQuery, YandexZenCategoriesQueryVariables>(
    YandexZenCategoriesDocument,
    options
  )
}
export function useYandexZenCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<YandexZenCategoriesQuery, YandexZenCategoriesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<YandexZenCategoriesQuery, YandexZenCategoriesQueryVariables>(
    YandexZenCategoriesDocument,
    options
  )
}
export type YandexZenCategoriesQueryHookResult = ReturnType<typeof useYandexZenCategoriesQuery>
export type YandexZenCategoriesLazyQueryHookResult = ReturnType<typeof useYandexZenCategoriesLazyQuery>
export type YandexZenCategoriesQueryResult = Apollo.QueryResult<
  YandexZenCategoriesQuery,
  YandexZenCategoriesQueryVariables
>
export const RegionsDocument = gql`
  query regions {
    regions {
      ...RegionsFields
    }
  }
  ${RegionsFieldsFragmentDoc}
`

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options)
}
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options)
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>
export const PublicationByDeprecatedIdDocument = gql`
  query publicationByDeprecatedId($deprecatedId: Int!) {
    publicationByDeprecatedId(deprecatedId: $deprecatedId) {
      id
      uid
      deprecatedId
      title
    }
  }
`

/**
 * __usePublicationByDeprecatedIdQuery__
 *
 * To run a query within a React component, call `usePublicationByDeprecatedIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationByDeprecatedIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationByDeprecatedIdQuery({
 *   variables: {
 *      deprecatedId: // value for 'deprecatedId'
 *   },
 * });
 */
export function usePublicationByDeprecatedIdQuery(
  baseOptions: Apollo.QueryHookOptions<PublicationByDeprecatedIdQuery, PublicationByDeprecatedIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationByDeprecatedIdQuery, PublicationByDeprecatedIdQueryVariables>(
    PublicationByDeprecatedIdDocument,
    options
  )
}
export function usePublicationByDeprecatedIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationByDeprecatedIdQuery, PublicationByDeprecatedIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationByDeprecatedIdQuery, PublicationByDeprecatedIdQueryVariables>(
    PublicationByDeprecatedIdDocument,
    options
  )
}
export type PublicationByDeprecatedIdQueryHookResult = ReturnType<typeof usePublicationByDeprecatedIdQuery>
export type PublicationByDeprecatedIdLazyQueryHookResult = ReturnType<typeof usePublicationByDeprecatedIdLazyQuery>
export type PublicationByDeprecatedIdQueryResult = Apollo.QueryResult<
  PublicationByDeprecatedIdQuery,
  PublicationByDeprecatedIdQueryVariables
>
export const CreateRelatedLinkDocument = gql`
  mutation createRelatedLink($data: CreateRelatedLinkInput!) {
    createRelatedLink(data: $data) {
      status
      link {
        id
        url
        text
      }
    }
  }
`
export type CreateRelatedLinkMutationFn = Apollo.MutationFunction<
  CreateRelatedLinkMutation,
  CreateRelatedLinkMutationVariables
>

/**
 * __useCreateRelatedLinkMutation__
 *
 * To run a mutation, you first call `useCreateRelatedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelatedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelatedLinkMutation, { data, loading, error }] = useCreateRelatedLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRelatedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRelatedLinkMutation, CreateRelatedLinkMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateRelatedLinkMutation, CreateRelatedLinkMutationVariables>(
    CreateRelatedLinkDocument,
    options
  )
}
export type CreateRelatedLinkMutationHookResult = ReturnType<typeof useCreateRelatedLinkMutation>
export type CreateRelatedLinkMutationResult = Apollo.MutationResult<CreateRelatedLinkMutation>
export type CreateRelatedLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateRelatedLinkMutation,
  CreateRelatedLinkMutationVariables
>
export const DeleteRelatedLinkDocument = gql`
  mutation deleteRelatedLink($data: DeleteRelatedLinkInput!) {
    deleteRelatedLink(data: $data) {
      status
      linkId
    }
  }
`
export type DeleteRelatedLinkMutationFn = Apollo.MutationFunction<
  DeleteRelatedLinkMutation,
  DeleteRelatedLinkMutationVariables
>

/**
 * __useDeleteRelatedLinkMutation__
 *
 * To run a mutation, you first call `useDeleteRelatedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRelatedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRelatedLinkMutation, { data, loading, error }] = useDeleteRelatedLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteRelatedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRelatedLinkMutation, DeleteRelatedLinkMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeleteRelatedLinkMutation, DeleteRelatedLinkMutationVariables>(
    DeleteRelatedLinkDocument,
    options
  )
}
export type DeleteRelatedLinkMutationHookResult = ReturnType<typeof useDeleteRelatedLinkMutation>
export type DeleteRelatedLinkMutationResult = Apollo.MutationResult<DeleteRelatedLinkMutation>
export type DeleteRelatedLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteRelatedLinkMutation,
  DeleteRelatedLinkMutationVariables
>
export const SubscriptionBlocksDocument = gql`
  query subscriptionBlocks {
    subscriptionBlocks {
      id
      uid
      title
    }
  }
`

/**
 * __useSubscriptionBlocksQuery__
 *
 * To run a query within a React component, call `useSubscriptionBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<SubscriptionBlocksQuery, SubscriptionBlocksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<SubscriptionBlocksQuery, SubscriptionBlocksQueryVariables>(SubscriptionBlocksDocument, options)
}
export function useSubscriptionBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionBlocksQuery, SubscriptionBlocksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<SubscriptionBlocksQuery, SubscriptionBlocksQueryVariables>(
    SubscriptionBlocksDocument,
    options
  )
}
export type SubscriptionBlocksQueryHookResult = ReturnType<typeof useSubscriptionBlocksQuery>
export type SubscriptionBlocksLazyQueryHookResult = ReturnType<typeof useSubscriptionBlocksLazyQuery>
export type SubscriptionBlocksQueryResult = Apollo.QueryResult<
  SubscriptionBlocksQuery,
  SubscriptionBlocksQueryVariables
>
export const RandomSeoTitleExampleDocument = gql`
  query randomSeoTitleExample {
    randomTitleExamle {
      seoTitle
      title
      id
    }
  }
`

/**
 * __useRandomSeoTitleExampleQuery__
 *
 * To run a query within a React component, call `useRandomSeoTitleExampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomSeoTitleExampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomSeoTitleExampleQuery({
 *   variables: {
 *   },
 * });
 */
export function useRandomSeoTitleExampleQuery(
  baseOptions?: Apollo.QueryHookOptions<RandomSeoTitleExampleQuery, RandomSeoTitleExampleQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<RandomSeoTitleExampleQuery, RandomSeoTitleExampleQueryVariables>(
    RandomSeoTitleExampleDocument,
    options
  )
}
export function useRandomSeoTitleExampleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RandomSeoTitleExampleQuery, RandomSeoTitleExampleQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<RandomSeoTitleExampleQuery, RandomSeoTitleExampleQueryVariables>(
    RandomSeoTitleExampleDocument,
    options
  )
}
export type RandomSeoTitleExampleQueryHookResult = ReturnType<typeof useRandomSeoTitleExampleQuery>
export type RandomSeoTitleExampleLazyQueryHookResult = ReturnType<typeof useRandomSeoTitleExampleLazyQuery>
export type RandomSeoTitleExampleQueryResult = Apollo.QueryResult<
  RandomSeoTitleExampleQuery,
  RandomSeoTitleExampleQueryVariables
>
export const BadwordsListDocument = gql`
  query badwordsList {
    badwords {
      id
      verbal_expression
    }
  }
`

/**
 * __useBadwordsListQuery__
 *
 * To run a query within a React component, call `useBadwordsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadwordsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadwordsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBadwordsListQuery(
  baseOptions?: Apollo.QueryHookOptions<BadwordsListQuery, BadwordsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<BadwordsListQuery, BadwordsListQueryVariables>(BadwordsListDocument, options)
}
export function useBadwordsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BadwordsListQuery, BadwordsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<BadwordsListQuery, BadwordsListQueryVariables>(BadwordsListDocument, options)
}
export type BadwordsListQueryHookResult = ReturnType<typeof useBadwordsListQuery>
export type BadwordsListLazyQueryHookResult = ReturnType<typeof useBadwordsListLazyQuery>
export type BadwordsListQueryResult = Apollo.QueryResult<BadwordsListQuery, BadwordsListQueryVariables>
export const OfficialWordsListDocument = gql`
  query officialWordsList {
    officialWords {
      id
      officialWord
    }
  }
`

/**
 * __useOfficialWordsListQuery__
 *
 * To run a query within a React component, call `useOfficialWordsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialWordsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialWordsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfficialWordsListQuery(
  baseOptions?: Apollo.QueryHookOptions<OfficialWordsListQuery, OfficialWordsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<OfficialWordsListQuery, OfficialWordsListQueryVariables>(OfficialWordsListDocument, options)
}
export function useOfficialWordsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfficialWordsListQuery, OfficialWordsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<OfficialWordsListQuery, OfficialWordsListQueryVariables>(
    OfficialWordsListDocument,
    options
  )
}
export type OfficialWordsListQueryHookResult = ReturnType<typeof useOfficialWordsListQuery>
export type OfficialWordsListLazyQueryHookResult = ReturnType<typeof useOfficialWordsListLazyQuery>
export type OfficialWordsListQueryResult = Apollo.QueryResult<OfficialWordsListQuery, OfficialWordsListQueryVariables>
