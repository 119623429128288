import DialogStore from '@stores/nnStore/nnStore'
import {TOOLBAR_DIALOG_ERROR__CONSTS} from './Nn.const'

export const addErrorsMessage = (dialogStore: DialogStore, error: any): void => {
  console.error(error)
  if (error.name === 'AbortError') {
    if (dialogStore.isDialogOpen) {
      dialogStore.addMessage({isUser: false, isError: true, message: TOOLBAR_DIALOG_ERROR__CONSTS.textAbortError})
    }
    return
  }
  if (dialogStore.isDialogOpen) {
    dialogStore.addMessage({isUser: false, isError: true, message: TOOLBAR_DIALOG_ERROR__CONSTS.textDialogError})
  }
}
