import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import ReactQuill, {Quill} from 'react-quill'
import {observer} from 'mobx-react-lite'
import {debounce} from 'lodash'
import {useSnackbar} from 'notistack'
import {GalleryImage, PublicationStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {deleteElementById} from '@utils/deleteElementById'
import {useGetGalleryImagesLazyQuery} from '@pages/Publication/gql/Publication.generated'
import {resolveAllPromises} from '@hooks/resolveAllPromises'
import {ConfirmationModal} from '@components/UI/ConfirmationModal/ConfirmationModal'
import {getQuillEditor} from '@utils/getQuillEditor'
import ElementTypeNames from '../../../../types/ElementTypeNames'
import {PublicationContentElement} from '../../../../types/PublicationContentElement'
import {useUpdatePublicationContentMutation} from '../../gql/TextEditor.generated'
import {EDITOR_REF_ID} from '../Editor.consts'
import {GalleriesData} from '../../../../types/GalleriesData'
import {QUILL_EDITOR_CONSTS} from './QuillEditor.consts'
import {formats} from './QuillEditor.formats'
import {EditorBlock, EditorWrapper, QuillEditorWrapper} from './QuillEditor.styles'
import {adaptQuillRequest, adaptQuillResponse} from './QuillEditor.adapters'
import type {QuillEditorProps} from './QuillEditor.types'
import type {FigureVersion, Element as graphqlElement} from '@graphql/types'

const Delta = Quill.import('delta')

const QuillEditor: FC<QuillEditorProps> = ({editorRef, modules, handleFocus, changeEditorTextLength}) => {
  const [html, setHtml] = useState('')
  const [isLoadingData, setIsLoadingData] = useState(false)
  const snackbar = useSnackbar()
  const [updateElements] = useUpdatePublicationContentMutation()
  const {publicationStore, editorStore, dialogStore} = useStore()
  const {isRollUp} = dialogStore
  const {publication, setPublicationContentLoading, setPublicationWithPrevState} = publicationStore
  const {setEditorRef, setEditorDirty, setEditorTextLength, setEditorLoadingNn} = editorStore
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [galleryIdForDeleting, setGalleryIdForDeleting] = useState('')

  const [getGalleryImages] = useGetGalleryImagesLazyQuery({
    fetchPolicy: 'no-cache'
  })

  const [fullPublicationContentData, setFullPublicationContentData] = useState<PublicationContentElement[]>([])

  const prepareGalleryData = useCallback(
    async (uid: string, ind: number): Promise<GalleriesData> => {
      const galleryImagesData = await getGalleryImages({variables: {uid}})

      const promiseRes = await Promise.resolve(galleryImagesData)

      return {
        ind,
        images:
          promiseRes?.data?.galleryImages?.map(item => {
            return {
              author: item?.author || '',
              description: item?.description || '',
              // gallery: (item && item.gallery) || '',
              id: item?.id || '',
              uid: item?.uid || '',
              figureVersion: {
                id: item?.figureVersion?.id || '',
                uid: item?.figureVersion?.uid || '',
                primaryProcessedImage: {
                  url: item?.figureVersion?.primaryProcessedImage?.url || ''
                },
                figure: {
                  id: item?.figureVersion?.figure?.id || '',
                  uid: item?.figureVersion?.figure?.uid || '',
                  src: item?.figureVersion?.figure?.src || '',
                  latestVersion: item?.figureVersion as FigureVersion,
                  sourceImage: item?.figureVersion?.figure?.sourceImage,
                  cropperPreview: item?.figureVersion?.figure?.cropperPreview
                  // crop: item?.figureVersion?.crop,
                  // figure: item?.figureVersion?.figure
                }
              }
            } as GalleryImage
          }) || []
      }
    },
    [getGalleryImages]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElementsMutation = useCallback(
    debounce(async (quill: Quill) => {
      if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
        setEditorLoadingNn(true)
        const elements = adaptQuillRequest({quillChildren: [...quill.root.children]})

        setPublicationContentLoading(true)
        try {
          await updateElements({
            variables: {
              data: {
                publicationUid: publication.uid,
                elements
              }
            }
          })
        } catch {
        } finally {
          setPublicationContentLoading(false)
          setEditorLoadingNn(false)
        }
      }
    }, 7500),
    // Нужно внемательно следить за зависимостями
    // react-hooks/exhaustive-deps не подсказывает, если функция обернута в debounce
    [publication.status, publication.uid, updateElements, setPublicationWithPrevState, setPublicationContentLoading]
  )

  const editorTextMaxLength = useMemo(() => {
    return publication.typeSlug === 'articles' ? 3500 : QUILL_EDITOR_CONSTS.maxPublicationLength
  }, [publication.typeSlug])

  const errorText = useMemo(() => {
    return publication.typeSlug === 'articles'
      ? QUILL_EDITOR_CONSTS.reachedCharacterLimitMsg
      : QUILL_EDITOR_CONSTS.mayBeThisArticleMsg
  }, [publication.typeSlug])

  const passDataToAdapter = useCallback(
    (fullContentData: PublicationContentElement[]) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        setEditorRef(editorRef)
        const ops = adaptQuillResponse({elements: fullContentData, quill})
        quill.setContents(new Delta({ops}))
        setIsLoadingData(false)
      }
    },
    [editorRef, editorRef.current]
  )

  const prepareAllDataForAdapter = useCallback(
    async (promises: Promise<any>[]) => {
      resolveAllPromises(promises).then((data: GalleriesData[]) => {
        const fullContentData: PublicationContentElement[] =
          fullPublicationContentData.map((contentItem: PublicationContentElement, ind: number) => {
            if (contentItem.__typename === ElementTypeNames.GalleryElement) {
              const galleryData = data.find((item: GalleriesData) => item.ind === ind)

              if (galleryData && galleryData.images) {
                return {
                  ...contentItem,
                  galleryImages: galleryData.images
                } as PublicationContentElement
              }
            }
            return contentItem
          }) || []

        passDataToAdapter(fullContentData)
      })
    },
    [fullPublicationContentData, passDataToAdapter]
  )

  useEffect(() => {
    const deleteElementEvent = (event: Event) => {
      deleteElementById(event?.detail?.id)
    }

    window.addEventListener('deleteImageEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteVideoEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteHtmlEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteSideBarEvent', event => deleteElementEvent(event))
    window.addEventListener('deleteGalleryEvent', event => toggleIsOpenConfirmDialog(event?.detail?.id || ''))

    setIsLoadingData(true)

    const quill = getQuillEditor(editorRef)

    if (quill) {
      setEditorRef(editorRef)
      const fullDataElements: PublicationContentElement[] = []

      const promises: Promise<any>[] = []

      publication.content.elements.forEach((item: graphqlElement & {__typename?: string}, ind: number) => {
        if (item.__typename && item.__typename === ElementTypeNames.GalleryElement) {
          promises.push(Promise.resolve(prepareGalleryData(item.uid, ind)))
          fullDataElements.push({
            ...item,
            galleryImages: []
          } as PublicationContentElement)
        } else {
          fullDataElements.push(item as PublicationContentElement)
        }
      })

      setFullPublicationContentData(fullDataElements)
      // если есть что-то для дообогащения
      if (promises.length > 0) {
        prepareAllDataForAdapter(promises)
        return
      }
      passDataToAdapter(fullDataElements)
    }
  }, [editorRef, editorRef.current, publication.content.elements, publication.content.elements.length, setEditorRef])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showError = useCallback(
    debounce(() => {
      snackbar.enqueueSnackbar(errorText, {variant: 'error'})
    }, 1000),
    []
  )

  const handleChange = useCallback(
    (newHtml, delta, source) => {
      setHtml(newHtml)

      let quill: Quill | undefined

      if (editorRef.current) {
        quill = editorRef.current.getEditor()
      }

      if (quill) {
        const editorTextLength = quill.getLength() - 1

        changeEditorTextLength(editorTextLength)
        setEditorTextLength(editorTextLength)

        if (source === 'user' || source === 'link') {
          if (source === 'user') {
            setEditorDirty(true)
          }

          if (delta?.ops?.length == 2) {
            const selectionIndex =
              (delta.ops[0]?.retain || 0) + (delta.ops[1]?.insert?.length || delta.ops[1]?.delete?.length || 0)

            quill.focus()
            quill.setSelection(selectionIndex, 0)
          }

          if (editorTextLength >= editorTextMaxLength) {
            showError()
          }

          updateElementsMutation(quill)
        }
      }
    },
    [
      editorRef.current,
      isLoadingData,
      changeEditorTextLength,
      editorTextMaxLength,
      updateElementsMutation,
      showError,
      // @ts-ignore: Unreachable code error
      publication.announceImage?.latestVersion?.primaryProcessedImage?.url,
      // @ts-ignore: Unreachable code error
      publication.announceImage?.uid,
      publication.announceImage?.author,
      publication.announceImage?.description,
      handleFocus,
      setEditorDirty,
      setEditorTextLength,
      html
    ]
  )

  const closeConfirmModal = useCallback(() => {
    deleteElementById(galleryIdForDeleting)
    setIsOpenConfirmDialog(false)
  }, [galleryIdForDeleting])

  const toggleIsOpenConfirmDialog = useCallback(
    (id: string) => {
      setGalleryIdForDeleting(id)
      setIsOpenConfirmDialog(!isOpenConfirmDialog)
    },
    [isOpenConfirmDialog]
  )

  return (
    <QuillEditorWrapper style={{height: '800px'}}>
      <EditorWrapper>
        <ReactQuill
          id={EDITOR_REF_ID}
          ref={editorRef}
          formats={formats}
          modules={modules}
          value={html}
          onChange={handleChange}
          readOnly={isRollUp}
        />
      </EditorWrapper>
      <ConfirmationModal
        id={'delete-gallery'}
        confirmText={QUILL_EDITOR_CONSTS.deleteGalleryConfirmationText}
        isOpenConfirmDialog={isOpenConfirmDialog}
        closeConfirmModal={closeConfirmModal}
        okLabel={QUILL_EDITOR_CONSTS.deleteGalleryConfirmBtn}
        toggleIsOpenConfirmDialog={toggleIsOpenConfirmDialog}
        firstSentenceText={QUILL_EDITOR_CONSTS.deleteGalleryConfirmationfirstSentenceText}
      />
    </QuillEditorWrapper>
  )
}

export default observer(QuillEditor)
