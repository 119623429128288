import React, {FC} from 'react'

const RetryIcon: FC<any> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_14121_103277)'>
        <path
          d='M15.1819 9.4549H19.0001V5.63672'
          stroke='#1964E7'
          strokeWidth='1.27273'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.0001 9.45205L16.7505 7.2025C15.4478 5.89978 13.684 5.16318 11.8416 5.15246C9.9993 5.14174 8.22702 5.85776 6.90918 7.14523'
          stroke='#1964E7'
          strokeWidth='1.27273'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.81818 14.5469H5V18.3651'
          stroke='#1964E7'
          strokeWidth='1.27273'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5 14.5469L7.24955 16.7964C8.55231 18.0991 10.3161 18.8357 12.1585 18.8465C14.0008 18.8572 15.7731 18.1412 17.0909 16.8537'
          stroke='#1964E7'
          strokeWidth='1.27273'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_14121_103277'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RetryIcon
