import {makeAutoObservable} from 'mobx'
import {ExtAnnounceParams} from '@components/Publication/Form/PublicationForm/ExtAnnounceParams'
import {SocialMediaImage} from '@graphql/types'
import AnnounceImageModalStore from '../announceImageModalStore'
import IAnnounceImageModalCacheStore from './IAnnounceImageModalCacheStore'

class AnnounceImageModalCacheStore implements IAnnounceImageModalCacheStore {
  announceImageModalStore: AnnounceImageModalStore

  extAnnounceParamsCache: ExtAnnounceParams | undefined = undefined
  socialImageCache: SocialMediaImage | undefined = undefined

  constructor(announceImageModalStore: AnnounceImageModalStore) {
    this.announceImageModalStore = announceImageModalStore
    makeAutoObservable(this)
  }

  setExtAnnounceParamsCache = (val: ExtAnnounceParams | undefined): void => {
    this.extAnnounceParamsCache = val
  }
  setSocialImageCache = (val: SocialMediaImage | undefined): void => {
    this.socialImageCache = val
  }
}

export default AnnounceImageModalCacheStore
