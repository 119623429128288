import {FC, useCallback} from 'react'
import {toast} from 'react-toastify'
import {observer} from 'mobx-react-lite'
import {useFormContext, useWatch} from 'react-hook-form'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchGenerateBack, fetchGenerateBackWithRegions} from '@fetch/NnAPI'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES} from '../ToolbarNn.consts'

type Props = {
  editorRef: React.MutableRefObject<any>
  handleClose: () => void
  isRegionsResponse: boolean
}
/**
 * Кнопка для генерации бэков.
 *
 * @param {React.MutableRefObject<any>} editorRef - Ссылка на редактор Quill, который используется для вставки текста.
 * @param {Function} handleClose - Функция для закрытия диалога.
 * @param {boolean} isRegionsResponse - Флаг запроса бека с регионами
 */

const WriteBackButton: FC<Props> = ({editorRef, handleClose, isRegionsResponse}) => {
  const {publicationStore, dialogStore} = useStore()
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  const responseForBack = useCallback(
    (data: any) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        // Получаем значение по ключу text
        const text = data.text

        if (text === TOOLBAR_BUTTONS_TITLES.back.backNotFound) {
          toast.success(TOOLBAR_BUTTONS_TITLES.back.backNotFound, {
            containerId: 'success',
            autoClose: 10000,
            hideProgressBar: true
          })
        } else if (text === TOOLBAR_BUTTONS_TITLES.back.publicationNotFound) {
          toast.success(TOOLBAR_BUTTONS_TITLES.back.backNotFound, {
            containerId: 'success',
            autoClose: 10000,
            hideProgressBar: true
          })
        } else {
          // Получаем текущую длину контента
          const currentLength = quill.getLength()

          // Получаем последний символ
          const lastChar = quill.getText(currentLength)

          // Определяем позицию вставки текста
          let insertPosition
          if (lastChar === '\n') {
            insertPosition = currentLength - 4
          } else {
            insertPosition = currentLength + 1
          }

          // Вставляем текст в указанную позицию
          quill.insertText(insertPosition, text.toString(), 'user')

          // Устанавливаем курсор в конец вставленного текста
          const insertedLength = text.length
          quill.setSelection(insertPosition + insertedLength, 0)

          // Вставка слова с ссылкой
          const word = data.word
          const url = data.url
          const index = text.indexOf(word) - 1

          if (index !== -1) {
            // Вставляем слово с ссылкой в нужную позицию
            quill.insertText(insertPosition + index, word, {link: url}, 'user')
            // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
            quill.deleteText(insertPosition + index + word.length, word.length, 'user')
          }
        }

        dialogStore.setIsLoading(false)
      }
    },
    [editorRef, dialogStore]
  )

  const handleWriteBack = useCallback(async () => {
    handleClose()
    const quill = getQuillEditor(editorRef)
    const {publication} = publicationStore
    const publicationId = publication?.deprecatedId
    const publicationUid = publication?.id

    const regions = fields.regionUids?.filter(id => {
      const region = fields.regions.find(region => region.uid === id)
      if (!region) {
        return true
      }
      return region.title !== 'Все регионы'
    })

    if (quill) {
      let requestText = ''
      const delta = quill.getContents()

      delta.forEach(item => {
        if (typeof item.insert === 'string') requestText += item.insert + ' '
      })

      if (requestText.length <= 2) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notFoundText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return dialogStore.setIsLoading(false)
      }

      try {
        dialogStore.setIsLoading(true)
        let response

        if (isRegionsResponse) {
          if ((regions && regions?.length <= 0) || regions === undefined) {
            toast.info(TOOLBAR_BUTTONS_TITLES.back.notFoundRegions, {
              containerId: 'success',
              autoClose: 10000,
              hideProgressBar: true
            })
            return
          }
          const data = {params: requestText, regions, publicationId, publicationUid}
          response = await fetchGenerateBackWithRegions(data)
        } else {
          const data = {params: requestText, publicationId, publicationUid}
          response = await fetchGenerateBack(data)
        }

        if (!response) {
          console.error(TOOLBAR_BUTTONS_TITLES.fetchError)
          return
        }
        responseForBack(response)
      } catch (error) {
        console.error(error)
      } finally {
        dialogStore.setIsLoading(false)
      }
    }
  }, [editorRef, responseForBack, publicationStore, handleClose, dialogStore, isRegionsResponse, fields])

  return (
    <MenuItem>
      <Button onClick={handleWriteBack}>
        {isRegionsResponse ? TOOLBAR_BUTTONS_TITLES.back.backWithRegionsTitle : TOOLBAR_BUTTONS_TITLES.back.backTitle}
      </Button>
    </MenuItem>
  )
}

export default observer(WriteBackButton)
