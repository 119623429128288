import {FetchResult} from '@apollo/client'
import {UpdatePublicationMainFieldsMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UiUpdatePublicationMainFieldsResult} from 'entities/types/UiPublication'
import {UiStory} from 'entities/types/UiStory'
import {UiAuthor} from 'entities/types/UiAuthor'
import {UiCollection} from 'entities/types/UiCollection'
import {UiTag} from 'entities/types/UiTag'
import {UiSubscriptionBlock} from 'entities/types/UiSubscriptionBlock'
import defaultPublication from '../publication.consts'

const updatePublicationMainFieldsAdapterToClient = (
  response: FetchResult<UpdatePublicationMainFieldsMutation>
): UiUpdatePublicationMainFieldsResult => {
  if (
    response.data?.updatePublication?.publication === null ||
    response.data?.updatePublication?.publication === undefined
  ) {
    return defaultPublication
  }

  const {
    title,
    yandexTitle,
    yandexDzenTitle,
    subtitle,
    deprecatedId,
    seoTitle,
    overviewTitle,
    overviewDescription,
    isCommentsEnabled,
    isAutomaticCommentsDisabled,
    isImportant,
    sendToRambler,
    specProjectUrl,
    isInside,
    tags,
    collections,
    story,
    subscriptionBlock,
    authors,
    keywords
  } = response.data?.updatePublication?.publication

  let storyElement: UiStory | null = null

  if (story) {
    const {uid: storyUid, title: storyTitle} = story

    storyElement = {
      title: storyTitle || '',
      id: '',
      uid: storyUid,
      createdAt: new Date(),
      dayThemeRegions: [],
      description: '',
      isActive: false,
      updatedAt: new Date(),
      publicationsCount: 0
    }
  }

  const authorsVal: UiAuthor[] =
    authors?.map(a => ({
      firstName: a.firstName || '',
      lastName: a.lastName || '',
      uid: a.uid || '',
      deprecatedId: 0,
      id: ''
    })) || []

  const collectionsVal: UiCollection[] =
    collections?.map(c => ({
      title: c?.title || '',
      uid: c?.uid || '',
      createdAt: new Date(),
      description: '',
      id: 0,
      isActive: false,
      publicationsCount: 0,
      updatedAt: new Date()
    })) || []

  const tagsVal: UiTag[] =
    tags?.map(t => ({
      title: t?.title || '',
      uid: t?.uid || '',
      id: '',
      createdAt: new Date(),
      description: '',
      isActive: false,
      priority: 0,
      relatedTags: [],
      updatedAt: new Date()
    })) || []

  let subscriptionBlockElement: UiSubscriptionBlock | null = null

  if (subscriptionBlock) {
    const {uid: subscriptionBlockUid, title: subscriptionBlockTitle} = subscriptionBlock
    subscriptionBlockElement = {
      title: subscriptionBlockTitle || '',
      uid: subscriptionBlockUid || '',
      content: '',
      id: '',
      position: 0
    }
  }

  return {
    authors: authorsVal,
    collections: collectionsVal,
    deprecatedId: deprecatedId ?? defaultPublication.deprecatedId,
    isAutomaticCommentsDisabled: isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
    isCommentsEnabled: isCommentsEnabled ?? defaultPublication.isCommentsEnabled,
    isImportant: isImportant ?? defaultPublication.isImportant,
    isInside: isInside ?? defaultPublication.isInside,
    keywords: keywords ?? defaultPublication.keywords,
    overviewTitle: overviewTitle ?? defaultPublication.overviewTitle,
    overviewDescription: overviewDescription ?? defaultPublication.overviewDescription,
    sendToRambler: sendToRambler ?? defaultPublication.sendToRambler,
    seoTitle: seoTitle ?? defaultPublication.seoTitle,
    specProjectUrl: specProjectUrl ?? defaultPublication.specProjectUrl,
    story: storyElement ?? defaultPublication.story,
    subscriptionBlock: subscriptionBlockElement ?? defaultPublication.subscriptionBlock,
    subtitle: subtitle ?? defaultPublication.subtitle,
    tags: tagsVal,
    title: title ?? defaultPublication.title,
    yandexDzenTitle: yandexDzenTitle ?? defaultPublication.yandexDzenTitle,
    yandexTitle: yandexTitle ?? defaultPublication.yandexTitle
    // остальные поля заполнены дефолтными значениями
    // announceImage: defaultPublication.announceImage,
    // badwords: defaultPublication.badwords,
    // canonicalUrl: defaultPublication.canonicalUrl,
    // comments: defaultPublication.comments,
    // commentsCount: 0,
    // content: {
    //   elements: []
    // },
    // createdAt: defaultPublication.createdAt,
    // creator: defaultPublication.creator,
    // destinations: defaultPublication.destinations,
    // hasOverviewBlock: defaultPublication.hasOverviewBlock,
    // id: defaultPublication.id,
    // ogDescription: defaultPublication.ogDescription,
    // ogTitle: defaultPublication.ogTitle,
    // overviewFigureId: defaultPublication.overviewFigureId,
    // overviewFigureVersionId: defaultPublication.overviewFigureVersionId,
    // publishedAt: defaultPublication.publishedAt,
    // regions: defaultPublication.regions,
    // relatedLinks: defaultPublication.relatedLinks,
    // rubric: defaultPublication.rubric,
    // socialMediaImage: defaultPublication.socialMediaImage,
    // status: defaultPublication.status,
    // subrubric: defaultPublication.subrubric,
    // type: defaultPublication.type,
    // typeSlug: defaultPublication.typeSlug,
    // oldAdminUrl: defaultPublication.oldAdminUrl,
    // updatedAt: new Date(),
    // uid: defaultPublication.uid,
    // url: defaultPublication.url,
    // yandexNewsVideos: defaultPublication.yandexNewsVideos,
    // yandexZenCategories: defaultPublication.yandexZenCategories
  }
}

export {updatePublicationMainFieldsAdapterToClient}
