import {PublicationStatus} from '@graphql/types'
import {UiPublication} from 'entities/types/UiPublication'
import {UiPublicationContent} from 'entities/types/UiPublicationContent'
import {UiSocialMediaImage} from 'entities/types/UiSocialMediaImage'
import {UiUser} from 'entities/types/UiUser'

const defaultContent: UiPublicationContent = {
  elements: []
}

const defaultCreator: UiUser = {
  id: '',
  uid: ''
}

const defaultSocialMediaImage: UiSocialMediaImage = {
  id: '',
  uid: ''
}

const defaultPublication: UiPublication = {
  id: '',
  uid: '',
  deprecatedId: 0,
  title: '',
  subtitle: '',
  isInside: false,
  creator: defaultCreator,
  keywords: '',
  relatedLinks: [],
  socialMediaImage: defaultSocialMediaImage,
  announceImage: {
    id: '',
    figureVersion: undefined,
    isFullWidth: false,
    description: '',
    author: '',
    alt: ''
  },
  comments: [],
  commentsCount: 0,
  content: defaultContent,
  hasOverviewBlock: false,
  rubric: null,
  subrubric: null,
  authors: [],
  status: PublicationStatus.Draft,
  yandexNewsVideos: [],
  yandexZenCategories: []
}

export default defaultPublication
