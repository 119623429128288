import {FetchResult} from '@apollo/client'
import {parseISO} from 'date-fns'
import {PublishPublicationMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UiPublishPublicationResult} from 'entities/types/UiPublication'
import {UiAnnounceImage} from 'entities/types/UiAnnounceImage'
import {UiFigureVersion} from 'entities/types/UiFigureVersion'
import {UiCrop} from 'entities/types/UiCrop'
import {UiImage} from 'entities/types/UiImage'
import {ImageCode} from '@graphql/types'
import {UiFigure} from 'entities/types/UiFigure'
import {UiFigureType} from 'entities/types/UiFigureType'
import {UiRelatedLink} from 'entities/types/UiRelatedLink'
import {UiAuthor} from 'entities/types/UiAuthor'
import {UiCollection} from 'entities/types/UiCollection'
import {UiPublicationYandexNewsVideo} from 'entities/types/UiPublicationYandexNewsVideo'
import {UiYandexZenCategory} from 'entities/types/UiYandexZenCategory'
import {UiTag} from 'entities/types/UiTag'
import {UiRegion} from 'entities/types/UiRegion'
import {UiSubscriptionBlock} from 'entities/types/UiSubscriptionBlock'
import {UiStory} from 'entities/types/UiStory'
import {UiDestination} from 'entities/types/UiDestination'
import {UiSocialMediaFigureVersion} from 'entities/types/UiSocialMediaFigureVersion'
import {UiSocialMediaImage} from 'entities/types/UiSocialMediaImage'
import defaultPublication from '../publication.consts'

const publishPublicationAdapterToClient = (
  response: FetchResult<PublishPublicationMutation>
): UiPublishPublicationResult => {
  if (
    response.data?.publishPublication?.publication === null ||
    response.data?.publishPublication?.publication === undefined
  ) {
    return defaultPublication
  }

  const {
    announceImage,
    authors,
    badwords,
    canonicalUrl,
    collections,
    createdAt,
    deprecatedId,
    destinations,
    id,
    isCommentsEnabled,
    isAutomaticCommentsDisabled,
    isImportant,
    isInside,
    keywords,
    ogDescription,
    ogTitle,
    oldAdminUrl,
    overviewDescription,
    overviewFigureId,
    overviewFigureVersionId,
    overviewTitle,
    publishedAt,
    redirectUrl,
    regions,
    relatedLinks,
    sendToRambler,
    seoTitle,
    socialMediaImage,
    specProjectUrl,
    status,
    story,
    subtitle,
    subscriptionBlock,
    tags,
    title,
    typeSlug,
    uid,
    yandexDzenTitle,
    yandexTitle,
    yandexNewsVideos,
    yandexZenCategories
  } = response.data?.publishPublication?.publication

  let announceImageElement: UiAnnounceImage

  if (announceImage) {
    const {id: announceImageId, alt, author, description, figureVersion, isFullWidth} = announceImage

    let figureVersionElement: UiFigureVersion | null = null

    if (figureVersion) {
      const {
        crop,
        figure,
        hasWatermark,
        hasDiagonalWatermark,
        id: figureVersionId,
        primaryProcessedImage,
        uid: figureVersionUid
      } = figureVersion

      let cropElement: UiCrop | null = null

      if (crop) {
        const {height, left, top, width} = crop
        cropElement = {
          height: height || 0,
          left: left || 0,
          top: top || 0,
          width: width || 0
        }
      }

      let figureElement: UiFigure | null = null

      if (figure) {
        const {id: figureId, uid: figureUid, src, cropperPreview} = figure

        let cropperPreviewElement: UiImage | null = null

        if (cropperPreview) {
          const {url} = cropperPreview

          cropperPreviewElement = {
            url: url || '',
            code: ImageCode.L,
            size: {
              width: 0,
              height: 0
            }
          }
        }

        figureElement = {
          id: figureId,
          uid: figureUid,
          src,
          cropperPreview: cropperPreviewElement ?? ({} as UiImage),
          alt,
          author,
          createdAt: new Date(),
          description,
          hasWatermark,
          hasDiagonalWatermark,
          updatedAt: new Date(),
          sourceImage: cropperPreviewElement ?? ({} as UiImage),
          type: {} as UiFigureType,
          latestVersion: {} as UiFigureVersion
        }
      }

      let primaryProcessedImageElement: UiImage | undefined = undefined
      if (primaryProcessedImage) {
        const {url} = primaryProcessedImage
        primaryProcessedImageElement = {
          url: url || '',
          code: ImageCode.L,
          size: {
            width: 0,
            height: 0
          }
        }
      }

      figureVersionElement = {
        crop: cropElement ?? ({} as UiCrop),
        figure: figureElement ?? ({} as UiFigure),
        hasWatermark,
        hasDiagonalWatermark,
        id: figureVersionId,
        primaryProcessedImage: primaryProcessedImageElement,
        uid: figureVersionUid,
        createdAt: new Date(),
        processedImages: []
      }
    }

    announceImageElement = {
      id: announceImageId,
      alt,
      author,
      description,
      figureVersion: figureVersionElement ?? ({} as UiFigureVersion),
      isFullWidth
    }
  } else {
    announceImageElement = defaultPublication.announceImage
  }

  const collectionsVal: UiCollection[] =
    collections?.map(c => ({
      id: +(c?.id || 0),
      uid: c?.uid || '',
      title: c?.title || '',
      createdAt: new Date(),
      description: '',
      isActive: false,
      publicationsCount: 0,
      updatedAt: new Date()
    })) || []

  const destinationsVal: UiDestination[] =
    destinations?.map(d => ({
      id: d?.id || '',
      slug: d?.slug || '',
      title: d?.title || '',
      uid: ''
    })) || []

  const relatedLinksVal: UiRelatedLink[] =
    relatedLinks?.map(rl => ({
      id: rl.id || '',
      text: rl.text || '',
      url: rl.url || ''
    })) || []

  const regionsVal: UiRegion[] =
    regions?.map(r => ({
      id: r?.id || '',
      uid: r?.uid || '',
      title: r?.title || '',
      slug: '',
      deprecatedId: 0,
      description: '',
      position: 0,
      requiredCountOfTopNewsBlocks: 0
    })) || []

  const authorsVal: UiAuthor[] =
    authors?.map(a => ({
      firstName: a.firstName || '',
      lastName: a.lastName || '',
      uid: a.uid || '',
      deprecatedId: 0,
      id: ''
    })) || []

  let storyElement: UiStory | null = null

  let socialMediaImageElement: UiSocialMediaImage | null = null

  if (socialMediaImage) {
    const {socialMediaFigureVersion} = socialMediaImage
    socialMediaImageElement = {
      socialMediaFigureVersion: {
        alignY: socialMediaFigureVersion ?? 0
      } as UiSocialMediaFigureVersion,
      id: '',
      uid: ''
    }
  }

  if (story) {
    const {id: storyId, uid: storyUid, title: storyTitle} = story

    storyElement = {
      id: storyId,
      uid: storyUid,
      title: storyTitle || '',
      createdAt: new Date(),
      dayThemeRegions: [],
      description: '',
      isActive: false,
      updatedAt: new Date(),
      publicationsCount: 0
    }
  }

  let subscriptionBlockElement: UiSubscriptionBlock | null = null

  if (subscriptionBlock) {
    const {uid: subscriptionBlockUid, title: subscriptionBlockTitle} = subscriptionBlock
    subscriptionBlockElement = {
      uid: subscriptionBlockUid || '',
      title: subscriptionBlockTitle || '',
      content: '',
      id: '',
      position: 0
    }
  } else {
    subscriptionBlockElement = {
      uid: '',
      title: '',
      content: '',
      id: '',
      position: 0
    }
  }

  const yandexZenCategoriesVal: UiYandexZenCategory[] =
    yandexZenCategories?.map(yac => ({
      deprecatedId: 0,
      id: yac?.id || '',
      uid: yac?.uid || '',
      title: yac?.title || ''
    })) || []

  const yandexNewsVideosVal: UiPublicationYandexNewsVideo[] = []

  yandexNewsVideos?.forEach(ynv => {
    let thumbnailElement: UiFigure | null = null

    if (ynv.thumbnail) {
      const {
        id: figureId,
        uid: figureUid,
        src,
        alt,
        author,
        description,
        hasWatermark,
        hasDiagonalWatermark
      } = ynv.thumbnail

      thumbnailElement = {
        id: figureId,
        uid: figureUid,
        src: src || '',
        cropperPreview: {} as UiImage,
        alt: alt || '',
        author: author || '',
        createdAt: createdAt ?? new Date(),
        description: description || '',
        hasWatermark: hasWatermark ?? false,
        hasDiagonalWatermark: hasDiagonalWatermark ?? false,
        updatedAt: new Date(),
        sourceImage: {} as UiImage,
        type: {} as UiFigureType,
        latestVersion: {} as UiFigureVersion
      }
    }

    yandexNewsVideosVal.push({
      id: ynv.id || '',
      title: ynv.title || '',
      uid: ynv.uid || '',
      uri: ynv.uri || '',
      thumbnail: thumbnailElement ?? ({} as UiFigure)
    })
  })

  const tagsVal: UiTag[] =
    tags?.map(t => ({
      id: t?.id || '',
      title: t?.title || '',
      uid: t?.uid || '',
      createdAt: new Date(),
      description: '',
      isActive: false,
      priority: 0,
      relatedTags: [],
      updatedAt: new Date()
    })) || []

  console.log('publishedAt', publishedAt)
  console.log('parseISO publishedAt', new Date(parseISO(publishedAt)))

  return {
    announceImage: announceImageElement,
    authors: authorsVal,
    badwords: badwords ?? defaultPublication.badwords,
    canonicalUrl: canonicalUrl ?? defaultPublication.canonicalUrl,
    collections: collectionsVal,
    deprecatedId: deprecatedId ?? defaultPublication.deprecatedId,
    destinations: destinationsVal,
    id: id ?? defaultPublication.id,
    isAutomaticCommentsDisabled: isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
    isCommentsEnabled: isCommentsEnabled ?? defaultPublication.isCommentsEnabled,
    isImportant: isImportant ?? defaultPublication.isImportant,
    isInside: isInside ?? defaultPublication.isInside,
    keywords: keywords ?? defaultPublication.keywords,
    ogTitle: ogTitle ?? defaultPublication.ogTitle,
    ogDescription: ogDescription ?? defaultPublication.ogDescription,
    oldAdminUrl: oldAdminUrl ?? defaultPublication.oldAdminUrl,
    overviewDescription: overviewDescription ?? defaultPublication.overviewDescription,
    overviewFigureId: overviewFigureId ?? defaultPublication.overviewFigureId,
    overviewFigureVersionId: overviewFigureVersionId ?? defaultPublication.overviewFigureVersionId,
    overviewTitle: overviewTitle ?? defaultPublication.overviewTitle,
    publishedAt: publishedAt ?? null,
    relatedLinks: relatedLinksVal,
    redirectUrl: redirectUrl ?? defaultPublication.redirectUrl,
    regions: regionsVal,
    sendToRambler: sendToRambler ?? defaultPublication.sendToRambler,
    seoTitle: seoTitle ?? defaultPublication.seoTitle,
    socialMediaImage: socialMediaImageElement ?? defaultPublication.socialMediaImage,
    story: storyElement ?? defaultPublication.story,
    specProjectUrl: specProjectUrl ?? defaultPublication.specProjectUrl,
    status: status ?? defaultPublication.status,
    subscriptionBlock: subscriptionBlockElement,
    subtitle: subtitle ?? defaultPublication.subtitle,
    tags: tagsVal,
    title: title ?? defaultPublication.title,
    typeSlug: typeSlug ?? defaultPublication.typeSlug,
    uid: uid ?? defaultPublication.uid,
    yandexTitle: yandexTitle ?? defaultPublication.yandexTitle,
    yandexDzenTitle: yandexDzenTitle ?? defaultPublication.yandexDzenTitle,
    yandexNewsVideos: yandexNewsVideosVal,
    yandexZenCategories: yandexZenCategoriesVal
  }
}

export {publishPublicationAdapterToClient}
