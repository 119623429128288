import {Quill} from 'react-quill'

interface insertTextParams {
  quill: Quill
  text: string
}

const Delta = Quill.import('delta')

export const insertText = ({quill, text}: insertTextParams): void => {
  quill.setContents(
    new Delta({
      ops: [
        {
          insert: text
        }
      ]
    }),
    'user'
  )
  quill.focus()
  quill.setSelection(text.length, 0)
}
