import {FC} from 'react'
import {ArrowForward} from '@mui/icons-material'
import RetryIcon from '@components/UI/Icons/RetryIcon'
import ArrowRightIcon from '@components/UI/Icons/ArrowRightIcon'
import {DialogItem, DialogItemButton, DialogItemButtonWrapper, Paragraph} from '../DialogNn.styles'
import {TOOLBAR_DIALOG_CONSTS} from '../../ToolbarNn.consts'

type DialogItemNnProps = {
  isUser: boolean
  isError: boolean
  isLoading?: boolean
  handleRetry?: () => void
  handleInsertText?: (message: string) => void
  isLastMessage: boolean
}
/**
 * Компонент для отображения сообщения в диалоге, с возможностью вставки текста в редактор или повторной попытки отправки запроса.
 *
 *
 * @param {boolean} isUser - Определяет, является ли сообщение от пользователя.
 * @param {boolean} isError - Определяет, является ли сообщение ошибкой.
 * @param {boolean} [isLoading=false] - Определяет, загружается ли сообщение (для отображения индикатора загрузки).
 * @param {Function} [handleRetry] - Функция для повторной попытки отправки сообщения при ошибке.
 * @param {Function} [handleInsertText] - Функция для вставки текста в редактор.
 * @param {boolean} isLastMessage - Определяет, является ли это последнее сообщение в диалоге.
 */

const DialogItemNn: FC<DialogItemNnProps> = ({
  children,
  isUser,
  isError,
  isLoading,
  handleRetry,
  handleInsertText,
  isLastMessage
}) => {
  if (isLoading) {
    return (
      <DialogItem isUser={isUser} isError={isError}>
        {children}
      </DialogItem>
    )
  }

  return (
    <DialogItem isUser={isUser} isError={isError}>
      {String(children)
        .split('\n')
        .map((line, i) => (
          <Paragraph key={i}>{line.trim()}</Paragraph>
        ))}
      {!isUser && (
        <DialogItemButtonWrapper>
          {!isError && (
            <DialogItemButton
              onClick={() => {
                handleInsertText(String(children))
              }}
            >
              <ArrowForward /> {TOOLBAR_DIALOG_CONSTS.CONTENT.saveButton}
            </DialogItemButton>
          )}
          {isLastMessage && (
            <DialogItemButton onClick={handleRetry}>
              <RetryIcon /> {TOOLBAR_DIALOG_CONSTS.CONTENT.retryButton}
            </DialogItemButton>
          )}
        </DialogItemButtonWrapper>
      )}
    </DialogItem>
  )
}

export default DialogItemNn
