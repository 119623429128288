import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, IconButton, Tooltip, Typography} from '@mui/material'
import TrashIcon from '@components/UI/Icons/Trash'
import {Figure} from '@graphql/types'
import PencilIcon from '@components/UI/Icons/Pencil'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {YandexVideoItemTitle, YandexVideoItemWrapper} from './YandexVideoItem.styles'
import {YANDEX_VIDEO_ITEM_CONSTS} from './YandexVideoItem.consts'

export type YandexVideoItemProps = {
  title: string
  thumbnail: Figure
  uid: string
  deleteYandexVideo: (uid: string) => void
  editYandexVideo: (uid: string) => void
}

export const YandexVideoItem: FC<any> = ({title, thumbnail, uid, deleteYandexVideo, editYandexVideo}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const toggleIsOpenModal = useCallback(() => {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  const [curThumbnail, setCurThumbnail] = useState(thumbnail)
  const [curTitle, setCurTitle] = useState(title)
  const [curUid, setCurUid] = useState(uid)

  useEffect(() => {
    setCurThumbnail(thumbnail)
  }, [thumbnail])

  useEffect(() => {
    setCurTitle(title)
  }, [title])

  useEffect(() => {
    setCurUid(uid)
  }, [uid])

  return (
    <YandexVideoItemWrapper>
      <img
        src={curThumbnail?.latestVersion?.primaryProcessedImage?.url}
        width='100'
        height='auto'
        style={{marginBottom: '12px'}}
      />
      <YandexVideoItemTitle>
        <Box display={'flex'} flexDirection={'row'} marginLeft={'12px'}>
          {curTitle}
        </Box>
        {curUid && (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Tooltip title={YANDEX_VIDEO_ITEM_CONSTS.deleteMsg}>
              <IconButton
                id={`delete-yandex-news-video_${curUid}`}
                key={'delete-yandex-news-video_' + curUid}
                edge='end'
                size='medium'
                onClick={toggleIsOpenModal}
              >
                <TrashIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={YANDEX_VIDEO_ITEM_CONSTS.editMsg}>
              <IconButton
                id={`edit-yandex-news-video_${curUid}`}
                key={'edit-yandex-news-video_' + curUid}
                edge='end'
                size='medium'
                onClick={() => editYandexVideo(curUid)}
                style={{
                  marginLeft: '15px'
                }}
              >
                <PencilIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </YandexVideoItemTitle>
      <SimpleModal
        mode='dialog'
        variant='warning'
        open={isOpenDeleteModal}
        title={YANDEX_VIDEO_ITEM_CONSTS.warningMsg}
        onClose={toggleIsOpenModal}
        onNo={toggleIsOpenModal}
        onYes={() => {
          deleteYandexVideo(curUid)
        }}
        yesLabel={YANDEX_VIDEO_ITEM_CONSTS.deleteMsg}
      >
        <Box textAlign='center'>
          <Typography variant='h2'>{YANDEX_VIDEO_ITEM_CONSTS.deleteQuestionMsg}</Typography>
        </Box>
      </SimpleModal>
    </YandexVideoItemWrapper>
  )
}
