import {PublicationStatus} from '@graphql/types'
import { UiPublication } from 'entities/types/UiPublication'

const defaultPublication: UiPublication = {
  announceImage: {
    __typename: undefined,
    alt: '',
    author: '',
    description: '',
    figureVersion: undefined,
    id: '',
    isFullWidth: false
  },
  authors: [],
  comments: [],
  commentsCount: 0,
  content: {
    __typename: undefined,
    elements: []
  },
  creator: {
    __typename: undefined,
    accessLevel: undefined,
    avatarUrl: undefined,
    birthDate: undefined,
    company: undefined,
    department: undefined,
    email: undefined,
    employmentDate: undefined,
    firstname: undefined,
    id: '',
    isFired: undefined,
    lastname: undefined,
    notes: undefined,
    phone: undefined,
    position: undefined,
    uid: undefined,
    username: undefined
  },
  hasOverviewBlock: false,
  id: '',
  isInside: false,
  keywords: '',
  relatedLinks: [],
  socialMediaImage: {
    __typename: undefined,
    id: '',
    socialMediaFigureVersion: undefined,
    uid: undefined
  },
  status: PublicationStatus.Draft,
  uid: undefined,
  destinations: [],
  yandexNewsVideos: [],
  yandexZenCategories: [],
  yandexDzenTitle: ''
}

export {defaultPublication}
