import React, {FC, useCallback} from 'react'
import {Quill} from 'react-quill'
import {toast} from 'react-toastify'
import {MenuItem} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {fetchCheckInoagents} from '@fetch/NnAPI'
import {useStore} from '@stores/rootStoreContext'
import ListInoagent from '@components/UI/ListInoagent/ListInoagent'
import {Button, TitleToast} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES} from '../ToolbarNn.consts'

type CheckAgentsProps = {
  editorRef: any
  handleClose: () => void
}

/**
 * Компонент CheckAgents
 * Предназначен для проверки текста из редактора Quill на наличие иноагентов и экстремистов.
 * @param editorRef - ссылка на редактор Quill
 * @param handleClose - функция для закрытия окна
 * @returns
 */
const CheckAgents: FC<CheckAgentsProps> = ({editorRef, handleClose}) => {
  const {publicationStore, dialogStore} = useStore()
  const {publication} = publicationStore

  // Основная функция для проверки текста на иноагентов
  const checkAgents = useCallback(async () => {
    handleClose()
    let quill: Quill | undefined

    if (editorRef.current !== null) {
      quill = editorRef.current.getEditor()
    }

    if (quill) {
      const delta = quill.getContents()
      let requestText = ''

      delta.forEach(item => {
        if (typeof item.insert === 'string') {
          requestText += item.insert
          requestText += ' '
        }
      })

      const cleanText = requestText.replace(/\s+\*/g, '*').trim()

      if (cleanText.length === 0) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notFoundText, {
          autoClose: 3000,
          hideProgressBar: true,
          containerId: 'success'
        })
        dialogStore.setIsLoading(false)
        return
      }

      const data = {
        params: cleanText,
        publicationId: publication.deprecatedId,
        publicationUid: publication.uid
      }

      dialogStore.setIsLoading(true)

      try {
        const responseJson = await fetchCheckInoagents(data)

        if (!responseJson) {
          console.error(TOOLBAR_BUTTONS_TITLES.fetchError)
          return
        }
        // Обработка ответа
        if (responseJson['INOAGENT'].length === 0 && responseJson['EXTREMIST'].length === 0) {
          if (responseJson['INOAGENT MARKED']) {
            toast.success(TOOLBAR_BUTTONS_TITLES.inoagent.inoagentMarked, {
              autoClose: 10000,
              hideProgressBar: true,
              containerId: 'success'
            })
          } else {
            toast.success(TOOLBAR_BUTTONS_TITLES.inoagent.inoagentNotFound, {
              autoClose: 10000,
              hideProgressBar: true,
              containerId: 'success'
            })
          }
        } else {
          toast(<ListInoagent listInoagent={responseJson} />, {
            autoClose: false,
            hideProgressBar: true,
            containerId: 'error',
            closeButton: true
          })
        }
      } catch (error: any) {
        toast.error(<TitleToast>Ошибка: {error.message}</TitleToast>, {
          autoClose: 10000,
          hideProgressBar: true,
          containerId: 'error'
        })
      } finally {
        dialogStore.setIsLoading(false)
      }
    }
  }, [editorRef, dialogStore.setIsLoading, handleClose, publication.deprecatedId, publication.uid])

  return (
    <MenuItem style={{padding: 0}}>
      <Button onClick={checkAgents}>{TOOLBAR_BUTTONS_TITLES.inoagent.inoagentTitle}</Button>
    </MenuItem>
  )
}

export default observer(CheckAgents)
