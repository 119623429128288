import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {SnackbarCloseReason} from '@mui/material'
import {useFormContext, useWatch} from 'react-hook-form'
import {WarningAmber} from '@mui/icons-material'
import {useStore} from '@stores/rootStoreContext'
import {
  usePublishPublicationMutation,
  useUpdatePublicationMutation
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {usePublishOverviewBlocksDraftMutation} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {DoNotDistrubIcon} from '@components/UI/Icons/DoNotDistrub'
import {AGENT_CHECK_NOTIFICATION} from './AgentCheckNotification.const'
import {
  ButtonContent,
  LeftBlockWrapper,
  StyledCancelButton,
  StyledSnackbar,
  StyledSubmitButton,
  StyledUl,
  Title,
  TitleButton,
  WrapperContent
} from './AgentCheckNotification.styles'

const AgentCheckNotification = observer(() => {
  const {agentCheckStore} = useStore()
  const {listInoagent, isOpen, setParamDefault} = agentCheckStore
  const [updatePublication] = useUpdatePublicationMutation()
  const [publishPublication] = usePublishPublicationMutation()
  const [publishOverviewBlocksDraft] = usePublishOverviewBlocksDraftMutation()
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  useEffect(() => {
    return () => {
      setParamDefault()
    }
  }, [])

  const handleConfirm = () => {
    agentCheckStore.onConfirm({updatePublication, publishPublication, publishOverviewBlocksDraft}, fields)
  }

  const handleCancel = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway' || reason === 'escapeKeyDown') {
      return
    }
    agentCheckStore.onCancel()
  }

  if (Object.keys(listInoagent).length === 0 && isOpen) {
    return (
      <StyledSnackbar
        open={agentCheckStore.isOpen}
        onClose={handleCancel}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        message={
          <WrapperContent>
            <LeftBlockWrapper>
              <WarningAmber fontSize='medium' />
            </LeftBlockWrapper>
            <div>
              <Title>{AGENT_CHECK_NOTIFICATION.errorTitle}</Title>
            </div>
          </WrapperContent>
        }
        action={
          <>
            <ButtonContent>
              <StyledSubmitButton onClick={handleConfirm}>{agentCheckStore.actionType}</StyledSubmitButton>
              <StyledCancelButton onClick={handleCancel}>Отменить</StyledCancelButton>
            </ButtonContent>
          </>
        }
      />
    )
  }
  if (Object.keys(listInoagent).length === 0) {
    return <></>
  }

  return (
    <StyledSnackbar
      open={agentCheckStore.isOpen}
      onClose={handleCancel}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      message={
        <WrapperContent>
          <LeftBlockWrapper>
            <DoNotDistrubIcon fontSize='medium' />
          </LeftBlockWrapper>
          <div>
            <Title>
              {AGENT_CHECK_NOTIFICATION.snackbarTitle}{' '}
              {listInoagent.INOAGENT.length !== 0
                ? AGENT_CHECK_NOTIFICATION.titleInoagent
                : AGENT_CHECK_NOTIFICATION.titleExtremist}
            </Title>
            <StyledUl>
              {listInoagent.INOAGENT.length !== 0 && (
                <>
                  {listInoagent.INOAGENT.map((inoagent, index) => (
                    <li key={inoagent + index}>{inoagent}</li>
                  ))}
                </>
              )}
              {listInoagent.EXTREMIST.length !== 0 && (
                <>
                  {listInoagent.INOAGENT.length !== 0 && <Title>{AGENT_CHECK_NOTIFICATION.titleExtremist}</Title>}
                  {listInoagent.EXTREMIST.map((extremist, index) => (
                    <li key={extremist + index}>{extremist}</li>
                  ))}
                </>
              )}
            </StyledUl>
          </div>
        </WrapperContent>
      }
      action={
        <>
          <TitleButton>{AGENT_CHECK_NOTIFICATION.alertTitle}</TitleButton>
          <ButtonContent>
            <StyledSubmitButton onClick={handleConfirm}>{agentCheckStore.actionType}</StyledSubmitButton>
            <StyledCancelButton onClick={handleCancel}>Отменить</StyledCancelButton>
          </ButtonContent>
        </>
      }
    />
  )
})

export default AgentCheckNotification
