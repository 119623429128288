import {Tooltip} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {FC, useCallback, useEffect, useState} from 'react'
import {useFormContext, useWatch} from 'react-hook-form'
import {useStore} from '@stores/rootStoreContext'
import PopoverInfoErrorButton from '@components/UI/PopoverInfoErrorButton/PopoverInfoErrorButton'
import {PublicationInputType} from '../../PublicationForm/PublicationInputType'
import RHFCheckboxLabel from '../../RHFCheckboxLabel/RHFCheckboxLabel'
import RamblerRssFillingRules from './RamblerRssFillingRules'
import {SEND_TO_RAMBLER_PICKER_CONSTS} from './SendToRamblerPicker.consts'

const SendToRamblerPicker: FC = () => {
  const {publicationStore} = useStore()
  const {publication, rubricAndSubrubricStore} = publicationStore
  const {rubricIdCache, subrubricIdCache, rubricAvailableForRambler, subrubricAvailableForRambler} =
    rubricAndSubrubricStore

  const {setValue, control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  const handleCheckbox = useCallback(() => {
    console.debug('handleCheckbox')
    // console.log('fields.sendToRambler', fields.sendToRambler)
  }, [])

  const [disableToggleForRambler, setDisableToggleForRambler] = useState(false)
  const [sendToRamblerTooltip, setSendToRamblerTooltip] = useState('')

  useEffect(() => {
    if ((fields.title?.length || 0) > 70) {
      setDisableToggleForRambler(true)
      setValue('sendToRambler', false)
      setSendToRamblerTooltip(SEND_TO_RAMBLER_PICKER_CONSTS.titleCharactersLimitWasReached)
      return
    }

    if (
      !rubricIdCache ||
      (!subrubricIdCache && rubricAvailableForRambler === false) ||
      (subrubricIdCache && subrubricAvailableForRambler === false)
    ) {
      setDisableToggleForRambler(true)
      setValue('sendToRambler', false)
      setSendToRamblerTooltip(SEND_TO_RAMBLER_PICKER_CONSTS.checkedInvalidRubric)
      return
    }
    setDisableToggleForRambler(false)
    setSendToRamblerTooltip('')
  }, [
    fields.title,
    publication?.rubric?.id,
    rubricAvailableForRambler,
    subrubricAvailableForRambler,
    rubricIdCache,
    subrubricIdCache,
    setValue
  ])

  return (
    <>
      <Tooltip title={sendToRamblerTooltip} placement='top' id={`send-to-rambler-checkbox-${publication.id}`}>
        <div>
          <RHFCheckboxLabel
            name='sendToRambler'
            label={SEND_TO_RAMBLER_PICKER_CONSTS.inRamblerRssMsg}
            disabled={disableToggleForRambler}
            style={{marginRight: '6px'}}
            handleClick={handleCheckbox}
          />
        </div>
      </Tooltip>
      <PopoverInfoErrorButton styleBtnScale={0.8}>
        <RamblerRssFillingRules></RamblerRssFillingRules>
      </PopoverInfoErrorButton>
    </>
  )
}
export default observer(SendToRamblerPicker)
