import {Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import styled, {css} from 'styled-components'
import {grayColor, mainTextColor} from '../../../@theme/vars'

function filterProps(...args: string[]) {
  return p => args && !args.includes(p)
}
interface ToolbarButtonProps {
  ql: string
  content: string
}

export const StyledToggleButton = styled(ToggleButton)
  .withConfig({
    shouldForwardProp: filterProps('ql')
  })
  .attrs<Pick<ToolbarButtonProps, 'ql'>>(({ql, className}) => ({
    className: [`ql-${ql}`, className].join(' ').trim()
  }))<{$width?: string; ql?: string}>`
    ${({$width}) =>
      $width &&
      css`
        width: ${$width};
      `}
    color: ${mainTextColor};
    background-color: #fafafa;
    padding: 9px;
    &.ql-active {
        background: rgba(0, 0, 0, 0.05);
    }
    &.MuiToggleButton-root {
        border: none;

        &.Mui-selected {
            p {
                font-weight: 500;
            }

            color: ${mainTextColor};
            box-shadow: inset 0 6px 4px rgba(0, 0, 0, 0.25);
        }
    }
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-right: 1px solid #c4c4c4;
  border-radius: 0;
`

export const ToolbarWrapper = styled(Box)`
  position: relative;
  width: calc(100% - 20px);
  max-width: 1266px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fafafa;
  justify-content: center;
  border: 1px solid #c4c4c4;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;

  &.fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1100;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

export const StyledCounter = styled(Typography)`
  color: green;
  margin-left: 12px;
`

export const ToolbarBlocked = styled.div`
  position: absolute;
  background-color: ${grayColor};
  opacity: 0.3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1300;
`
