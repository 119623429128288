import React, {FC, useEffect, useState} from 'react'
import DebugInfo from '@components/TextEditor/DebugInfo/DebugInfo'
import {DebugInfoWrapper} from './PublicationDebugInfo.styles'

export type PublicationDebugInfoProps = {
  id: string
  oldAdminUrl: string
  url: string
  deprecatedId: number
}

export const PublicationDebugInfo: FC<PublicationDebugInfoProps> = props => {
  const [id, setId] = useState('')
  const [oldAdminUrl, setOldAdminUrl] = useState('')
  const [url, setUrl] = useState('')
  const [deprecatedId, setDeprecatedId] = useState(0)

  useEffect(() => {
    setId(props.id)
    setOldAdminUrl(props.oldAdminUrl)
    setUrl(props.url)
    setDeprecatedId(props.deprecatedId)
  }, [props])

  return (
    <DebugInfoWrapper>
      <DebugInfo id={id} oldAdminUrl={oldAdminUrl} url={url} deprecatedId={deprecatedId} />
    </DebugInfoWrapper>
  )
}
